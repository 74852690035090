import { Table, Tag, Button } from 'antd';
import paths from 'pages/Router/paths';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { setBreadcrumb } from 'state/actions/breadcrumb';
import { fetchRoles } from 'state/actions/roles';
import { fetchUsers } from 'state/actions/users';
import PageHeader from 'design-components/PageHeader';
import './Notifications.css';
import {
  fetchNotifications,
  updateNotification,
} from 'state/actions/notification';
import ModalDetailNotification from 'components/ModalDetailNotification';

export default function Notifications({ match }) {
  const [search, setSearch] = useState('');
  const [notification, setNotification] = useState();

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const { notificationList, siteList, logsList, role, loading, error } =
    useSelector(
      (state) => ({
        notificationList: state.notifications.list,
        roleList: state.roles.list,
        siteList: state.sites.list,

        logsList: state.logs.list,
        userList: state.users.list,
        loading: state.notifications.loading,
        deleted: state.logs.deleted,
        role: state.auth.userData.role,
        error: state.logs.error,
        downloading: state.logs.downloading,
      }),
      shallowEqual
    );

  const dispatch = useDispatch();

  // console.log(notificationList, 'notification');
  // useEffect(() => {
  //   if (role === 'admin' || role === 'superadmin') {
  //     dispatch(fetchAuthLogs({}));
  //   }
  // }, [dispatch, role]); // eslint-disable-line

  useEffect(() => {
    dispatch(fetchNotifications());
  }, []); //eslint-disable-line

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  useEffect(() => {
    dispatch(fetchUsers(match.params.id));
    dispatch(fetchRoles());
    dispatch(setBreadcrumb([{ menu: 'Notification', link: '/notification' }]));
  }, []); // eslint-disable-line

  const redirect = role === 'superadmin' ? false : <Redirect to={paths.ROOT} />;

  const filteredData = search
    ? logsList.filter((el) => {
        const clonedElem = { ...el };
        return clonedElem.message.toLowerCase().includes(search.toLowerCase());
      })
    : logsList;

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      render: (text) => <div className="table-name">{text}</div>,
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
    },
    {
      title: 'Site',
      dataIndex: 'propertiesName',
      // sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      render: (text, record) => {
        if (record.unitnumber) {
          return (
            <div>{`${
              siteList.find(
                (site) => site.propertiesName === record.propertiesName
              )?.name
            } `}</div>
          );
        }
        return <span>-</span>;
      },
    },
    {
      title: 'Unit Number',
      dataIndex: 'unitnumber',
      // sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      render: (text, record) => {
        if (record.unitnumber) {
          return <div>{`${record.unitnumber.length} Unit Number`}</div>;
        }
        return <span>-</span>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      // sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      render: (text, record) => {
        if (record.status) {
          switch (record.status) {
            case 'ongoing':
              return <Tag color="gold">Ongoing</Tag>;
            case 'done':
              return <Tag color="green">Done</Tag>;
            case 'expired':
              return <Tag color="red">Expired</Tag>;
            default:
              return <span>-</span>;
          }
        }
        return <span>-</span>;
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text, record) => {
        return (
          <Button type="link" onClick={() => setNotification(record)}>
            See Details
          </Button>
        );
      },
    },
  ];

  const onUpdate = (notif) => {
    dispatch(updateNotification({ id: notif._id, status: notif.status }));
  };

  return (
    <section className="notification">
      {redirect}
      <ModalDetailNotification
        isVisible={notification}
        data={notification}
        onUpdate={onUpdate}
        setData={setNotification}
        onCancel={() => setNotification(null)}
      />
      <PageHeader
        title=""
        isGrid={false}
        isSearch
        searchValue={search}
        searchAction={(e) => setSearch(e.target.value)}
        searchPlaceholder="Enter User's Name"
        isAdd={false}
        addLabel=""
      />

      <div>
        {!loading && Array.isArray(filteredData) ? (
          <Table
            columns={columns}
            dataSource={notificationList}
            rowKey="_id"
            pagination={tableParams.pagination}
            onChange={handleTableChange}
          />
        ) : (
          <ClipLoader />
        )}
        {error && 'Show error'}
      </div>
    </section>
  );
}
