import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { url } from 'utils/url';
import { log, errorMsg } from 'utils';

export const DIRECTORIES_FETCH_DATA_INIT = createAction(
  'DIRECTORIES_FETCH_DATA_INIT'
);
export const DIRECTORIES_FETCH_DATA_SUCCESS = createAction(
  'DIRECTORIES_FETCH_DATA_SUCCESS'
);
export const DIRECTORIES_FETCH_DATA_FAIL = createAction(
  'DIRECTORIES_FETCH_DATA_FAIL'
);

export const DIRECTORIES_DELETE_DIRECTORY_INIT = createAction(
  'DIRECTORIES_DELETE_DIRECTORY_INIT'
);
export const DIRECTORIES_DELETE_DIRECTORY_SUCCESS = createAction(
  'DIRECTORIES_DELETE_DIRECTORY_SUCCESS'
);
export const DIRECTORIES_DELETE_DIRECTORY_FAIL = createAction(
  'DIRECTORIES_DELETE_DIRECTORY_FAIL'
);

export const DIRECTORIES_CLEAR_DATA = createAction('DIRECTORIES_CLEAR_DATA');

export const DIRECTORIES_CREATE_DIRECTORY_INIT = createAction(
  'DIRECTORIES_CREATE_DIRECTORY_INIT'
);
export const DIRECTORIES_CREATE_DIRECTORY_SUCCESS = createAction(
  'DIRECTORIES_CREATE_DIRECTORY_SUCCESS'
);
export const DIRECTORIES_CREATE_DIRECTORY_FAIL = createAction(
  'DIRECTORIES_CREATE_DIRECTORY_FAIL'
);

export const DIRECTORIES_MODIFY_DIRECTORY_INIT = createAction(
  'DIRECTORIES_MODIFY_DIRECTORY_INIT'
);
export const DIRECTORIES_MODIFY_DIRECTORY_SUCCESS = createAction(
  'DIRECTORIES_MODIFY_DIRECTORY_SUCCESS'
);
export const DIRECTORIES_MODIFY_DIRECTORY_FAIL = createAction(
  'DIRECTORIES_MODIFY_DIRECTORY_FAIL'
);

export const DIRECTORIES_CLEAN_UP = createAction('DIRECTORIES_CLEAN_UP');

export const DIRECTORIES_CLEAR_DATA_LOGOUT = createAction(
  'DIRECTORIES_CLEAR_DATA_LOGOUT'
);

export const SET_ACTIVE_DIRECTORY = createAction('SET_ACTIVE_DIRECTORY');

export const fetchDirectories = ({ directoryId = '', siteId, screenId }) => {
  return async (dispatch, getState) => {
    dispatch(DIRECTORIES_FETCH_DATA_INIT());
    if (directoryId) {
      let directoryData;
      try {
        const res = await axios.post(
          `${url}/directory/get`,
          {
            id: directoryId,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          }
        );
        directoryData = res.data.data;
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(DIRECTORIES_FETCH_DATA_FAIL({ error }));
      }

      const directory = { ...directoryData, id: directoryId };
      log(directory);
      log(directoryData);
      return dispatch(
        DIRECTORIES_FETCH_DATA_SUCCESS({
          list: getState().directorys.list,
          directory,
        })
      );
    }

    let directory;

    try {
      const res = await axios.get(`${url}/directory/list`, {
        params: {
          siteId,
          screenId,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      directory = res.data.data;
      log(directory);
    } catch (error) {
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(DIRECTORIES_FETCH_DATA_FAIL({ error }));
    }

    return dispatch(
      DIRECTORIES_FETCH_DATA_SUCCESS({
        list: directory,
        directory: getState().directories.directory,
      })
    );
  };
};

export const deleteDirectory = (id) => {
  return async (dispatch) => {
    dispatch(DIRECTORIES_DELETE_DIRECTORY_INIT());

    try {
      await axios.delete(`${url}/directory/delete`, {
        params: {
          id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        DIRECTORIES_DELETE_DIRECTORY_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The directory was deleted.');
    return dispatch(DIRECTORIES_DELETE_DIRECTORY_SUCCESS({ id }));
  };
};

export const createDirectory = ({
  name,
  siteId,
  screenId,
  duration,
  file,
  leftTenants,
  rightTenants,
}) => {
  return async (dispatch) => {
    dispatch(DIRECTORIES_CREATE_DIRECTORY_INIT());

    const directory = new FormData();
    directory.set('siteId', siteId);
    directory.set('screenId', screenId);
    directory.set('duration', duration);
    directory.set('leftTenants', JSON.stringify(leftTenants));
    directory.set('rightTenants', JSON.stringify(rightTenants));
    directory.set('name', name);
    directory.append('file', file);

    try {
      await axios.post(`${url}/directory/create`, directory, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        DIRECTORIES_CREATE_DIRECTORY_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'directory created successfully');
    dispatch(DIRECTORIES_CREATE_DIRECTORY_SUCCESS({ directory }));
    return dispatch(fetchDirectories({ siteId, screenId }));
  };
};

export const modifyDirectory = ({
  name,
  logo,
  file,
  screenId,
  duration,
  siteId,
  leftTenants,
  rightTenants,
  id,
}) => {
  return async (dispatch) => {
    dispatch(DIRECTORIES_MODIFY_DIRECTORY_INIT());

    const directory = new FormData();
    directory.set('screenId', screenId);
    directory.set('siteId', siteId);
    directory.set('duration', duration);
    directory.set('leftTenants', JSON.stringify(leftTenants));
    directory.set('rightTenants', JSON.stringify(rightTenants));
    directory.set('name', name);
    directory.set('id', id);
    if (logo) directory.set('logo', logo);
    directory.append('file', file);

    try {
      await axios.put(`${url}/directory/edit`, directory, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        DIRECTORIES_MODIFY_DIRECTORY_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'Directory updated successfully');

    return dispatch(DIRECTORIES_MODIFY_DIRECTORY_SUCCESS({ directory, id }));
  };
};

export const directorysCleanUp = () => (dispatch) =>
  dispatch(DIRECTORIES_CLEAN_UP());

export const setActiveDirectory = (directory, cb) => {
  return (dispatch) => {
    dispatch(SET_ACTIVE_DIRECTORY(directory));
    cb();
  };
};
