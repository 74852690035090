import { createReducer } from 'redux-act';

import {
  SYNCED_CATEGORIES_FETCH_DATA_INIT,
  SYNCED_CATEGORIES_FETCH_DATA_SUCCESS,
  SYNCED_CATEGORIES_FETCH_DATA_FAIL,
  SYNCED_CATEGORIES_DELETE_CATEGORY_INIT,
  SYNCED_CATEGORIES_DELETE_CATEGORY_SUCCESS,
  SYNCED_CATEGORIES_DELETE_CATEGORY_FAIL,
  SYNCED_CATEGORIES_CREATE_CATEGORY_INIT,
  SYNCED_CATEGORIES_CREATE_CATEGORY_SUCCESS,
  SYNCED_CATEGORIES_CREATE_CATEGORY_FAIL,
} from 'state/actions/syncedCategories';

const initialState = {
  list: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
  updated: false,
};

export const syncedCategoriesReducer = createReducer(
  {
    [SYNCED_CATEGORIES_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [SYNCED_CATEGORIES_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      list: payload.list || [],
      loading: false,
      error: null,
    }),
    [SYNCED_CATEGORIES_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [SYNCED_CATEGORIES_DELETE_CATEGORY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [SYNCED_CATEGORIES_DELETE_CATEGORY_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.filter((elem) => elem._id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [SYNCED_CATEGORIES_DELETE_CATEGORY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [SYNCED_CATEGORIES_CREATE_CATEGORY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [SYNCED_CATEGORIES_CREATE_CATEGORY_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.concat(payload.category),
      loading: false,
      error: null,
      success: true,
      updated: true,
    }),
    [SYNCED_CATEGORIES_CREATE_CATEGORY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
  },
  initialState
);
