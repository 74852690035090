import ModalDirectoryForm from 'components/ModalDirectoryForm';
import { useFormatMessage } from 'hooks';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { log } from 'utils';

import { clearUsersData } from 'state/actions/users';
import { regexInput } from 'utils/regex';
import ModalDeleteConfirmation from 'components/ModalDeleteConfirmation';
import Cards from 'design-components/Cards';
import _ from 'underscore';
import { createDirectory, deleteDirectory, fetchDirectories, modifyDirectory } from 'state/actions/directories';
import './Directories.css';


export default function Directories() {
  const { siteId, screenId } = useParams();

  const {
    error,
    loading,
    deleted,
    updated,
    directoriesList,
    userData,
    role,
  } = useSelector(
    (state) => ({
      sites: state.sites.list,
      error: state.directories.error,
      loading: state.directories.loading,
      deleted: state.directories.deleted,
      updated: state.directories.updated,
      directoriesList: state.directories.list,
      userData: state.auth.userData,
      role: state.auth.userData.role,
    }),
    shallowEqual
  );

  const defaultDirectory = {
    name: '',
    description: '',
    modifiedAt: moment(Date.now()).format('DD-MM-YY hh:mm:ss'),
    modifiedBy: userData.email ? userData.email : '',
  };

  const [deleteModal, setDeleteModal] = useState({
    userId: null,
    isOpen: false,
  });
  const [search, setSearch] = useState();
  const [visible, setVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [directory, setDirectory] = useState(defaultDirectory);
  // const [siteName, setSiteName] = useState('');
  // const [organizationId, setOrganizationId] = useState('');
  const [searchError, setSearchError] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDirectories({ siteId, screenId }));
    return () => dispatch(clearUsersData());
  }, [dispatch, role, siteId]); // eslint-disable-line

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        directoryId: null,
        isOpen: !prevState.isOpen,
      }));
      dispatch(fetchDirectories({ siteId, screenId }));
    }
  }, [deleted, loading]); // eslint-disable-line

  useEffect(() => {
    log(updated);
    if (updated && !loading) {
      dispatch(fetchDirectories({ siteId, screenId }));
      setVisible(false);
    }
  }, [updated, loading]); // eslint-disable-line



  const onCreate = (values) => {
    const action = isEditing ? modifyDirectory : createDirectory;
    log(values, '<<<< values directory oncreate');
    dispatch(action(values));
  };

  const onNewDirectoryHandler = () => {
    log('handle new directory');
    setDirectory(defaultDirectory);
    setIsEditing(false);
    setVisible(true);
  };

  const onRemoveButtonClickHandler = (directoryId) => {
    setDeleteModal((prevState) => ({
      directoryId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ directoryId: null, isOpen: false });
  };

  const onDeleteDirectoryHandler = () => {
    log(deleteModal);
    dispatch(deleteDirectory(deleteModal.directoryId));
  };

  const handleSearch = (e) => {
    setSearchError(null);
    if (!regexInput(e.target.value) && e.target.value) {
      setSearchError('Search cannot using special character');
    }
    setSearch(e.target.value);
  };


  const data = search
    ? directoriesList.filter((el) => {
      const clonedElem = { ...el };
      return clonedElem.name.toLowerCase().includes(search.toLowerCase());
    })
    : directoriesList;

  const permDeleteMessage = useFormatMessage('Directories.permDelete');

  return (
    <section className="directories">
      {deleteModal.isOpen && (
        <ModalDeleteConfirmation
          title="Delete Directory"
          onCloseHandler={onCloseModalHandler}
          onDeleteHandler={onDeleteDirectoryHandler}
          description={permDeleteMessage}
          visible={deleteModal.isOpen}
        />
      )}
      <div className="directories-header">
      <div className="searchbar-wrapper">
          <div className="searchbar">
            <i className="feather-search" />
            <input
              type="text"
              placeholder="Search..."
              value={search}
              onChange={handleSearch}
            />
          </div>
          {searchError && <div className="search-error">{searchError}</div>}
        </div>

        <div className="directories__buton-add">
          <button className="button-primary" onClick={onNewDirectoryHandler}>
            <i className="feather-plus" /> Add Directories
          </button>
        </div>
      </div>
      {visible && (
        <ModalDirectoryForm
          visible
          onCancel={() => {
            setVisible(false);
          }}
          onCreate={onCreate}
          directory={directory}
          setDirectory={setDirectory}
          isEditing={isEditing}
        />
      )}
      <div className="directories__table">
        {loading ? (
          <ClipLoader />
        ) : (
          <div className="media-content-wrapper">
            <div className="media-content">
              <div className="media-list-content">
                {_.chunk(data, 3).map((chunk, index) => {
                  return (
                    <div className="media-list-chunk" key={index}>
                      {chunk.map((dir) => (
                        <div
                          key={dir._id}
                          data-id={dir._id}
                          className="media-list"
                        >
                          <Cards
                            data={dir}
                            type="directory"
                            isShowMedia
                            editAction={() => {
                              setDirectory({...dir, leftTenants: dir.leftTenants.map(tenant => tenant.entityname), rightTenants: dir.rightTenants.map(tenant=> tenant.entityname)});
                              setVisible(true);
                              setIsEditing(true);
                            }}
                            deleteAction={() => onRemoveButtonClickHandler(dir._id)}


                          />
                        </div>
                      ))}
                      {chunk.length < 2 && <div className="media-list-empty" />}
                      {chunk.length < 3 && <div className="media-list-empty" />}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          // <DirectoryCard
          //   data={data}
          //   onRemoveButtonClickHandler={(id) => onRemoveButtonClickHandler(id)}
          //   onEditButtonClickHandler={(record) => {
          //     setDirectory(record);
          //     setVisible(true);
          //     setIsEditing(true);
          //   }}
          //   handleGoTo={handleGoTo}
          // />
        )}
        {error && 'Show error'}
      </div>
    </section>
  );
}
