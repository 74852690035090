/* eslint-disable jsx-a11y/label-has-associated-control */
import { Input, Modal, Select, Tooltip, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
// import InputMask from 'react-input-mask';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ExportIcon } from 'assets/icon/dynamic';
import { imgUrl } from 'utils/url';
import { log } from 'utils';
import { transportUpdate } from 'state/actions/configuration';
import 'react-quill/dist/quill.snow.css';
import ModalConfirmationContent from 'components/ModalConfirmationContent';
import InformationBox from 'components/InformationBox';
import ImageUploadPreview from 'components/ImageUploadPreview';
import ReactQuill from 'react-quill';
import { filterAccess } from 'components/Report/utils';
import './TransportForm.css';
import ModalResetContent from 'components/ModalResetContent';

const TransportForm = ({
  configuration,
  userData,
  setConfiguration,
  loading,
}) => {
  const { siteId } = useParams();

  const [isDragged, setIsDragged] = useState(false);
  const [errors, setErrors] = useState();
  const [activeTransport, setActiveTransport] = useState('mrt');

  const dispatch = useDispatch();

  const transports = [
    {
      value: 'mrt',
      label: 'MRT',
    },
    {
      value: 'taxi',
      label: 'Taxi',
    },
    {
      value: 'bus',
      label: 'Bus',
    },
  ];

  useEffect(() => {
    const newConfig = { ...configuration };
    if (
      newConfig?.bus?.length === 0 ||
      newConfig?.mrt?.length === 0 ||
      newConfig?.taxi?.length === 0
    ) {
      for (let index = 0, l = transports.length; index < l; index += 1) {
        const transport = transports[index];
        if (
          newConfig[transport.value]?.length === 0 ||
          !newConfig[transport.value]
        ) {
          newConfig[transport.value] = [{ logo: null, description: '' }];
        }
      }
      setConfiguration({
        ...newConfig,
        mrt: newConfig.mrt,
        bus: newConfig.bus,
        taxi: newConfig.taxi,
      });
    }
    log(configuration, 'configuration');
  }, [configuration]); //eslint-disable-line
  useEffect(() => {
    setErrors();
  }, [loading]); //eslint-disable-line

  const onFileChangedHandler = (file, params) => {
    const config = { ...configuration };
    config[params] = file;
    setConfiguration(config);
  };
  const onFileChangedHandlerContent = (file, params, index) => {
    const config = { ...configuration };
    config[activeTransport][index][params] = file;

    setConfiguration({ ...config });
  };

  const handleDeletePreviewImage = (file, media) => {
    const config = { ...configuration };
    config[file] = null;
    config[media] = null;
    setConfiguration({ ...config });
  };
  const handleDeletePreviewImageContent = (file, media, index) => {
    const config = { ...configuration };
    config[activeTransport][index][file] = null;
    config[activeTransport][index][media] = null;
    setConfiguration({ ...config });
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (
      (!configuration.carparkBackgroundFile &&
        !configuration.carparkBackground) ||
      (!configuration.carparkContentFile && !configuration.carparkContent) ||
      (!configuration.transportBackgroundFile &&
        !configuration.publictransportBackground) ||
      (configuration.findmycarActive &&
        !configuration.findmycarBackgroundFile &&
        !configuration.findmycarBackground)
    ) {
      const newErrors = {
        errors: {},
        message: 'Something went wrong!',
      };

      newErrors.errors.carparkBackground = !configuration.carparkBackgroundFile &&
        !configuration.carparkBackground && [
          {
            msg: 'This field is required',
          },
        ];
      newErrors.errors.carparkContent = !configuration.carparkContentFile &&
        !configuration.carparkContent && [
          {
            msg: 'This field is required',
          },
        ];
      newErrors.errors.transportBackground = !configuration.transportBackgroundFile &&
        !configuration.publictransportBackground && [
          {
            msg: 'This field is required',
          },
        ];
      newErrors.errors.findmycarBackground = !configuration.findmycarBackgroundFile &&
        !configuration.findmycarBackground && [
          {
            msg: 'This field is required',
          },
        ];

      setErrors(newErrors);
    } else {
      log(configuration, 'poi');
      Modal.confirm({
        title: 'Confirmation',
        content: <ModalConfirmationContent />,
        onOk: () =>
          dispatch(
            transportUpdate({
              ...configuration,
              siteId,
            })
          ),
      });
    }
  };

  const onSelectTransport = (val) => {
    setActiveTransport(val);
  };

  const onChangeDescription = (e) => {
    e.preventDefault();
    log(e, 'val description');
    const newConfig = { ...configuration };
    newConfig[activeTransport][e.target.name].description = e.target.value;

    setConfiguration((prevState) => ({
      ...prevState,
      [`${activeTransport}`]: newConfig[activeTransport],
    }));
  };

  const handleAddContent = (event) => {
    event.preventDefault();
    const newConfig = { ...configuration };
    newConfig[activeTransport].push({ logo: null, description: '' });
    setConfiguration({ ...newConfig });
  };

  const handleDeleteTransport = (index) => {
    const newConfig = { ...configuration };
    newConfig[activeTransport].splice(index, 1);
    setConfiguration({ ...newConfig });
  };
  // const canSubmit = poi.name && poi.description;

  const carparkBackgroundPreviewUrl = configuration?.carparkBackgroundFile
    ? configuration?.carparkBackgroundFile &&
      URL.createObjectURL(configuration?.carparkBackgroundFile)
    : configuration?.carparkBackground &&
      `${imgUrl}/media/${configuration?.carparkBackground}`;
  const carparkQrcodePreviewUrl = configuration?.carparkQrcodeFile
    ? configuration?.carparkQrcodeFile &&
      URL.createObjectURL(configuration?.carparkQrcodeFile)
    : configuration?.carparkQrcode &&
      `${imgUrl}/media/${configuration?.carparkQrcode}`;
  const carparkContentPreviewUrl = configuration?.carparkContentFile
    ? configuration?.carparkContentFile &&
      URL.createObjectURL(configuration?.carparkContentFile)
    : configuration?.carparkContent &&
      `${imgUrl}/media/${configuration?.carparkContent}`;
  const transportBackgroundPreviewUrl = configuration?.transportBackgroundFile
    ? configuration?.transportBackgroundFile &&
      URL.createObjectURL(configuration?.transportBackgroundFile)
    : configuration?.publictransportBackground &&
      `${imgUrl}/media/${configuration?.publictransportBackground}`;
  const findmycarBackgroundPreviewUrl = configuration?.findmycarBackgroundFile
    ? configuration?.findmycarBackgroundFile &&
      URL.createObjectURL(configuration?.findmycarBackgroundFile)
    : configuration?.findmycarBackground &&
      `${imgUrl}/media/${configuration?.findmycarBackground}`;
  const transportPreviewUrl = configuration[`${activeTransport}File`]
    ? configuration[`${activeTransport}File`] &&
      URL.createObjectURL(configuration[`${activeTransport}File`])
    : configuration[`${activeTransport}Media`] &&
      `${imgUrl}/media/${configuration[`${activeTransport}Media`]}`;

  const onReset = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: 'Confirmation',
      content: <ModalResetContent />,
      onOk: () => {
        const newConf = {
          ...configuration,
          carparkBackgroundFile: null,
          carparkBackground: null,
          carparkQrcodeFile: null,
          carparkQrcode: null,
          carparkContentFile: null,
          carparkContent: null,
          transportBackgroundFile: null,
          publictransportBackground: null,
          mrtFile: null,
          mrtMedia: null,
          busFile: null,
          busMedia: null,
          taxiFile: null,
          taxiMedia: null,
          mrt: [],
          bus: [],
          taxi: [],
        };
        setConfiguration({ ...newConf });
      },
    });
  };

  return (
    <div className="transport-form">
      <h1>Transport Requirement</h1>
      <form className="form-wrapper">
        <div>
          <div className="sub-title">Carpark Rates</div>
          <InformationBox />
          <div className="form">
            <label className="form-label">
              Media for background tile: Carpark Rates [W1000 px x H755 px]
            </label>
            {carparkBackgroundPreviewUrl ? (
              <div className="upload-image-preview">
                <Tooltip placement="top" title="Delete">
                  <button
                    type="button"
                    onClick={() =>
                      handleDeletePreviewImage(
                        'carparkBackgroundFile',
                        'carparkBackground'
                      )
                    }
                    className="btn-remove"
                  >
                    <i className="feather-x" />
                  </button>
                </Tooltip>
                <ImageUploadPreview
                  imagePreviewUrl={carparkBackgroundPreviewUrl}
                  fileParams={configuration?.carparkBackgroundFile}
                />
              </div>
            ) : (
              <Upload
                accept="image/*,video/mp4"
                showUploadList={false}
                beforeUpload={(file) =>
                  onFileChangedHandler(file, 'carparkBackgroundFile')
                }
              >
                <div
                  onDragLeave={() => setIsDragged(false)}
                  onDragOver={() => setIsDragged(true)}
                  className={`upload-drag-and-drop ${
                    isDragged ? 'active' : ''
                  }`}
                >
                  <ExportIcon size={22} color="#C2C2C2" />
                  <p className="upload-title">
                    Drag or <span className="upload-highlight">Browse</span>
                  </p>
                  <p className="upload-subtitle">
                    File format supported: jpg, png (Up to 5MB){' '}
                  </p>
                </div>
              </Upload>
            )}
            {errors && errors.errors && errors.errors.carparkBackground && (
              <span className="error-message">
                {errors.errors.carparkBackground[0].msg}
              </span>
            )}
          </div>
          <div className="form">
            <label className="form-label">Carpark Image Content</label>
            {carparkContentPreviewUrl ? (
              <div className="upload-image-preview">
                <Tooltip placement="top" title="Delete">
                  <button
                    type="button"
                    onClick={() =>
                      handleDeletePreviewImage(
                        'carparkContentFile',
                        'carparkContent'
                      )
                    }
                    className="btn-remove"
                  >
                    <i className="feather-x" />
                  </button>
                </Tooltip>
                <img
                  className="media-avatar"
                  src={carparkContentPreviewUrl}
                  alt="User profile logo preview"
                />
              </div>
            ) : (
              <Upload
                accept="image/*"
                showUploadList={false}
                beforeUpload={(file) =>
                  onFileChangedHandler(file, 'carparkContentFile')
                }
              >
                <div
                  onDragLeave={() => setIsDragged(false)}
                  onDragOver={() => setIsDragged(true)}
                  className={`upload-drag-and-drop ${
                    isDragged ? 'active' : ''
                  }`}
                >
                  <ExportIcon size={22} color="#C2C2C2" />
                  <p className="upload-title">
                    Drag or <span className="upload-highlight">Browse</span>
                  </p>
                  <p className="upload-subtitle">
                    File format supported: jpg, png (Up to 5MB){' '}
                  </p>
                </div>
              </Upload>
            )}
            {errors && errors.errors && errors.errors.carparkContent && (
              <span className="error-message">
                {errors.errors.carparkContent[0].msg}
              </span>
            )}
          </div>
          <div className="form">
            <label className="form-label">Road Closure Description</label>

            <ReactQuill
              modules={{
                toolbar: [
                  [{ header: '1' }, { header: '2' }],
                  [{ size: [] }],
                  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                ],
                clipboard: {
                  // toggle to add extra line breaks when pasting HTML:
                  matchVisual: false,
                },
              }}
              formats={[
                'header',
                'font',
                'size',
                'bold',
                'italic',
                'underline',
                'strike',
                'blockquote',
                'list',
                'bullet',
                'indent',
                'link',
                'image',
                'video',
              ]}
              theme="snow"
              value={configuration?.carparkDescription ?? ''}
              onChange={(val) =>
                setConfiguration({ ...configuration, carparkDescription: val })
              }
            />

            {errors && errors.errors && errors.errors.description && (
              <span className="error-message">
                {errors.errors.description[0].msg}
              </span>
            )}
          </div>
          <div className="form">
            <label className="form-label">Road Closure QR Code</label>
            {carparkQrcodePreviewUrl ? (
              <div className="upload-image-preview">
                <Tooltip placement="top" title="Delete">
                  <button
                    type="button"
                    onClick={() =>
                      handleDeletePreviewImage(
                        'carparkQrcodeFile',
                        'carparkQrcode'
                      )
                    }
                    className="btn-remove"
                  >
                    <i className="feather-x" />
                  </button>
                </Tooltip>
                <ImageUploadPreview
                  imagePreviewUrl={carparkQrcodePreviewUrl}
                  fileParams={configuration?.carparkQrcodeFile}
                />
              </div>
            ) : (
              <Upload
                accept="image/*,video/mp4"
                showUploadList={false}
                beforeUpload={(file) =>
                  onFileChangedHandler(file, 'carparkQrcodeFile')
                }
              >
                <div
                  onDragLeave={() => setIsDragged(false)}
                  onDragOver={() => setIsDragged(true)}
                  className={`upload-drag-and-drop ${
                    isDragged ? 'active' : ''
                  }`}
                >
                  <ExportIcon size={22} color="#C2C2C2" />
                  <p className="upload-title">
                    Drag or <span className="upload-highlight">Browse</span>
                  </p>
                  <p className="upload-subtitle">
                    File format supported: jpg, png (Up to 5MB){' '}
                  </p>
                </div>
              </Upload>
            )}
            {errors && errors.errors && errors.errors.carparkQrcode && (
              <span className="error-message">
                {errors.errors.carparkQrcode[0].msg}
              </span>
            )}
          </div>

          <div className="sub-title">Public Transport Information</div>
          <InformationBox />
          <div className="form">
            <label className="form-label">
              Media for background tile: Public transport information [W1000 px
              x H755 px]
            </label>
            {transportBackgroundPreviewUrl ? (
              <div className="upload-image-preview">
                <Tooltip placement="top" title="Delete">
                  <button
                    type="button"
                    onClick={() =>
                      handleDeletePreviewImage(
                        'transportBackgroundFile',
                        'publictransportBackground'
                      )
                    }
                    className="btn-remove"
                  >
                    <i className="feather-x" />
                  </button>
                </Tooltip>
                <ImageUploadPreview
                  imagePreviewUrl={transportBackgroundPreviewUrl}
                  fileParams={configuration?.transportBackgroundFile}
                />
              </div>
            ) : (
              <Upload
                accept="image/*,video/mp4"
                showUploadList={false}
                beforeUpload={(file) =>
                  onFileChangedHandler(file, 'transportBackgroundFile')
                }
              >
                <div
                  onDragLeave={() => setIsDragged(false)}
                  onDragOver={() => setIsDragged(true)}
                  className={`upload-drag-and-drop ${
                    isDragged ? 'active' : ''
                  }`}
                >
                  <ExportIcon size={22} color="#C2C2C2" />
                  <p className="upload-title">
                    Drag or <span className="upload-highlight">Browse</span>
                  </p>
                  <p className="upload-subtitle">
                    File format supported: jpg, png (Up to 5MB){' '}
                  </p>
                </div>
              </Upload>
            )}
            {errors && errors.errors && errors.errors.transportBackground && (
              <span className="error-message">
                {errors.errors.transportBackground[0].msg}
              </span>
            )}
          </div>
          <div className="form">
            <label className="form-label">Transport Method</label>
            <Select
              onChange={onSelectTransport}
              defaultValue={activeTransport}
              options={transports}
              value={activeTransport}
            />
          </div>
          {activeTransport && (
            <>
              <div className="form">
                <label className="form-label">Upload Image</label>
                {transportPreviewUrl ? (
                  <div className="upload-image-preview">
                    <Tooltip placement="top" title="Delete">
                      <button
                        type="button"
                        onClick={() =>
                          handleDeletePreviewImage(
                            `${activeTransport}File`,
                            `${activeTransport}Media`
                          )
                        }
                        className="btn-remove"
                      >
                        <i className="feather-x" />
                      </button>
                    </Tooltip>
                    <img
                      className="media-avatar"
                      src={transportPreviewUrl}
                      alt="User profile logo preview"
                    />
                  </div>
                ) : (
                  <Upload
                    accept="image/*"
                    showUploadList={false}
                    beforeUpload={(file) =>
                      onFileChangedHandler(file, `${activeTransport}File`)
                    }
                  >
                    <div
                      onDragLeave={() => setIsDragged(false)}
                      onDragOver={() => setIsDragged(true)}
                      className={`upload-drag-and-drop ${
                        isDragged ? 'active' : ''
                      }`}
                    >
                      <ExportIcon size={22} color="#C2C2C2" />
                      <p className="upload-title">
                        Drag or <span className="upload-highlight">Browse</span>
                      </p>
                      <p className="upload-subtitle">
                        File format supported: jpg, png (Up to 5MB){' '}
                      </p>
                    </div>
                  </Upload>
                )}
              </div>

              <div className="form">
                <label className="form-label">Content</label>
                {configuration[activeTransport] &&
                  configuration[activeTransport].map((transport, index) => {
                    const logoPreviewUrl = transport.logoFile
                      ? transport.logoFile &&
                        URL.createObjectURL(transport.logoFile)
                      : transport.logo && `${imgUrl}/media/${transport.logo}`;
                    log(transport, logoPreviewUrl, 'transport');
                    // eslint-disable-next-line
                    return (
                      <div className="form-row" key={index}>
                        <div className="logo-form">
                          {logoPreviewUrl ? (
                            <div className="upload-image-preview">
                              <Tooltip placement="top" title="Delete">
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleDeletePreviewImageContent(
                                      'logoFile',
                                      'logo',
                                      index
                                    )
                                  }
                                  className="btn-remove"
                                >
                                  <i className="feather-x" />
                                </button>
                              </Tooltip>
                              <img
                                className="media-avatar"
                                src={logoPreviewUrl}
                                alt="User profile logo preview"
                              />
                            </div>
                          ) : (
                            <Upload
                              accept="image/*"
                              showUploadList={false}
                              beforeUpload={(file) =>
                                onFileChangedHandlerContent(
                                  file,
                                  'logoFile',
                                  index
                                )
                              }
                            >
                              <div
                                onDragLeave={() => setIsDragged(false)}
                                onDragOver={() => setIsDragged(true)}
                                className={`upload-drag-and-drop ${
                                  isDragged ? 'active' : ''
                                }`}
                              >
                                <ExportIcon size={22} color="#C2C2C2" />
                                <p className="upload-title">
                                  Drag or{' '}
                                  <span className="upload-highlight">
                                    Browse
                                  </span>
                                </p>
                                {/* <p className="upload-subtitle">
                          File format supported: jpg, png
                        </p> */}
                              </div>
                            </Upload>
                          )}
                        </div>
                        <Input.TextArea
                          rows={5}
                          placeholder="Description"
                          className={`${
                            errors && errors.errors && errors.errors.description
                              ? 'has-error'
                              : ''
                          }`}
                          name={index}
                          value={transport.description}
                          onChange={onChangeDescription}
                        />
                        <button
                          type="button"
                          onClick={() => handleDeleteTransport(index)}
                          className="btn-remove"
                        >
                          <i className="feather-x" />
                        </button>
                      </div>
                    );
                  })}
                <button
                  className="btn-outline-primary"
                  onClick={handleAddContent}
                >
                  Add Transport Description
                </button>
              </div>
            </>
          )}

          {configuration.findmycarActive && (
            <>
              <div className="sub-title">Find My Car</div>
              <InformationBox />
              <div className="form">
                <label className="form-label">
                  Media for background tile: Find My Car [W1000 px x H755 px]
                </label>
                {findmycarBackgroundPreviewUrl ? (
                  <div className="upload-image-preview">
                    <Tooltip placement="top" title="Delete">
                      <button
                        type="button"
                        onClick={() =>
                          handleDeletePreviewImage(
                            'findmycarBackground',
                            'findmycarBackgroundFile'
                          )
                        }
                        className="btn-remove"
                      >
                        <i className="feather-x" />
                      </button>
                    </Tooltip>
                    <ImageUploadPreview
                      imagePreviewUrl={findmycarBackgroundPreviewUrl}
                      fileParams={configuration?.findmycarBackgroundFile}
                    />
                  </div>
                ) : (
                  <Upload
                    accept="image/*, video/mp4"
                    showUploadList={false}
                    beforeUpload={(file) =>
                      onFileChangedHandler(file, 'findmycarBackgroundFile')
                    }
                  >
                    <div
                      onDragLeave={() => setIsDragged(false)}
                      onDragOver={() => setIsDragged(true)}
                      className={`upload-drag-and-drop ${
                        isDragged ? 'active' : ''
                      }`}
                    >
                      <ExportIcon size={22} color="#C2C2C2" />
                      <p className="upload-title">
                        Drag or <span className="upload-highlight">Browse</span>
                      </p>
                      <p className="upload-subtitle">
                        File format supported: jpg, png (Up to 5MB){' '}
                      </p>
                    </div>
                  </Upload>
                )}
                {errors &&
                  errors.errors &&
                  errors.errors.findmycarBackground && (
                    <span className="error-message">
                      {errors.errors.findmycarBackground[0].msg}
                    </span>
                  )}
              </div>
            </>
          )}

          {(filterAccess({
            userData,
            siteId,
            param: 'config',
            action: 'create',
          }) ||
            filterAccess({
              userData,
              siteId,
              param: 'config',
              action: 'update',
            })) && (
            <div className="button-wrapper">
              <div>
                {loading ? (
                  <button className="button-secondary" disabled>
                    Loading...
                  </button>
                ) : (
                  <button className="button-secondary" onClick={onReset}>
                    Reset
                  </button>
                )}
              </div>
              <div>
                {loading ? (
                  <button className="button-primary" disabled>
                    Loading...
                  </button>
                ) : (
                  <button className="button-primary" onClick={onSubmitHandler}>
                    Save & Apply
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default TransportForm;
