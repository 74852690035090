import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { url } from 'utils/url';
import { log, errorMsg } from 'utils';

export const SYNCED_CATEGORIES_FETCH_DATA_INIT = createAction(
  'SYNCED_CATEGORIES_FETCH_DATA_INIT'
);
export const SYNCED_CATEGORIES_FETCH_DATA_SUCCESS = createAction(
  'SYNCED_CATEGORIES_FETCH_DATA_SUCCESS'
);
export const SYNCED_CATEGORIES_FETCH_DATA_FAIL = createAction(
  'SYNCED_CATEGORIES_FETCH_DATA_FAIL'
);

export const SYNCED_CATEGORIES_DELETE_CATEGORY_INIT = createAction(
  'SYNCED_CATEGORIES_DELETE_CATEGORY_INIT'
);
export const SYNCED_CATEGORIES_DELETE_CATEGORY_SUCCESS = createAction(
  'SYNCED_CATEGORIES_DELETE_CATEGORY_SUCCESS'
);
export const SYNCED_CATEGORIES_DELETE_CATEGORY_FAIL = createAction(
  'SYNCED_CATEGORIES_DELETE_CATEGORY_FAIL'
);

export const SYNCED_CATEGORIES_CREATE_CATEGORY_INIT = createAction(
  'SYNCED_CATEGORIES_CREATE_CATEGORY_INIT'
);
export const SYNCED_CATEGORIES_CREATE_CATEGORY_SUCCESS = createAction(
  'SYNCED_CATEGORIES_CREATE_CATEGORY_SUCCESS'
);
export const SYNCED_CATEGORIES_CREATE_CATEGORY_FAIL = createAction(
  'SYNCED_CATEGORIES_CREATE_CATEGORY_FAIL'
);

export const fetchSyncedCategories = ({ siteId }) => {
  // eslint-disable-next-line
  return async (dispatch, getState) => {
    dispatch(SYNCED_CATEGORIES_FETCH_DATA_INIT());

    if (siteId) {
      let categories;
      try {
        categories = await Axios.get(`${url}/synced`, {
          params: {
            siteId,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(SYNCED_CATEGORIES_FETCH_DATA_FAIL({ error }));
      }
      categories = categories.data.data;
      return dispatch(
        SYNCED_CATEGORIES_FETCH_DATA_SUCCESS({
          list: categories,
        })
      );
    }
  };
};

export const deleteSyncedCategory = ({ id, siteId }) => {
  return async (dispatch) => {
    dispatch(SYNCED_CATEGORIES_DELETE_CATEGORY_INIT());

    try {
      await Axios.delete(`${url}/synced/delete`, {
        params: {
          id,
          siteId,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        SYNCED_CATEGORIES_DELETE_CATEGORY_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The category was deleted.');

    return dispatch(SYNCED_CATEGORIES_DELETE_CATEGORY_SUCCESS({ id }));
  };
};

export const createSyncedCategory = ({ categoryIds, siteId }) => {
  return async (dispatch) => {
    dispatch(SYNCED_CATEGORIES_CREATE_CATEGORY_INIT());
    let createCategory;
    const category = {
      siteId,
      categoryIds,
    };

    try {
      createCategory = await Axios.post(`${url}/synced/create`, category, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      log(createCategory, '<<< created cat');
    } catch (error) {
      log(error.response, '<<< ERROR CREATE SYNCED CATEGORY');
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        SYNCED_CATEGORIES_CREATE_CATEGORY_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'Category created successfully');

    return dispatch(
      SYNCED_CATEGORIES_CREATE_CATEGORY_SUCCESS({
        category: createCategory.data.data,
      })
    );
  };
};
