import { Modal } from 'antd';
import React from 'react';

function ModalCodeBlock({ isVisible, title, data, onCancel, loading }) {
  return (
    <Modal
      visible={isVisible}
      title={title}
      onCancel={onCancel}
      className="adsign-modal"
      footer={null}
      closeIcon={<i className="feather-x" />}
      width={450}
    >
      {data && (
        <div className="modal-content">
          <pre>{JSON.stringify(data, null, 2)}</pre>
          {/* <p className="modal-content-title">{data['jcr:title']}</p>
          {data.openinghours && (
            <p className="modal-content-description">
              {`${data.openinghours[0]?.starttime} - ${data.openinghours[0]?.endtime}`}
            </p>
          )}
          {data.storephonenumber && (
            <p className="modal-content-description">
              {`${data.storephonenumber}`}
            </p>
          )}
          {data['jcr:description'] && (
            <p className="modal-content-description">
              {ReactHtmlParser(data['jcr:description'])}
            </p>
          )} */}
        </div>
      )}
    </Modal>
  );
}

export default ModalCodeBlock;
