import { createReducer } from 'redux-act';

import {
  DIRECTORIES_FETCH_DATA_INIT,
  DIRECTORIES_FETCH_DATA_SUCCESS,
  DIRECTORIES_FETCH_DATA_FAIL,
  DIRECTORIES_DELETE_DIRECTORY_INIT,
  DIRECTORIES_DELETE_DIRECTORY_SUCCESS,
  DIRECTORIES_DELETE_DIRECTORY_FAIL,
  DIRECTORIES_CLEAR_DATA,
  DIRECTORIES_CREATE_DIRECTORY_INIT,
  DIRECTORIES_CREATE_DIRECTORY_SUCCESS,
  DIRECTORIES_CREATE_DIRECTORY_FAIL,
  DIRECTORIES_MODIFY_DIRECTORY_INIT,
  DIRECTORIES_MODIFY_DIRECTORY_SUCCESS,
  DIRECTORIES_MODIFY_DIRECTORY_FAIL,
  DIRECTORIES_CLEAN_UP,
  DIRECTORIES_CLEAR_DATA_LOGOUT,
  SET_ACTIVE_DIRECTORY,
} from 'state/actions/directories';

const initialState = {
  list: [],
  directory: {
    name: '',
    description: '',
    siteId: '',
    siteName: '',
    modifiedAt: new Date().toDateString(),
    modifiedBy: '',
  },
  loading: false,
  error: null,
  success: false,
  deleted: false,
  updated: false,
  tab: 'directorySettings',
  activeScreen: {
    _id: '',
    name: '',
    description: '',
  },
};

export const directoriesReducer = createReducer(
  {
    [DIRECTORIES_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [DIRECTORIES_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      list: payload.list || [],
      directory: payload.directory || initialState.directory,
      loading: false,
      error: null,
    }),
    [DIRECTORIES_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [DIRECTORIES_DELETE_DIRECTORY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [DIRECTORIES_DELETE_DIRECTORY_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [DIRECTORIES_DELETE_DIRECTORY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [DIRECTORIES_CLEAR_DATA]: (state) => ({
      ...initialState,
      list: state.list,
    }),
    [DIRECTORIES_CREATE_DIRECTORY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [DIRECTORIES_CREATE_DIRECTORY_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.concat(payload.directory),
      loading: false,
      error: null,
      success: true,
      updated: true,
    }),
    [DIRECTORIES_CREATE_DIRECTORY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [DIRECTORIES_MODIFY_DIRECTORY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [DIRECTORIES_MODIFY_DIRECTORY_SUCCESS]: (state, payload) => ({
      ...state,
      list: !state.list
        ? []
        : state.list.map((elem) => {
            if (elem.id === payload.id) {
              return {
                name: payload.directory.name,
                description: payload.directory.description,
                organizationId: payload.directory.organizationId,
                organizationName: payload.directory.organizationName,
                modifiedAt: new Date().toDateString(),
                modifiedBy: elem.email,
              };
            }
            return elem;
          }),
      loading: false,
      error: null,
      success: true,
      updated: true,
    }),
    [DIRECTORIES_MODIFY_DIRECTORY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [DIRECTORIES_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
      updated: false,
    }),
    [DIRECTORIES_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),

    [SET_ACTIVE_DIRECTORY]: (state, payload) => {
      return {
        ...state,
        activeScreen: payload,
      };
    },
  },
  initialState
);
