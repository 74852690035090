/* eslint-disable jsx-a11y/label-has-associated-control */
import { Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
// import InputMask from 'react-input-mask';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
// import { ExportIcon } from 'assets/icon/dynamic';
import { log } from 'utils';
import { amenitiesUpdate } from 'state/actions/configuration';
import 'react-quill/dist/quill.snow.css';
import ModalConfirmationContent from 'components/ModalConfirmationContent';
import { arrayMoveImmutable } from 'array-move';
import { DragIcon } from 'assets/icon';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { filterAccess } from 'components/Report/utils';
import './AmenitiesForm.css';
import ModalResetContent from 'components/ModalResetContent';
import ListAmenities from 'assets/List_of_Amenities.png';

const AmenitiesForm = ({
  configuration,
  userData,
  setConfiguration,
  loading,
}) => {
  const { siteId } = useParams();

  // const [isDragged, setIsDragged] = useState(false);
  const [errors, setErrors] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    const newConfig = { ...configuration };

    if (newConfig.amenities?.length === 0 || !newConfig.amenities) {
      newConfig.amenities = [{ logo: null, description: '' }];
      setConfiguration({
        ...newConfig,
        mrt: newConfig.mrt,
        bus: newConfig.bus,
        taxi: newConfig.taxi,
      });
    }

    log(configuration, 'configuration');
  }, [configuration]); //eslint-disable-line
  useEffect(() => {
    setErrors();
  }, [loading]); //eslint-disable-line

  const onSubmitHandler = (event) => {
    event.preventDefault();

    log(configuration, 'poi');
    Modal.confirm({
      title: 'Confirmation',
      content: <ModalConfirmationContent />,
      onOk: () =>
        dispatch(
          amenitiesUpdate({
            ...configuration,
            siteId,
          })
        ),
    });
  };

  const onChangeDescription = (e) => {
    e.preventDefault();
    log(e, 'val description');
    const newConfig = { ...configuration };
    newConfig.amenities[e.target.name].description = e.target.value;

    setConfiguration((prevState) => ({
      ...prevState,
      amenities: newConfig.amenities,
    }));
  };

  const handleAddContent = (event) => {
    event.preventDefault();
    const newConfig = { ...configuration };
    newConfig.amenities.push({ logo: null, description: '' });
    setConfiguration({ ...newConfig });
  };

  const handleDeleteTransport = (index) => {
    const newConfig = { ...configuration };
    newConfig.amenities.splice(index, 1);
    setConfiguration({ ...newConfig });
  };
  // const canSubmit = poi.name && poi.description;

  const onReset = (e) => {
    e.preventDefault();

    Modal.confirm({
      title: 'Confirmation',
      content: <ModalResetContent />,
      onOk: () => {
        const newConf = {
          ...configuration,

          amenities: [],
        };
        setConfiguration({ ...newConf });
      },
    });
  };

  return (
    <div className="amenities-form">
      <h1>Amenities Content</h1>
      <form className="form-wrapper">
        <div>
          <div className="form">
            <img
              src={ListAmenities}
              alt="list of amenities"
              style={{ width: '60%' }}
            />
            <DragDropContext
              onDragEnd={(result) => {
                const amenities = arrayMoveImmutable(
                  configuration.amenities,
                  result.source.index,
                  result.destination.index
                );
                setConfiguration({ ...configuration, amenities });
              }}
            >
              <DragContainer
                configuration={configuration}
                errors={errors}
                handleDeleteTransport={handleDeleteTransport}
                handleAddContent={handleAddContent}
                onChangeDescription={onChangeDescription}
                distance={1}
              />
            </DragDropContext>
            <button className="btn-outline-primary" onClick={handleAddContent}>
              Add New Amenities
            </button>
          </div>

          {(filterAccess({
            userData,
            siteId,
            param: 'config',
            action: 'create',
          }) ||
            filterAccess({
              userData,
              siteId,
              param: 'config',
              action: 'update',
            })) && (
            <div className="button-wrapper">
              <div>
                {loading ? (
                  <button className="button-secondary" disabled>
                    Loading...
                  </button>
                ) : (
                  <button className="button-secondary" onClick={onReset}>
                    Reset
                  </button>
                )}
                {/* <button className="button-secondary" onClick={handleReset}>
            Reset
          </button> */}
              </div>
              <div>
                {loading ? (
                  <button className="button-primary" disabled>
                    Loading...
                  </button>
                ) : (
                  <button className="button-primary" onClick={onSubmitHandler}>
                    Save & Apply
                  </button>
                )}
                {/* <button className="button-secondary" onClick={handleReset}>
            Reset
          </button> */}
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

const DragContainer = ({
  configuration,
  errors,
  handleDeleteTransport,
  onChangeDescription,
}) => {
  return (
    <Droppable droppableId="droppableId">
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          {configuration.amenities &&
            configuration.amenities.map((transport, ind) => {
              return (
                <DragElement
                  ind={ind}
                  errors={errors}
                  index={ind}
                  transport={transport}
                  onChangeDescription={onChangeDescription}
                  handleDeleteTransport={handleDeleteTransport}
                />
              );
            })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

const DragElement = ({
  ind,
  errors,
  transport,
  onChangeDescription,
  handleDeleteTransport,
}) => {
  return (
    <Draggable draggableId={`draggable-${ind}`} index={ind} key={ind}>
      {(provided) => (
        <div
          className="form-row"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <DragIcon />
          <Input.TextArea
            rows={5}
            placeholder="Description"
            className={`${
              errors && errors.errors && errors.errors.description
                ? 'has-error'
                : ''
            }`}
            name={ind}
            value={transport.description}
            onChange={onChangeDescription}
          />
          <button
            type="button"
            onClick={() => handleDeleteTransport(ind)}
            className="btn-remove"
          >
            <i className="feather-x" />
          </button>
        </div>
      )}
    </Draggable>
  );
};

export default AmenitiesForm;
