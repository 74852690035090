import { Modal, Switch, Tag } from 'antd';
import React from 'react';

function ModalDetailNotification({
  isVisible,
  data,
  onUpdate,
  onCancel,
  setData,
  loading,
}) {
  const getStatus = (status) => {
    // console.log(data.statu, 'data status');
    if (status)
      switch (status) {
        case 'ongoing':
          console.log('ongoing');
          return <Tag color="gold">ONGOING</Tag>;
        case 'done':
          return <Tag color="green">APPROVED</Tag>;
        case 'expired':
          return <Tag color="red">EXPIRED</Tag>;
        default:
          return <span>-</span>;
      }
  };

  const items = data
    ? [
        {
          key: '1',
          label: 'Title',
          children: data.title,
        },
        {
          key: '2',
          label: 'Notes',
          children: data.notes,
        },
        {
          key: '3',
          label: 'Unit Number',
          children: data.unitnumber.join(', '),
        },
        {
          key: '4',
          label: 'Site',
          children: data.propertiesName,
        },
        {
          key: '5',
          label: 'Status',
          children: getStatus(data.status),
        },
      ]
    : [];

  const onChange = (checked) => {
    setData({ ...data, status: checked ? 'done' : 'ongoing' });
    onUpdate({ ...data, status: checked ? 'done' : 'ongoing' });
  };
  return (
    <Modal
      open={isVisible}
      title="Notification Details"
      onCancel={onCancel}
      className="notification-dialog"
      footer={
        <div className="notification-footer">
          <div className="row-data-footer" key="footer">
            <label className="label-preview">Admin's approval</label>
            <div>Switch to indicate that the notification has updated</div>
          </div>
          <Switch
            onChange={onChange}
            checked={data && data.status === 'done'}
          />
        </div>
      }
      closeIcon={<i className="feather-x" />}
      width={450}
    >
      {data && (
        <div className="modal-content">
          {items.map((item) => (
            <div className="row-data" key={item.key}>
              <label className="label-preview">{item.label}</label>
              <div>{item.children}</div>
            </div>
          ))}
        </div>
      )}
    </Modal>
  );
}

export default ModalDetailNotification;
