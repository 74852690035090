import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { url } from 'utils/url';
import { errorMsg } from 'utils';
import dayjs from 'dayjs';

export const DEALS_FETCH_DATA_INIT = createAction('DEALS_FETCH_DATA_INIT');
export const DEALS_FETCH_DATA_SUCCESS = createAction(
  'DEALS_FETCH_DATA_SUCCESS'
);
export const DEALS_FETCH_DATA_FAIL = createAction('DEALS_FETCH_DATA_FAIL');

export const DEALS_DELETE_DEAL_INIT = createAction('DEALS_DELETE_DEAL_INIT');
export const DEALS_DELETE_DEAL_SUCCESS = createAction(
  'DEALS_DELETE_DEAL_SUCCESS'
);
export const DEALS_DELETE_DEAL_FAIL = createAction('DEALS_DELETE_DEAL_FAIL');

export const DEALS_CLEAR_DATA = createAction('DEALS_CLEAR_DATA');

export const DEALS_CREATE_DEAL_INIT = createAction('DEALS_CREATE_DEAL_INIT');
export const DEALS_CREATE_DEAL_SUCCESS = createAction(
  'DEALS_CREATE_DEAL_SUCCESS'
);
export const DEALS_CREATE_DEAL_FAIL = createAction('DEALS_CREATE_DEAL_FAIL');

export const DEALS_MODIFY_DEAL_INIT = createAction('DEALS_MODIFY_DEAL_INIT');
export const DEALS_MODIFY_DEAL_SUCCESS = createAction(
  'DEALS_MODIFY_DEAL_SUCCESS'
);
export const DEALS_MODIFY_DEAL_FAIL = createAction('DEALS_MODIFY_DEAL_FAIL');

export const DEALS_CLEAN_UP = createAction('DEALS_CLEAN_UP');

export const DEALS_CLEAR_DATA_LOGOUT = createAction('DEALS_CLEAR_DATA_LOGOUT');
export const DEALS_SYNC_DEAL_INIT = createAction('DEALS_SYNC_DEAL_INIT');
export const DEALS_SYNC_DEAL_SUCCESS = createAction('DEALS_SYNC_DEAL_SUCCESS');
export const DEALS_SYNC_DEAL_FAIL = createAction('DEALS_SYNC_DEAL_FAIL');

export const fetchDeal = ({
  dealId,
  siteId,
  type,
  // categoryId = 'all',
  // type = 'published',
}) => {
  // eslint-disable-next-line
  return async (dispatch, getState) => {
    dispatch(DEALS_FETCH_DATA_INIT());
    if (dealId) {
      let dealData;
      try {
        dealData = await Axios.get(`${url}/deal/list`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
          params: {
            siteId,
          },
        });
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(DEALS_FETCH_DATA_FAIL({ error }));
      }
      const dealFind = dealData.data.data.find((x) => x._id === dealId);
      const deal = { ...dealFind, id: dealId };
      return dispatch(
        DEALS_FETCH_DATA_SUCCESS({
          list: getState().deals.list,
          deal,
        })
      );
    }

    if (siteId) {
      let deals;
      try {
        deals = await Axios.post(
          `${url}/deal/filter`,
          {
            siteId,
            type,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          }
        );
      } catch (error) {
        let errorMessage;
        if (error.response) {
          errorMessage = error && error.response && error.response.data.message;
        }
        toastr.error('', errorMessage);
        return dispatch(DEALS_FETCH_DATA_FAIL({ error }));
      }
      deals = deals.data.data;
      return dispatch(
        DEALS_FETCH_DATA_SUCCESS({
          list: deals,
          deal: getState().deals.deal,
        })
      );
    }
  };
};

export const syncDeal = () => {
  return async (dispatch) => {
    dispatch(DEALS_SYNC_DEAL_INIT());
    Axios.get(`${url}/deal/fetch`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    })
      .then(() => {
        return dispatch(DEALS_SYNC_DEAL_SUCCESS());
      })
      .catch((error) => {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(DEALS_SYNC_DEAL_FAIL({ error }));
      });
  };
};

export const createDeal = (deal) => {
  return async (dispatch) => {
    dispatch(DEALS_CREATE_DEAL_INIT());

    // let createDeal;

    const event = new FormData();
    event.set('path', 'event');
    event.set('jcr:title', deal['jcr:title']);
    event.set('jcr:description', deal['jcr:description']);
    event.set('tenants', JSON.stringify(deal.tenants));
    event.set('isRibbon', deal.isRibbon);
    if (deal.isRibbon) {
      event.set('ribbon', deal.ribbon);
      event.set('ribboncolor', deal.ribboncolor);
    }

    event.set(
      'sortimportance',
      deal.sortimportance ? deal.sortimportance : '999'
    );

    event.set('siteId', deal.siteId);
    event.append('file', deal.file);
    if (deal.start && deal.end) {
      event.set(
        'start',
        dayjs.isDayjs(deal.start)
          ? deal.start.format()
          : dayjs(deal.start).format()
      );
      event.set(
        'end',
        dayjs.isDayjs(deal.end) ? deal.end.format() : dayjs(deal.end).format()
      );
    }

    if (deal.starttime) event.set('starttime', deal.starttime);
    if (deal.endtime) event.set('endtime', deal.endtime);
    // if (deal.starttime && deal.endtime) {
    //   event.set(
    //     'starttime',
    //     dayjs.isDayjs(deal.starttime)
    //       ? deal.starttime.format()
    //       : dayjs(deal.starttime).format()
    //   );
    //   event.set(
    //     'endtime',
    //     dayjs.isDayjs(deal.endtime)
    //       ? deal.endtime.format()
    //       : dayjs(deal.endtime).format()
    //   );
    // }

    try {
      await Axios.post(`${url}/deal/create`, event, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'content-type': 'multipart/form-data',
        },
      });
    } catch (error) {
      // let errorMessage;
      // if (error.response) {
      //   errorMessage = error && error.response && error.response.data.message;
      // }

      toastr.error('', 'Please fill in all required fields');
      // toastr.error('', errorMessage);
      return dispatch(
        DEALS_CREATE_DEAL_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }
    const eventJson = JSON.stringify(Object.fromEntries(event));
    toastr.success('', 'Deal created successfully');
    return dispatch(DEALS_CREATE_DEAL_SUCCESS({ event: eventJson }));
  };
};

export const modifyDeal = (deal) => {
  return async (dispatch) => {
    dispatch(DEALS_MODIFY_DEAL_INIT());

    const event = new FormData();
    event.set('path', 'event');
    event.set('id', deal.id);
    event.set('jcr:title', deal['jcr:title']);
    if (deal['jcr:description'])
      event.set('jcr:description', deal['jcr:description']);
    if (deal.starttime) event.set('starttime', deal.starttime);
    event.set('isRibbon', deal.isRibbon);
    event.set('ribbon', deal.ribbon);
    event.set('tenants', JSON.stringify(deal.tenants));
    event.set('ribboncolor', deal.ribboncolor);
    event.set(
      'sortimportance',
      deal.sortimportance ? deal.sortimportance : '999'
    );
    if (deal.endtime) event.set('endtime', deal.endtime);
    event.set('siteId', deal.siteId);
    if (deal.thumbnail) {
      event.set('thumbnail', deal.thumbnail);
    }
    event.append('file', deal.file);

    if (deal.start && deal.end) {
      event.set(
        'start',
        dayjs.isDayjs(deal.start)
          ? deal.start.format()
          : dayjs(deal.start).format()
      );
      event.set(
        'end',
        dayjs.isDayjs(deal.end) ? deal.end.format() : dayjs(deal.end).format()
      );
    }
    if (deal.starttime) event.set('starttime', deal.starttime);
    if (deal.endtime) event.set('endtime', deal.endtime);

    try {
      await Axios.put(`${url}/deal/edit`, event, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'content-type': 'multipart/form-data',
        },
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        DEALS_MODIFY_DEAL_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }
    const eventJson = JSON.stringify(Object.fromEntries(event));
    toastr.success('', 'Deal updated successfully');
    return dispatch(DEALS_MODIFY_DEAL_SUCCESS({ eventJson, id: deal.id }));
  };
};

export const deleteDeal = ({ id, siteId }, cb) => {
  return async (dispatch) => {
    dispatch(DEALS_DELETE_DEAL_INIT());
    try {
      await Axios.post(
        `${url}/deal/delete`,
        {
          dealId: id,
          siteId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        DEALS_DELETE_DEAL_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'The event was deleted.');
    cb();
    return dispatch(DEALS_DELETE_DEAL_SUCCESS({ id }));
  };
};

export const dealsCleanUp = () => (dispatch) => dispatch(DEALS_CLEAN_UP());
