import { createAction } from 'redux-act';

export const ACTIVE_MENU = createAction('ACTIVE_MENU');
export const ACTIVE_TAB_MEDIA = createAction('ACTIVE_TAB_MEDIA');
export const ACTIVE_TAB_EVENT = createAction('ACTIVE_TAB_EVENT');
export const ACTIVE_TAB_TENANT = createAction('ACTIVE_TAB_TENANT');
export const ACTIVE_TAB_DEAL = createAction('ACTIVE_TAB_DEAL');

export const setBreadcrumb = (list) => {
  return async (dispatch) => {
    return dispatch(ACTIVE_MENU({ list }));
  };
};

export const setTabMedia = (media) => {
  return async (dispatch) => {
    return dispatch(ACTIVE_TAB_MEDIA({ media }));
  };
};
export const setTabEvent = (event) => {
  return async (dispatch) => {
    return dispatch(ACTIVE_TAB_EVENT({ event }));
  };
};
export const setTabDeal = (deal) => {
  return async (dispatch) => {
    return dispatch(ACTIVE_TAB_DEAL({ deal }));
  };
};

export const setTabTenant = (tenant) => {
  return async (dispatch) => {
    return dispatch(ACTIVE_TAB_TENANT({ tenant }));
  };
};
