/* eslint-disable jsx-a11y/label-has-associated-control */

import React from 'react';


const ImageUploadPreview = ({
imagePreviewUrl, 
fileParams
}) => {


  return  fileParams?.type === 'video/mp4'||imagePreviewUrl.split('.')[imagePreviewUrl.split('.').length-1] === 'mp4'  ? (
      <video
        controls="controls"
        preload="metadata"
        key={imagePreviewUrl}
      >
        <source src={imagePreviewUrl} type="video/mp4" />
      </video>
    ) : (
      <img
        className="media-avatar"
        src={imagePreviewUrl}
        alt="User profile logo preview"
      />
    );
          
    
    };


export default ImageUploadPreview;
