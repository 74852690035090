import { createReducer } from 'redux-act';

import {
  EVENTS_FETCH_DATA_INIT,
  EVENTS_FETCH_DATA_SUCCESS,
  EVENTS_FETCH_DATA_FAIL,
  EVENTS_DELETE_EVENT_INIT,
  EVENTS_DELETE_EVENT_SUCCESS,
  EVENTS_DELETE_EVENT_FAIL,
  EVENTS_CLEAR_DATA,
  EVENTS_CREATE_EVENT_INIT,
  EVENTS_CREATE_EVENT_SUCCESS,
  EVENTS_CREATE_EVENT_FAIL,
  EVENTS_MODIFY_EVENT_INIT,
  EVENTS_MODIFY_EVENT_SUCCESS,
  EVENTS_MODIFY_EVENT_FAIL,
  EVENTS_CLEAN_UP,
  EVENTS_CLEAR_DATA_LOGOUT,
  EVENTS_SYNC_EVENT_INIT,
  EVENTS_SYNC_EVENT_SUCCESS,
  EVENTS_SYNC_EVENT_FAIL,
} from 'state/actions/event';

const initialState = {
  list: [],
  event: {},
  loading: false,
  downloading: false,
  error: {
    errors: null,
    message: '',
  },
  success: false,
  deleted: false,
};

export const eventReducer = createReducer(
  {
    [EVENTS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [EVENTS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      list: payload.list || [],
      poi: payload.poi || initialState.poi,
      loading: false,
      error: {
        errors: null,
        message: '',
      },
    }),
    [EVENTS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [EVENTS_DELETE_EVENT_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [EVENTS_DELETE_EVENT_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.filter((elem) => elem._id !== payload.id),
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      deleted: true,
    }),
    [EVENTS_DELETE_EVENT_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [EVENTS_CLEAR_DATA]: (state) => ({
      ...initialState,
      list: state.list,
    }),
    [EVENTS_CREATE_EVENT_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [EVENTS_CREATE_EVENT_SUCCESS]: (state) => ({
      ...state,
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      success: true,
    }),
    [EVENTS_CREATE_EVENT_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [EVENTS_MODIFY_EVENT_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [EVENTS_MODIFY_EVENT_SUCCESS]: (state) => ({
      ...state,

      loading: false,
      error: {
        errors: null,
        message: '',
      },
      success: true,
    }),
    [EVENTS_MODIFY_EVENT_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [EVENTS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      success: false,
      deleted: false,
    }),
    [EVENTS_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
    [EVENTS_SYNC_EVENT_INIT]: (state) => ({
      ...state,
      downloading: true,
    }),
    [EVENTS_SYNC_EVENT_SUCCESS]: (state) => ({
      ...state,
      downloading: false,
      error: {
        errors: null,
        message: '',
      },
    }),
    [EVENTS_SYNC_EVENT_FAIL]: (state, payload) => ({
      ...state,
      downloading: false,
      error: payload.error,
    }),
  },
  initialState
);
