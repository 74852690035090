import React, { useState, useEffect } from 'react';
import { Tooltip, Table } from 'antd';
import moment from 'moment';
import { log } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import {
  fetchMapviewer,
  createAdsumFile,
  deleteAdsumFile,
} from 'state/actions/mapviewer';
import ModalMapviewerForm from 'components/ModalMapviewerForm';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import './MapViewer.scss';

function MapViewer() {
  const { siteId } = useParams();

  const [visible, setVisible] = useState(false);

  const { list, loading, sites } = useSelector((state) => ({
    list: state.mapviewer.list,
    loading: state.mapviewer.loading,
    sites: state.sites.list,
  }));

  const dispatch = useDispatch();

  const exportData = (data) => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      data.data
    )}`;
    const link = document.createElement('a');
    link.href = jsonString;
    link.download = `${data.type}.json`;

    link.click();
  };

  const deleteFile = (record) => {
    dispatch(
      deleteAdsumFile({
        type: record.type,
        siteId: sites.find((site) => site._id === siteId)?.adsumSiteId,
      })
    );
  };

  const columns = [
    {
      title: 'File Name',
      dataIndex: 'type',
      sorter: true,
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      sorter: true,
      render: (text, record) => {
        if (record.createdAt) {
          return (
            <div>{`${moment(record.createdAt)
              .utcOffset('+08:00')
              .format('h:mm A, MMM Do YYYY')}`}</div>
          );
        }
        return <span>-</span>;
      },
    },
    {
      title: 'Options',
      dataIndex: 'option',

      render: (text, record) => {
        return (
          <div className="sites__actions">
            <Tooltip title="Download File">
              <button
                type="button"
                onClick={() => exportData(record)}
                data-tooltip="Download"
                className="btn btn--delete"
              >
                <i className="feather-download" />
              </button>
            </Tooltip>
            <Tooltip title="Delete Site">
              <button
                type="button"
                onClick={() => deleteFile(record)}
                data-tooltip="Delete"
                className="btn btn--delete"
              >
                <i className="feather-trash-2" />
              </button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    log('map viewer page');
    dispatch(
      fetchMapviewer({
        siteId: sites.find((site) => site._id === siteId)?.adsumSiteId,
      })
    );
  }, []); // eslint-disable-line 
  // useEffect(() => {
  //   console.log('map viewer page');
  //   dispatch(fetchMapviewer({ siteId }));
  // }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(
      fetchMapviewer({
        siteId: sites.find((site) => site._id === siteId)?.adsumSiteId,
      })
    );
  }, [siteId]); // eslint-disable-line

  // const handleChangeTable = (pagination, filters, sorter, extra) => {
  //   setSort(sorter);
  // };

  const onNewFileHandler = () => {
    log('handle new site');
    setVisible(true);
  };

  return (
    <section className="files-management">
      {visible && (
        <ModalMapviewerForm
          visible={visible}
          onCreate={(val) => {
            setVisible(false);
            dispatch(
              createAdsumFile({
                file: val,
                siteId: sites.find((site) => site._id === siteId).adsumSiteId,
              })
            );
          }}
          onCancel={() => setVisible(false)}
        />
      )}

      <div className="header-content">
        <div className="header-left-content">
          <h1 className="page-title">Map Viewer Data</h1>
        </div>
        <div className="header-right-content">
          <button className="button-primary" onClick={onNewFileHandler}>
            <i className="feather-plus" />
            Add File
          </button>
        </div>
      </div>
      {loading ? (
        <ClipLoader />
      ) : (
        <div>
          <Table
            columns={columns}
            dataSource={list}
            rowKey="_id"
            // onChange={handleChangeTable}
          />
        </div>
      )}
    </section>
  );
}

export default MapViewer;
