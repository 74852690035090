import { Form, Input, InputNumber, Modal, Select, Tooltip, Upload } from 'antd';
import { useChangeHandler } from 'hooks';
import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { regexDescription, regexInput } from 'utils/regex';
import { log } from 'utils';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { ExportIcon } from 'assets/icon/dynamic';
import { imgUrl } from 'utils/url';

export default function ModalDirectoryForm({
  directory,
  setDirectory,
  visible,
  onCancel,
  onCreate,
  isEditing,
}) {
  const { siteId, screenId } = useParams();

  const [form] = Form.useForm();

  const [isDragged, setIsDragged] = useState(false);
  const { loading, tenants } = useSelector(
    (state) => ({
      loading: state.sites.loading,
      tenants: state.pois.tenant,
    }),
    shallowEqual
  );

  const [errors, setErrors] = useState({
    name: '',
    description: '',
  });

  // useEffect(() => {
  //   return () => dispatch(directoriesCleanUp());
  // }, [dispatch]);

  const onFileChangedHandler = (file) => {
    setDirectory((prevState) => ({ ...prevState, file, logo: null }));
  };

  const onChangeHandler = useChangeHandler(setDirectory);

  const onSave = (values) => {
    if (
      (!regexInput(values.name) && values.name) ||
      (!regexDescription(values.description) && values.description)
    ) {
      setErrors({
        ...errors,
        name:
          !regexInput(values.name) &&
          values.name &&
          'This field cannot contain any special characters',
        description:
          !regexDescription(values.description) &&
          values.description &&
          'This field cannot contain any special characters',
      });
    } else {
      setErrors({
        name: '',
        description: '',
      });
      onCreate({
        ...directory,
        id: directory._id,
        leftTenants: directory.leftTenants.map((tenant) => {
          const tenantData = tenants.find((t) => t.entityname === tenant);
          return {
            entityname: tenant,
            thumbnail: tenantData?.thumbnail,
            title: tenantData['jcr:title'],
          };
        }),
        rightTenants: directory.rightTenants.map((tenant) => {
          const tenantData = tenants.find((t) => t.entityname === tenant);
          return {
            entityname: tenant,
            thumbnail: tenantData?.thumbnail,
            title: tenantData['jcr:title'],
          };
        }),

        name: values.name,
        siteId,
        screenId,
        description: values.description,
        duration: values.duration,
      });
      form.resetFields();
    }
  };

  const handleDeletePreviewImage = () => {
    setDirectory((prevState) => ({
      ...prevState,
      file: null,
      logo: null,
    }));
  };

  const imagePreviewUrl = directory.file
    ? directory.file && URL.createObjectURL(directory.file)
    : directory.logo && `${imgUrl}/media/${directory.logo}`;

  return (
    <Modal
      className="adsign-modal"
      visible={visible}
      title={isEditing ? 'Edit Directory' : 'Create New Directory'}
      okText="Submit"
      confirmLoading={loading}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      // onOk={() => {}}
      footer={
        <div>
          <button
            className="button-danger-secondary mr-2"
            onClick={() => {
              onCancel();
              form.resetFields();
            }}
          >
            Cancel
          </button>
          <button
            className="button-primary"
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  onSave(values);
                })
                .catch((info) => {
                  log('Validate Failed:', info);
                });
            }}
          >
            Submit
          </button>
        </div>
      }
    >
      <Form
        className="form"
        layout="vertical"
        form={form}
        onChange={onChangeHandler}
        initialValues={{
          name: directory.name,
          duration: directory.duration || 10,
          leftTenants: directory.leftTenants,
          rightTenants: directory.rightTenants,
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true }]}
          validateStatus={errors.name ? 'error' : 'success'}
          help={errors.name ? errors.name : null}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Media"
          name="logo"
          validateStatus={errors.description ? 'error' : 'success'}
          help={errors.description ? errors.description : null}
        >
          {imagePreviewUrl ? (
            <div className="upload-image-preview">
              <Tooltip placement="top" title="Delete">
                <button
                  type="button"
                  onClick={handleDeletePreviewImage}
                  className="btn-remove"
                >
                  <i className="feather-x" />
                </button>
              </Tooltip>
              <img
                className="media-avatar"
                src={imagePreviewUrl}
                alt="User profile logo preview"
              />
            </div>
          ) : (
            <Upload
              accept="image/*"
              showUploadList={false}
              beforeUpload={onFileChangedHandler}
            >
              <div
                onDragLeave={() => setIsDragged(false)}
                onDragOver={() => setIsDragged(true)}
                className={`upload-drag-and-drop ${isDragged ? 'active' : ''}`}
              >
                <ExportIcon size={22} color="#C2C2C2" />
                <p className="upload-title">
                  Drag or <span className="upload-highlight">Browse</span>
                </p>
                <p className="upload-subtitle">
                  File format supported: jpg, png (Up to 50MB){' '}
                </p>
              </div>
            </Upload>
          )}
        </Form.Item>
        <Form.Item
          label="Duration"
          name="duration"
          rules={[{ required: true }]}
          validateStatus={errors.duration ? 'error' : 'success'}
          help={errors.duration ? errors.duration : null}
        >
          <InputNumber addonAfter="Seconds" />
        </Form.Item>

        <Form.Item
          label="Left Side Tenants"
          name="leftTenants"
          rules={[{ required: true }]}
          validateStatus={errors.name ? 'error' : 'success'}
          help={errors.leftTenants ? errors.leftTenants : null}
        >
          <Select
            mode="multiple"
            options={tenants.map((poi) => ({
              value: poi.entityname,
              label: `${poi['jcr:title']} - ${
                poi.locations &&
                poi.locations?.length > 0 &&
                poi.locations.join(', ')
              }`,
            }))}
            value={directory.tenants}
            onChange={(e) => setDirectory({ ...directory, leftTenants: e })}
          />
        </Form.Item>
        <Form.Item
          label="Right Side Tenants"
          name="rightTenants"
          rules={[{ required: true }]}
          validateStatus={errors.name ? 'error' : 'success'}
          help={errors.rightTenants ? errors.rightTenants : null}
        >
          <Select
            mode="multiple"
            options={tenants.map((poi) => ({
              value: poi.entityname,
              label: `${poi['jcr:title']} - ${
                poi.locations &&
                poi.locations?.length > 0 &&
                poi.locations.join(', ')
              }`,
            }))}
            value={directory.tenants}
            onChange={(e) => setDirectory({ ...directory, rightTenants: e })}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
