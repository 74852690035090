import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import CategoryDropdown from 'components/CategoryDropdown';
import { DatePicker, Modal } from 'antd';
import ModalScreenForm from 'components/ModalScreenForm';
import { EmptyAnalyticIcon, FilterIcon } from 'assets/icon';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ProfileAnalyzerReport from 'components/ProfileAnalyzerReport';
import {
  downloadAnalytics,
  downloadAnalyticsLeaderboard,
  fetchAnalytics,
  fetchLeaderboard,
} from 'state/actions/analytic';
import {
  createScreen,
  deleteScreen,
  fetchScreens,
  modifyScreen,
} from 'state/actions/screens';
import { log } from 'utils';
import FilterDropdown from 'components/FilterDropdown';
import { ExportIcon } from 'assets/icon/dynamic';
import LeaderboardReport from 'components/LeaderboardReport';
import './Analytic.css';

const Analytics = () => {
  const { siteId } = useParams();

  const defaultScreen = {
    name: '',
    description: '',
    modifiedAt: moment(Date.now()).format('DD-MM-YY hh:mm:ss'),
  };

  const { analytics, loading, screens, loadingScreen, updated } = useSelector(
    (state) => ({
      analytics: state.analytic.data,
      loading: state.analytic.loading,
      locations: state.analytic.locations,
      screens: state.screens.list,
      loadingScreen: state.screens.loading,
      deleted: state.screens.deleted,
      updated: state.screens.updated,
    }),
    shallowEqual
  );

  // select report type 'poi' or 'place'
  const [typeOpen, setTypeOpen] = useState(false);
  const [selectedType, setSelectedType] = useState();
  const [visible, setVisible] = useState(false);
  const [tempSelectedType, setTempSelectedType] = useState(selectedType);
  const [isEditing, setIsEditing] = useState(false);
  // select report type 'poi' or 'place'
  const [deviceOpen, setDeviceOpen] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(
    screens.map((screen) => screen.adsumScreenId)
  );
  const [tempSelectedDevice, setTempSelectedDevice] = useState([]);
  const [screen, setScreen] = useState(defaultScreen);
  const [longDateRange, setLongDateRange] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: '',
    endDate: '',
  });
  const [deleteModal, setDeleteModal] = useState({
    userId: null,
    isOpen: false,
  });

  const chartType = [
    { _id: 'place', name: 'Unit Number' },
    { _id: 'poi', name: 'POI' },
    { _id: 'category', name: 'Category' },
    { _id: 'event', name: 'Events' },
    { _id: 'promotion', name: 'Deals' },
    { _id: 'qrcode', name: 'QR Code' },
    { _id: 'leaderboard', name: 'Kiosk Performance' },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchScreens({ siteId }));
  }, [siteId]); // eslint-disable-line
  useEffect(() => {
    setSelectedDevice(screens.map((screen) => screen.adsumScreenId));
  }, [screens]); // eslint-disable-line

  useEffect(() => {
    log(updated);
    if (updated && !loadingScreen) {
      dispatch(fetchScreens({ siteId }));
      setVisible(false);
    }
  }, [updated, loadingScreen]); // eslint-disable-line
  useEffect(() => {
    console.log(selectedType, 'selectedtype');
    if (selectedType) {
      console.log('fetch analytics');
      if (selectedType === 'leaderboard') {
        console.log('leaderboard');
        dispatch(
          fetchLeaderboard({
            start: dateRange.startDate,
            end: dateRange.endDate,
            adsumScreenIds: selectedDevice,
            siteId,
          })
        );
      } else {
        dispatch(
          fetchAnalytics({
            start: dateRange.startDate,
            end: dateRange.endDate,
            type: selectedType,
            adsumScreenIds: selectedDevice,
            siteId,
          })
        );
      }
    }
  }, [selectedType, dateRange, selectedDevice]); // eslint-disable-line

  useEffect(() => {
    const ids = screens.map((screen) =>
      screen.adsumScreenId ? screen.adsumScreenId : screen._id
    );
    setTempSelectedDevice([...ids]);
  }, [screens]); // eslint-disable-line

  const handleSetType = () => {
    setSelectedType(tempSelectedType);
    setTypeOpen(false);
  };
  const handleSetDevice = () => {
    setSelectedDevice(tempSelectedDevice);
    setDeviceOpen(false);
  };

  const onCreate = (values) => {
    const action = isEditing ? modifyScreen : createScreen;
    log(values, '<<<< values screen oncreate');
    dispatch(action(values));
  };

  const onNewScreenHandler = () => {
    log('handle new screen');
    setScreen(defaultScreen);
    setIsEditing(false);
    setVisible(true);
  };

  const onRemoveButtonClickHandler = (screenId) => {
    setDeleteModal((prevState) => ({
      screenId,
      isOpen: !prevState.isOpen,
    }));
  };

  const getType = (type) => {
    const findCategory = chartType.find((cat) => cat._id === type);
    return findCategory?.name;
  };

  const handleDateChange = (range) => {
    const startDate = range && range[0].valueOf();
    const endDate = range && range[1].valueOf();

    const timeleft = endDate - startDate;
    const days = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    console.log(startDate, endDate, days, 'range date picker');
    if (days > 31) {
      setLongDateRange(true);

      setDateRange((prevState) => ({
        ...prevState,
        startDate,
        endDate,
      }));
      // toastr.error('Date range must be less than 31 days');
    } else {
      setLongDateRange(false);
      setDateRange((prevState) => ({
        ...prevState,
        startDate,
        endDate,
      }));
    }
  };

  const onDeleteScreenHandler = () => {
    dispatch(deleteScreen(deleteModal.screenId, siteId));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ screenId: null, isOpen: false });
  };

  return (
    <>
      <div className="analytic">
        <ModalScreenForm
          visible={visible}
          onCancel={() => {
            setVisible(false);
          }}
          onCreate={onCreate}
          screen={screen}
          setScreen={setScreen}
          isEditing={isEditing}
          handleDeleteCategory={(cat) => onRemoveButtonClickHandler(cat._id)}
        />
        {deleteModal.isOpen && (
          <Modal
            title="
          Are you sure want to delete these data?
          "
            visible={deleteModal.isOpen}
            onOk={onDeleteScreenHandler}
            onCancel={onCloseModalHandler}
          >
            Data cannot be restored after delete
          </Modal>
        )}
        <div className="analytic-header">
          <div className="analytic-header-left">
            <div
              className="playlist-wrapper"
              onClick={() => !loading && setTypeOpen(!typeOpen)}
            >
              <div className="playlist-selection">
                <FilterIcon />
                <span>{!selectedType ? 'Type' : getType(selectedType)}</span>
              </div>
              <i
                className={`feather-chevron-down ${
                  typeOpen ? 'playlist-open' : 'playlist-closed'
                }`}
              />

              <CategoryDropdown
                categories={chartType}
                modalOpen={typeOpen}
                handleSetPlaylist={handleSetType}
                setTempSelectedCategory={(id) => setTempSelectedType(id)}
                tempSelectedCategory={tempSelectedType}
              />
            </div>
            <div
              className="playlist-wrapper"
              onClick={() => !loading && setDeviceOpen(!deviceOpen)}
            >
              <div className="playlist-selection">
                <FilterIcon />
                <span>Show All</span>
              </div>
              <i
                className={`feather-chevron-down ${
                  deviceOpen ? 'playlist-open' : 'playlist-closed'
                }`}
              />

              <FilterDropdown
                categories={screens.filter((scr) => scr.adsumScreenId)}
                modalOpen={deviceOpen}
                title="Screen"
                filter
                handleSetPlaylist={handleSetDevice}
                isAdsum={screens.find((screen) => screen.adsumScreenId)}
                handleNew={onNewScreenHandler}
                handleEditCategory={(cat) => {
                  setScreen(cat);
                  setVisible(true);
                  setIsEditing(true);
                }}
                handleDeleteCategory={(cat) =>
                  onRemoveButtonClickHandler(cat._id)
                }
                setTempSelectedCategory={(id) => {
                  if (id === 'all') {
                    const categories = screens.filter(
                      (scr) => scr.adsumScreenId
                    );
                    if (tempSelectedDevice.length === categories.length) {
                      setTempSelectedDevice([]);
                    } else {
                      const all = categories.map(
                        (screen) => screen.adsumScreenId
                      );
                      console.log(all);
                      setTempSelectedDevice(all);
                    }
                  } else {
                    console.log(id);
                    const index = tempSelectedDevice.indexOf(id);
                    if (index === -1) {
                      setTempSelectedDevice([...tempSelectedDevice, id]);
                    } else {
                      tempSelectedDevice.splice(index, 1);
                      setTempSelectedDevice([...tempSelectedDevice]);
                    }
                  }
                }}
                tempSelectedCategory={tempSelectedDevice}
              />
            </div>

            <div>
              <DatePicker.RangePicker
                className="analytic-date-picker"
                onChange={handleDateChange}
              />
            </div>
          </div>

          {selectedType && selectedType !== 'qrcode' && (
            <div className="analytic-header-right">
              <button
                className="btn-outline-primary"
                onClick={() =>
                  dispatch(
                    downloadAnalytics({
                      start: dateRange.startDate,
                      end: dateRange.endDate,
                      adsumScreenIds: selectedDevice,
                      type: selectedType,
                      siteId,
                    })
                  )
                }
              >
                <ExportIcon color="#64D5D2" size={18} />
                {selectedType === 'leaderboard'
                  ? 'Export Summary'
                  : 'Export Raw Data'}
              </button>
            </div>
          )}
        </div>
        <div className="analytic-content-wrapper">
          <div className="analytic-content">
            {selectedType ? (
              selectedType === 'leaderboard' ? (
                <LeaderboardReport siteId={siteId} loading={loading} />
              ) : (
                <ProfileAnalyzerReport
                  siteId={siteId}
                  loading={loading}
                  analytics={analytics}
                  longDateRange={longDateRange}
                  selectedType={selectedType}
                  onExport={() =>
                    dispatch(
                      downloadAnalyticsLeaderboard({
                        start: dateRange.startDate,
                        end: dateRange.endDate,
                        adsumScreenIds: selectedDevice,
                        type: selectedType,
                        siteId,
                      })
                    )
                  }
                />
              )
            ) : (
              <div className="empty-content">
                <EmptyAnalyticIcon />
                <h1 className="empty-title">
                  Select the type of data analytics
                </h1>
                <p>
                  Please select the data type first to display the analytics
                  data to be shown
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Analytics;
