import { Tabs } from 'antd';
// import Feedbacks from 'pages/Feedbacks';
import { log } from 'utils';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setActiveTab } from 'state/actions/screens';
import MasterComponentForm from 'components/MasterComponentForm';
import RewardForm from 'components/RewardForm';
import ConciergeForm from 'components/ConciergeForm';
import TouristProgrammeForm from 'components/TouristProgrammeForm';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { fetchConfigs } from 'state/actions/configuration';
import TransportForm from 'components/TransportForm';
import HomepageForm from 'components/HomepageForm';
import { InfoIcon, TickIcon } from 'assets/icon';
import TagIconForm from 'components/TagIconForm';
import AmenitiesForm from 'components/AmenitiesForm';
import './Configuration.css';
import MobileForm from 'components/MobileForm';

const { TabPane } = Tabs;

function Configuration() {
  const dispatch = useDispatch();
  const { loading, configData, userData } = useSelector(
    (state) => ({
      loading: state.config.loading,
      configData: state.config.config,
      userData: state.auth.userData,
    }),
    shallowEqual
  );

  const { tab: activeTab } = useSelector((state) => ({
    tab: state.screens.tab,
    activeScreen: state.screens.activeScreen,
  }));

  const { siteId } = useParams();
  const [configuration, setConfiguration] = useState({ siteId });
  const [configPages, setConfigPages] = useState();

  useEffect(() => {
    dispatch(fetchConfigs({ siteId }));
  }, [siteId, dispatch]); //eslint-disable-line

  useEffect(() => {
    setConfiguration(configData);
  }, [configData]);
  useEffect(() => {
    const transportCompleteParams = [
      'carparkBackground',
      'carparkContent',
      'publictransportBackground',
      'mrt',
      'bus',
      'taxi',
    ];
    if (configData?.findmycarActive) {
      transportCompleteParams.push('findmycarBackground');
    }
    const homepageCompleteParams = [
      'directoryBackground',
      'openinghour',
      'wifi',
      'dineBackground',
      'fashionBackground',
      'floorBackground',
      'promotionBackground',
      'happeningBackground',
      'rewardBackground',
      'conciergeBackground',
      'transportBackground',
    ];
    if (configData.touristActive) {
      transportCompleteParams.push('touristBackground');
    }
    const newConf = [
      {
        key: 'master',
        title: 'Master Components',
        active: true,
        completeParams: [
          'logo',
          'background',
          'primaryColor',
          'secondaryColor',
          'tertiaryColor',
          'fb',
          'ig',
        ],
        children: (
          <MasterComponentForm
            loading={loading}
            userData={userData}
            configuration={configuration}
            setConfiguration={setConfiguration}
          />
        ),
      },
      {
        key: 'tag',
        title: 'Tag Icons',
        active: true,
        completeParams: [
          'ecapitavoucher',
          'halalcertified',
          'dbsinstantearn',
          'capitacard',
          'openingsoon',
          'newstore',
          'closed',
        ],
        children: (
          <TagIconForm
            loading={loading}
            userData={userData}
            configuration={configuration}
            setConfiguration={setConfiguration}
          />
        ),
      },
      {
        key: 'amenities',
        title: 'Amenities',
        active: true,
        completeParams: ['amenities'],
        children: (
          <AmenitiesForm
            loading={loading}
            userData={userData}
            configuration={configuration}
            setConfiguration={setConfiguration}
          />
        ),
      },
      {
        key: 'homepage',
        title: 'Homepage',
        active: true,
        completeParams: homepageCompleteParams,
        children: (
          <HomepageForm
            loading={loading}
            userData={userData}
            configuration={configuration}
            setConfiguration={setConfiguration}
          />
        ),
      },
      {
        key: 'rewards',
        title: 'Rewards',
        active: true,
        completeParams: ['rewardMedia'],
        children: (
          <RewardForm
            userData={userData}
            loading={loading}
            configuration={configuration}
            setConfiguration={setConfiguration}
          />
        ),
      },
      {
        key: 'concierge',
        title: 'Mall Info',
        active: true,
        completeParams: ['conciergeMedia', 'conciergeDescription'],
        children: (
          <ConciergeForm
            userData={userData}
            loading={loading}
            configuration={configuration}
            setConfiguration={setConfiguration}
          />
        ),
      },
      {
        key: 'tourist',
        title: 'Tourist Programme',
        active: configData.touristActive,
        completeParams: ['touristMedia', 'touristDescription'],
        children: (
          <TouristProgrammeForm
            loading={loading}
            userData={userData}
            configuration={configuration}
            setConfiguration={setConfiguration}
          />
        ),
      },
      {
        key: 'transport',
        title: 'Transport',
        completeParams: transportCompleteParams,
        active: true,
        children: (
          <TransportForm
            loading={loading}
            userData={userData}
            configuration={configuration}
            setConfiguration={setConfiguration}
          />
        ),
      },
      {
        key: 'mobile',
        title: 'Mobile',
        completeParams: transportCompleteParams,
        active: true,
        children: (
          <MobileForm
            loading={loading}
            userData={userData}
            configuration={configuration}
            setConfiguration={setConfiguration}
          />
        ),
      },
    ];
    setConfigPages(newConf);
  }, [configData, configuration]); //eslint-disable-line

  const handleChangeTab = (activeKey) => {
    log(activeKey);
    dispatch(setActiveTab({ tab: activeKey }));
  };

  const checkComplete = (array) => {
    const empty = array.filter((arr) => !configData[arr]);
    console.log(empty);
    return empty.length === 0;
  };

  return (
    <div className="configuration">
      <Tabs
        activeKey={activeTab}
        onTabClick={handleChangeTab}
        className="config-tab"
        tabPosition="left"
      >
        {configPages &&
          configPages.map(
            (page) =>
              page.active && (
                <TabPane
                  tab={
                    <div
                      onClick={(e) => e.preventDefault()}
                      className="configuration-nav"
                    >
                      <span>{page.title}</span>
                      {checkComplete(page.completeParams) ? (
                        <TickIcon />
                      ) : (
                        <InfoIcon />
                      )}
                    </div>
                  }
                  key={page.key}
                >
                  {page.children}
                </TabPane>
              )
          )}
      </Tabs>
    </div>
  );
}

export default Configuration;
