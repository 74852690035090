/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useState } from 'react';
// import InputMask from 'react-input-mask';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { imgUrl } from 'utils/url';
import { log } from 'utils';
import 'react-quill/dist/quill.snow.css';
import { homepageUpdate } from 'state/actions/configuration';
import ImageUpload from 'components/ImageUpload';
import { Input, Modal } from 'antd';
import ModalConfirmationContent from 'components/ModalConfirmationContent';
import InformationBox from 'components/InformationBox';
import { filterAccess } from 'components/Report/utils';
import './HomepageForm.css';
import ModalResetContent from 'components/ModalResetContent';

const HomepageForm = ({
  configuration,
  setConfiguration,
  loading,
  userData,
}) => {
  const { siteId } = useParams();

  const [errors, setErrors] = useState();

  const dispatch = useDispatch();

  const onFileChangedHandler = (file, params) => {
    const config = { ...configuration };
    config[params] = file;
    setConfiguration(config);
  };
  const handleDeletePreviewImage = (file, media) => {
    const config = { ...configuration };
    config[file] = null;
    config[media] = null;
    setConfiguration({ ...config });
  };
  const mediaForms = [
    {
      label: 'Media for background tile: Directory [W492 px x H656 px]',
      fileParams: 'directoryBackgroundFile',
      mediaParams: 'directoryBackground',
      errorParams: 'directory',
    },
    {
      label: 'Media for background tile: Dine [W492 px x H320 px]',
      fileParams: 'dineBackgroundFile',
      mediaParams: 'dineBackground',
      errorParams: 'dine',
    },
    {
      label: 'Media for background tile: Fashion [W492 px x H320 px]',
      fileParams: 'fashionBackgroundFile',
      mediaParams: 'fashionBackground',
      errorParams: 'fashion',
    },
    {
      label: 'Media for background tile: Floor Guide [W238 px x H320 px]',
      fileParams: 'floorBackgroundFile',
      mediaParams: 'floorBackground',
      errorParams: 'floor',
    },
    {
      label: 'Media for background tile: Promotions [W238 px x H320 px]',
      fileParams: 'promotionBackgroundFile',
      mediaParams: 'promotionBackground',
      errorParams: 'promotion',
    },
    {
      label: 'Media for background tile: Happenings [W238 px x H320 px]',
      fileParams: 'happeningBackgroundFile',
      mediaParams: 'happeningBackground',
      errorParams: 'happening',
    },
    {
      label: 'Media for background tile: Reward [W238 px x H320 px]',
      fileParams: 'rewardBackgroundFile',
      mediaParams: 'rewardBackground',
      errorParams: 'reward',
    },

    {
      label: `Media for background tile: Mall Info [${
        configuration.touristActive ? 'W323 px x H320 px' : 'W492 px x H320 px'
      }]`,
      fileParams: 'conciergeBackgroundFile',
      mediaParams: 'conciergeBackground',
      errorParams: 'concierge',
    },
    {
      label: `Media for background tile: Public Transport [${
        configuration.touristActive ? 'W323 px x H320 px' : 'W492 px x H320 px'
      }]`,
      fileParams: 'transportBackgroundFile',
      mediaParams: 'transportBackground',
      errorParams: 'transport',
    },
  ];

  if (configuration.touristActive) {
    mediaForms.push({
      label: 'Media for background tile: Tourist Programme [W323 px x H320 px]',
      fileParams: configuration.touristActive ? 'touristBackgroundFile' : null,
      mediaParams: 'touristBackground',
      errorParams: 'tourist',
    });
  }

  const onSubmitHandler = (event) => {
    event.preventDefault();
    const errorFields = mediaForms.filter(
      (form) =>
        !configuration[form.fileParams] && !configuration[form.mediaParams]
    );
    if (errorFields.length > 0) {
      const newErrors = {
        errors: {},
        message: 'Something went wrong!',
      };
      if (errorFields.length > 0) {
        for (let i = 0, l = errorFields.length; i < l; i += 1) {
          const form = errorFields[i];
          newErrors.errors[form.errorParams] = [
            {
              msg: 'This field is required',
            },
          ];
        }
      }

      setErrors(newErrors);
    } else {
      log(configuration, 'configuration');
      Modal.confirm({
        title: 'Confirmation',
        content: <ModalConfirmationContent />,
        onOk: () =>
          dispatch(
            homepageUpdate({
              ...configuration,
              siteId,
            })
          ),
      });
    }
  };

  const onReset = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: 'Confirmation',
      content: <ModalResetContent />,
      onOk: () => {
        const newConf = {
          ...configuration,
        };

        for (let i = 0, l = mediaForms.length; i < l; i += 1) {
          const form = mediaForms[i];
          newConf[form.fileParams] = null;
          newConf[form.mediaParams] = null;
        }
        setConfiguration({ ...newConf });
      },
    });
  };

  // const canSubmit = poi.name && poi.description;

  return (
    <div className="reward-form">
      <h1>Homepage Requirement</h1>
      <form className="form-wrapper">
        <div>
          <InformationBox />
          {mediaForms.map((form) => {
            const imagePreviewUrl =
              configuration &&
              (configuration[form.fileParams]
                ? configuration[form.fileParams] &&
                  URL.createObjectURL(configuration[form.fileParams])
                : configuration[form.mediaParams] &&
                  `${imgUrl}/media/${configuration[form.mediaParams]}`);

            return (
              form.fileParams && (
                <div className="form">
                  <label className="form-label">{form.label}</label>
                  <ImageUpload
                    handleDeletePreviewImage={() =>
                      handleDeletePreviewImage(
                        form.fileParams,
                        form.mediaParams
                      )
                    }
                    onFileChangedHandler={(file) =>
                      onFileChangedHandler(file, form.fileParams)
                    }
                    imagePreviewUrl={imagePreviewUrl}
                    fileParams={configuration[form.fileParams]}
                  />
                  {errors &&
                    errors.errors &&
                    errors.errors[form.errorParams] && (
                      <span className="error-message">
                        {errors.errors[form.errorParams][0].msg}
                      </span>
                    )}
                </div>
              )
            );
          })}

          <div className="form">
            <label className="form-label">Opening Hour</label>

            <Input
              placeholder="Opening Hour"
              value={configuration.openinghour}
              onChange={(e) =>
                setConfiguration({
                  ...configuration,
                  openinghour: e.target.value,
                })
              }
            />

            {errors && errors.errors && errors.errors.openinghour && (
              <span className="error-message">
                {errors.errors.openinghour[0].msg}
              </span>
            )}
          </div>
          <div className="form">
            <label className="form-label">Wifi</label>

            <Input
              placeholder="Wifi"
              value={configuration.wifi}
              onChange={(e) =>
                setConfiguration({ ...configuration, wifi: e.target.value })
              }
            />

            {errors && errors.errors && errors.errors.wifi && (
              <span className="error-message">{errors.errors.wifi[0].msg}</span>
            )}
          </div>
          <div className="form">
            <label className="form-label">Wifi Password</label>

            <Input
              placeholder="Wifi Password"
              value={configuration.wifipassword}
              onChange={(e) =>
                setConfiguration({
                  ...configuration,
                  wifipassword: e.target.value,
                })
              }
            />

            {errors && errors.errors && errors.errors.wifipassword && (
              <span className="error-message">
                {errors.errors.wifipassword[0].msg}
              </span>
            )}
          </div>

          {(filterAccess({
            userData,
            siteId,
            param: 'config',
            action: 'create',
          }) ||
            filterAccess({
              userData,
              siteId,
              param: 'config',
              action: 'update',
            })) && (
            <div className="button-wrapper">
              <div>
                {loading ? (
                  <button className="button-secondary" disabled>
                    Loading...
                  </button>
                ) : (
                  <button className="button-secondary" onClick={onReset}>
                    Reset
                  </button>
                )}
              </div>
              <div>
                {loading ? (
                  <button className="button-primary" disabled>
                    Loading...
                  </button>
                ) : (
                  <button className="button-primary" onClick={onSubmitHandler}>
                    Save & Apply
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default HomepageForm;
