/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useState } from 'react';
// import InputMask from 'react-input-mask';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { imgUrl } from 'utils/url';
import { log } from 'utils';
import 'react-quill/dist/quill.snow.css';
import { tagIconUpdate } from 'state/actions/configuration';
import ImageUpload from 'components/ImageUpload';
import { Modal } from 'antd';
import ModalConfirmationContent from 'components/ModalConfirmationContent';
import { filterAccess } from 'components/Report/utils';
import ModalResetContent from 'components/ModalResetContent';
import './TagIconForm.css';

const TagIconForm = ({
  configuration,
  setConfiguration,
  userData,
  loading,
}) => {
  const { siteId } = useParams();

  const [errors, setErrors] = useState();

  const dispatch = useDispatch();

  const onFileChangedHandler = (file, params) => {
    const config = { ...configuration };
    config[params] = file;
    setConfiguration(config);
  };
  const handleDeletePreviewImage = (file, media) => {
    const config = { ...configuration };
    config[file] = null;
    config[media] = null;
    setConfiguration({ ...config });
  };
  const mediaForms = [
    {
      label: 'Icon for eCapitaVoucher Tag',
      fileParams: 'ecapitavoucherFile',
      mediaParams: 'ecapitavoucher',
      errorParams: 'ecapitavoucher',
    },
    {
      label: 'Icon for DBS Instant Earn Tag',
      fileParams: 'dbsinstantearnFile',
      mediaParams: 'dbsinstantearn',
      errorParams: 'dbsinstantearn',
    },
    {
      label: 'Icon for Halal Certified Tag',
      fileParams: 'halalcertifiedFile',
      mediaParams: 'halalcertified',
      errorParams: 'halalcertified',
    },
    {
      label: 'Icon for CapitaCard Tag',
      fileParams: 'capitacardFile',
      mediaParams: 'capitacard',
      errorParams: 'capitacard',
    },
    {
      label: 'Icon for Opening Soon Tag',
      fileParams: 'openingsoonFile',
      mediaParams: 'openingsoon',
      errorParams: 'openingsoon',
    },
    {
      label: 'Icon for New Store Tag',
      fileParams: 'newstoreFile',
      mediaParams: 'newstore',
      errorParams: 'newstore',
    },
    {
      label: 'Icon for Temporary Closed Tag',
      fileParams: 'closedFile',
      mediaParams: 'closed',
      errorParams: 'closed',
    },
  ];

  const onSubmitHandler = (event) => {
    event.preventDefault();
    const errorFields = mediaForms.filter(
      (form) =>
        !configuration[form.fileParams] && !configuration[form.mediaParams]
    );
    if (errorFields.length > 0) {
      const newErrors = {
        errors: {},
        message: 'Something went wrong!',
      };
      if (errorFields.length > 0) {
        for (let i = 0, l = errorFields.length; i < l; i += 1) {
          const form = errorFields[i];
          newErrors.errors[form.errorParams] = [
            {
              msg: 'This field is required',
            },
          ];
        }
      }

      log(newErrors, 'errors');

      setErrors(newErrors);
    } else {
      log(configuration, 'configuration');
      Modal.confirm({
        title: 'Confirmation',
        content: <ModalConfirmationContent />,
        onOk: () =>
          dispatch(
            tagIconUpdate({
              ...configuration,
              siteId,
            })
          ),
      });
    }
  };

  const onReset = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: 'Confirmation',
      content: <ModalResetContent />,
      onOk: () => {
        const newConf = {
          ...configuration,
        };

        for (let i = 0, l = mediaForms.length; i < l; i += 1) {
          const form = mediaForms[i];
          newConf[form.fileParams] = null;
          newConf[form.mediaParams] = null;
        }
        setConfiguration({ ...newConf });
      },
    });
  };

  // const canSubmit = poi.name && poi.description;

  return (
    <div className="tagicon-form">
      <h1>Tag Icons</h1>
      <form className="form-wrapper">
        <div>
          {mediaForms.map((form) => {
            const imagePreviewUrl =
              configuration &&
              (configuration[form.fileParams]
                ? configuration[form.fileParams] &&
                  URL.createObjectURL(configuration[form.fileParams])
                : configuration[form.mediaParams] &&
                  `${imgUrl}/media/${configuration[form.mediaParams]}`);

            return (
              form.fileParams && (
                <div className="form">
                  <label className="form-label">{form.label}</label>
                  <ImageUpload
                    handleDeletePreviewImage={() =>
                      handleDeletePreviewImage(
                        form.fileParams,
                        form.mediaParams
                      )
                    }
                    onFileChangedHandler={(file) =>
                      onFileChangedHandler(file, form.fileParams)
                    }
                    imagePreviewUrl={imagePreviewUrl}
                    fileParams={configuration[form.fileParams]}
                    fileTypes="image/*"
                  />
                  {errors &&
                    errors.errors &&
                    errors.errors[form.errorParams] && (
                      <span className="error-message">
                        {errors.errors[form.errorParams][0].msg}
                      </span>
                    )}
                </div>
              )
            );
          })}

          {/* <button className="button-secondary" onClick={handleReset}>
            Reset
          </button> */}
        </div>
        {(filterAccess({
          userData,
          siteId,
          param: 'config',
          action: 'create',
        }) ||
          filterAccess({
            userData,
            siteId,
            param: 'config',
            action: 'update',
          })) && (
          <div className="button-wrapper">
            {loading ? (
              <button className="button-secondary" disabled>
                Loading...
              </button>
            ) : (
              <button className="button-secondary" onClick={onReset}>
                Reset
              </button>
            )}
            <div>
              {loading ? (
                <button className="button-primary" disabled>
                  Loading...
                </button>
              ) : (
                <button className="button-primary" onClick={onSubmitHandler}>
                  Save & Apply
                </button>
              )}
              {/* <button className="button-secondary" onClick={handleReset}>
            Reset
          </button> */}
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default TagIconForm;
