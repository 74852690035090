import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { url } from 'utils/url';
import { log, errorMsg } from 'utils';

export const CONFIGS_FETCH_DATA_INIT = createAction('CONFIGS_FETCH_DATA_INIT');
export const CONFIGS_FETCH_DATA_SUCCESS = createAction(
  'CONFIGS_FETCH_DATA_SUCCESS'
);
export const CONFIGS_FETCH_DATA_FAIL = createAction('CONFIGS_FETCH_DATA_FAIL');

export const CONFIGS_DELETE_CONFIG_INIT = createAction(
  'CONFIGS_DELETE_CONFIG_INIT'
);
export const CONFIGS_DELETE_CONFIG_SUCCESS = createAction(
  'CONFIGS_DELETE_CONFIG_SUCCESS'
);
export const CONFIGS_DELETE_CONFIG_FAIL = createAction(
  'CONFIGS_DELETE_CONFIG_FAIL'
);

export const CONFIGS_CLEAR_DATA = createAction('CONFIGS_CLEAR_DATA');

export const CONFIGS_CREATE_CONFIG_INIT = createAction(
  'CONFIGS_CREATE_CONFIG_INIT'
);
export const CONFIGS_CREATE_CONFIG_SUCCESS = createAction(
  'CONFIGS_CREATE_CONFIG_SUCCESS'
);
export const CONFIGS_CREATE_CONFIG_FAIL = createAction(
  'CONFIGS_CREATE_CONFIG_FAIL'
);

export const CONFIGS_MODIFY_CONFIG_INIT = createAction(
  'CONFIGS_MODIFY_CONFIG_INIT'
);
export const CONFIGS_MODIFY_CONFIG_SUCCESS = createAction(
  'CONFIGS_MODIFY_CONFIG_SUCCESS'
);
export const CONFIGS_MODIFY_CONFIG_FAIL = createAction(
  'CONFIGS_MODIFY_CONFIG_FAIL'
);

export const CONFIGS_CLEAN_UP = createAction('CONFIGS_CLEAN_UP');

export const CONFIGS_CLEAR_DATA_LOGOUT = createAction(
  'CONFIGS_CLEAR_DATA_LOGOUT'
);

export const SET_CONFIG_TAB = createAction('SET_CONFIG_TAB');
export const SET_ACTIVE_CONFIG = createAction('SET_ACTIVE_CONFIG');

export const fetchConfigs = ({ siteId }) => {
  return async (dispatch) => {
    dispatch(CONFIGS_FETCH_DATA_INIT());

    let config;

    try {
      const res = await axios.get(`${url}/configuration/`, {
        params: {
          siteId,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      config = res.data.data;
      log(config, 'config response');
    } catch (error) {
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(CONFIGS_FETCH_DATA_FAIL({ error }));
    }

    return dispatch(
      CONFIGS_FETCH_DATA_SUCCESS({
        config,
      })
    );
  };
};

export const deleteConfig = (id) => {
  return async (dispatch) => {
    dispatch(CONFIGS_DELETE_CONFIG_INIT());

    try {
      await axios.delete(`${url}/config/delete`, {
        params: {
          id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        CONFIGS_DELETE_CONFIG_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The config was deleted.');
    return dispatch(CONFIGS_DELETE_CONFIG_SUCCESS({ id }));
  };
};

export const masterComponentUpdate = ({
  siteId,
  primaryColor,
  secondaryColor,
  tertiaryColor,
  logoFile,
  fb,
  ig,
  backgroundFile,
  findmycarWarning,
  touristActive = false,
  findmycarActive = false,
}) => {
  return async (dispatch) => {
    dispatch(CONFIGS_CREATE_CONFIG_INIT());

    const config = new FormData();
    config.set('primaryColor', primaryColor);
    config.set('siteId', siteId);
    config.set('secondaryColor', secondaryColor);
    config.set('tertiaryColor', tertiaryColor);
    config.set('fb', fb);
    config.set('ig', ig);
    config.set(
      'activeStatus',
      JSON.stringify({ touristActive, findmycarActive })
    );
    if (findmycarActive) config.set('findmycarWarning', findmycarWarning);
    if (logoFile) config.append('file', logoFile, `logo-${logoFile.name}`);
    if (backgroundFile)
      config.append('file', backgroundFile, `bg-${backgroundFile.name}`);

    let response;
    try {
      response = await axios.post(`${url}/configuration/update`, config, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        CONFIGS_CREATE_CONFIG_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    log(response, 'response config post');
    toastr.success('', 'config created successfully');
    dispatch(CONFIGS_CREATE_CONFIG_SUCCESS({ config }));
    return dispatch(fetchConfigs({ siteId }));
  };
};
export const rewardUpdate = ({ siteId, rewardFile }) => {
  return async (dispatch) => {
    dispatch(CONFIGS_CREATE_CONFIG_INIT());

    const config = new FormData();
    config.set('siteId', siteId);

    if (rewardFile)
      config.append('file', rewardFile, `reward-${rewardFile.name}`);

    let response;
    try {
      response = await axios.post(`${url}/configuration/update`, config, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        CONFIGS_CREATE_CONFIG_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    log(response, 'response config post');
    toastr.success('', 'config created successfully');
    dispatch(CONFIGS_CREATE_CONFIG_SUCCESS({ config }));
    return dispatch(fetchConfigs({ siteId }));
  };
};
export const homepageUpdate = ({
  directoryBackgroundFile,
  dineBackgroundFile,
  fashionBackgroundFile,
  floorBackgroundFile,
  promotionBackgroundFile,
  happeningBackgroundFile,
  rewardBackgroundFile,
  touristBackgroundFile,
  conciergeBackgroundFile,
  transportBackgroundFile,
  openinghour,
  wifi,
  wifipassword,
  siteId,
}) => {
  return async (dispatch) => {
    dispatch(CONFIGS_CREATE_CONFIG_INIT());

    const config = new FormData();
    config.set('siteId', siteId);
    config.set('type', 'homepage');
    if (openinghour) config.set('openinghour', openinghour);
    if (wifi) config.set('wifi', wifi);
    if (wifipassword) config.set('wifipassword', wifipassword);

    if (directoryBackgroundFile)
      config.append(
        'file',
        directoryBackgroundFile,
        `directorybg-${directoryBackgroundFile.name}`
      );
    if (dineBackgroundFile)
      config.append(
        'file',
        dineBackgroundFile,
        `dinebg-${dineBackgroundFile.name}`
      );
    if (fashionBackgroundFile)
      config.append(
        'file',
        fashionBackgroundFile,
        `fashionbg-${fashionBackgroundFile.name}`
      );
    if (floorBackgroundFile)
      config.append(
        'file',
        floorBackgroundFile,
        `floorbg-${floorBackgroundFile.name}`
      );
    if (promotionBackgroundFile)
      config.append(
        'file',
        promotionBackgroundFile,
        `promotionbg-${promotionBackgroundFile.name}`
      );
    if (happeningBackgroundFile)
      config.append(
        'file',
        happeningBackgroundFile,
        `happeningbg-${happeningBackgroundFile.name}`
      );
    if (rewardBackgroundFile)
      config.append(
        'file',
        rewardBackgroundFile,
        `rewardbg-${rewardBackgroundFile.name}`
      );
    if (touristBackgroundFile)
      config.append(
        'file',
        touristBackgroundFile,
        `touristbg-${touristBackgroundFile.name}`
      );
    if (conciergeBackgroundFile)
      config.append(
        'file',
        conciergeBackgroundFile,
        `conciergebg-${conciergeBackgroundFile.name}`
      );
    if (transportBackgroundFile)
      config.append(
        'file',
        transportBackgroundFile,
        `transportbg-${transportBackgroundFile.name}`
      );

    let response;
    try {
      response = await axios.post(`${url}/configuration/update`, config, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        CONFIGS_CREATE_CONFIG_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    log(response, 'response config post');
    toastr.success('', 'config created successfully');
    dispatch(CONFIGS_CREATE_CONFIG_SUCCESS({ config }));
    return dispatch(fetchConfigs({ siteId }));
  };
};
export const mobileUpdate = ({
  backgroundMobileFile,
  directoryBackgroundMobileFile,
  dineBackgroundMobileFile,
  fashionBackgroundMobileFile,
  floorBackgroundMobileFile,
  promotionBackgroundMobileFile,
  happeningBackgroundMobileFile,
  rewardBackgroundMobileFile,
  touristBackgroundMobileFile,
  conciergeBackgroundMobileFile,
  transportBackgroundMobileFile,
  conciergeMediaMobileFile,
  touristMediaMobileFile,
  rewardMediaMobileFile,
  carparkBackgroundMobileFile,
  carparkContentMobileFile,
  publictransportBackgroundMobileFile,
  isDisabledMobile,
  maintenanceDescription,
  siteId,
}) => {
  return async (dispatch) => {
    dispatch(CONFIGS_CREATE_CONFIG_INIT());

    const config = new FormData();
    config.set('siteId', siteId);
    config.set('maintenanceDescription', maintenanceDescription);
    if (isDisabledMobile !== undefined) {
      config.set('isDisabledMobile', isDisabledMobile);
    }

    if (backgroundMobileFile)
      config.append(
        'file',
        backgroundMobileFile,
        `backgroundMobile-${backgroundMobileFile.name}`
      );
    if (directoryBackgroundMobileFile)
      config.append(
        'file',
        directoryBackgroundMobileFile,
        `directoryBackgroundMobile-${directoryBackgroundMobileFile.name}`
      );
    if (dineBackgroundMobileFile)
      config.append(
        'file',
        dineBackgroundMobileFile,
        `dineBackgroundMobile-${dineBackgroundMobileFile.name}`
      );
    if (fashionBackgroundMobileFile)
      config.append(
        'file',
        fashionBackgroundMobileFile,
        `fashionBackgroundMobile-${fashionBackgroundMobileFile.name}`
      );
    if (floorBackgroundMobileFile)
      config.append(
        'file',
        floorBackgroundMobileFile,
        `floorBackgroundMobile-${floorBackgroundMobileFile.name}`
      );
    if (promotionBackgroundMobileFile)
      config.append(
        'file',
        promotionBackgroundMobileFile,
        `promotionBackgroundMobile-${promotionBackgroundMobileFile.name}`
      );
    if (happeningBackgroundMobileFile)
      config.append(
        'file',
        happeningBackgroundMobileFile,
        `happeningBackgroundMobile-${happeningBackgroundMobileFile.name}`
      );
    if (rewardBackgroundMobileFile)
      config.append(
        'file',
        rewardBackgroundMobileFile,
        `rewardBackgroundMobile-${rewardBackgroundMobileFile.name}`
      );
    if (touristBackgroundMobileFile)
      config.append(
        'file',
        touristBackgroundMobileFile,
        `touristBackgroundMobile-${touristBackgroundMobileFile.name}`
      );
    if (conciergeBackgroundMobileFile)
      config.append(
        'file',
        conciergeBackgroundMobileFile,
        `conciergeBackgroundMobile-${conciergeBackgroundMobileFile.name}`
      );
    if (transportBackgroundMobileFile)
      config.append(
        'file',
        transportBackgroundMobileFile,
        `transportBackgroundMobile-${transportBackgroundMobileFile.name}`
      );
    if (conciergeMediaMobileFile)
      config.append(
        'file',
        conciergeMediaMobileFile,
        `conciergeMediaMobile-${conciergeMediaMobileFile.name}`
      );
    if (touristMediaMobileFile)
      config.append(
        'file',
        touristMediaMobileFile,
        `touristMediaMobile-${touristMediaMobileFile.name}`
      );
    if (rewardMediaMobileFile)
      config.append(
        'file',
        rewardMediaMobileFile,
        `rewardMediaMobile-${rewardMediaMobileFile.name}`
      );
    if (carparkBackgroundMobileFile)
      config.append(
        'file',
        carparkBackgroundMobileFile,
        `carparkBackgroundMobile-${carparkBackgroundMobileFile.name}`
      );
    if (carparkContentMobileFile)
      config.append(
        'file',
        carparkContentMobileFile,
        `carparkContentMobile-${carparkContentMobileFile.name}`
      );
    if (publictransportBackgroundMobileFile)
      config.append(
        'file',
        publictransportBackgroundMobileFile,
        `publictransportBackgroundMobile-${publictransportBackgroundMobileFile.name}`
      );

    let response;
    try {
      response = await axios.post(
        `${url}/configuration/update-mobile`,
        config,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        CONFIGS_CREATE_CONFIG_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    log(response, 'response config post');
    toastr.success('', 'config created successfully');
    dispatch(CONFIGS_CREATE_CONFIG_SUCCESS({ config }));
    return dispatch(fetchConfigs({ siteId }));
  };
};
export const tagIconUpdate = ({
  ecapitavoucherFile,
  halalcertifiedFile,
  dbsinstantearnFile,
  capitacardFile,
  openingsoonFile,
  newstoreFile,
  closedFile,
  siteId,
}) => {
  return async (dispatch) => {
    dispatch(CONFIGS_CREATE_CONFIG_INIT());

    const config = new FormData();
    config.set('siteId', siteId);

    if (ecapitavoucherFile)
      config.append(
        'file',
        ecapitavoucherFile,
        `ecapitavoucher-${ecapitavoucherFile.name}`
      );
    if (halalcertifiedFile)
      config.append(
        'file',
        halalcertifiedFile,
        `halalcertified-${halalcertifiedFile.name}`
      );
    if (dbsinstantearnFile)
      config.append(
        'file',
        dbsinstantearnFile,
        `dbsinstantearn-${dbsinstantearnFile.name}`
      );
    if (capitacardFile)
      config.append(
        'file',
        capitacardFile,
        `capitacard-${capitacardFile.name}`
      );
    if (openingsoonFile)
      config.append(
        'file',
        openingsoonFile,
        `openingsoon-${openingsoonFile.name}`
      );
    if (newstoreFile)
      config.append('file', newstoreFile, `newstore-${newstoreFile.name}`);
    if (closedFile)
      config.append('file', closedFile, `closed-${closedFile.name}`);

    let response;
    try {
      response = await axios.post(`${url}/configuration/update`, config, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        CONFIGS_CREATE_CONFIG_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    log(response, 'response config post');
    toastr.success('', 'config created successfully');
    dispatch(CONFIGS_CREATE_CONFIG_SUCCESS({ config }));
    return dispatch(fetchConfigs({ siteId }));
  };
};
export const conciergeUpdate = ({
  siteId,
  conciergeFile,
  conciergeDescription,
}) => {
  return async (dispatch) => {
    dispatch(CONFIGS_CREATE_CONFIG_INIT());

    const config = new FormData();
    config.set('siteId', siteId);
    config.set('conciergeDescription', conciergeDescription);

    if (conciergeFile)
      config.append('file', conciergeFile, `concierge-${conciergeFile.name}`);

    let response;
    try {
      response = await axios.post(`${url}/configuration/update`, config, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        CONFIGS_CREATE_CONFIG_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    log(response, 'response config post');
    toastr.success('', 'config created successfully');
    dispatch(CONFIGS_CREATE_CONFIG_SUCCESS({ config }));
    return dispatch(fetchConfigs({ siteId }));
  };
};
export const touristUpdate = ({ siteId, touristFile, touristDescription }) => {
  return async (dispatch) => {
    dispatch(CONFIGS_CREATE_CONFIG_INIT());

    const config = new FormData();
    config.set('siteId', siteId);
    config.set('touristDescription', touristDescription);

    if (touristFile)
      config.append('file', touristFile, `tourist-${touristFile.name}`);

    let response;
    try {
      response = await axios.post(`${url}/configuration/update`, config, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        CONFIGS_CREATE_CONFIG_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    log(response, 'response config post');
    toastr.success('', 'config created successfully');
    dispatch(CONFIGS_CREATE_CONFIG_SUCCESS({ config }));
    return dispatch(fetchConfigs({ siteId }));
  };
};
export const transportUpdate = ({
  siteId,
  carparkBackgroundFile,
  carparkQrcodeFile,
  carparkQrcode,
  carparkDescription,
  carparkContentFile,
  transportBackgroundFile,
  findmycarBackgroundFile,
  mrtMedia,
  taxiMedia,
  busMedia,
  mrtFile,
  taxiFile,
  busFile,
  mrt,
  bus,
  taxi,
}) => {
  return async (dispatch) => {
    dispatch(CONFIGS_CREATE_CONFIG_INIT());

    log(taxiMedia, 'mrt');

    const config = new FormData();
    config.set('siteId', siteId);
    config.set('bus', JSON.stringify(bus));
    config.set('taxi', JSON.stringify(taxi));
    config.set('mrt', JSON.stringify(mrt));
    if (mrtMedia) config.set('mrtMedia', mrtMedia);
    if (busMedia) config.set('busMedia', busMedia);
    if (taxiMedia) config.set('mrtMedia', mrtMedia);
    config.set('type', 'transport');
    if (carparkQrcode) config.set('carparkQrcode', carparkQrcode);
    if (carparkDescription)
      config.set('carparkDescription', carparkDescription);
    if (carparkQrcodeFile)
      config.append(
        'file',
        carparkQrcodeFile,
        `carparkqr-${carparkQrcodeFile.name}`
      );
    if (carparkBackgroundFile)
      config.append(
        'file',
        carparkBackgroundFile,
        `carparkbg-${carparkBackgroundFile.name}`
      );
    if (carparkContentFile)
      config.append(
        'file',
        carparkContentFile,
        `carpark-${carparkContentFile.name}`
      );
    if (transportBackgroundFile)
      config.append(
        'file',
        transportBackgroundFile,
        `publictransportbg-${transportBackgroundFile.name}`
      );
    if (findmycarBackgroundFile)
      config.append(
        'file',
        findmycarBackgroundFile,
        `findmycarbg-${findmycarBackgroundFile.name}`
      );
    if (mrtFile) config.append('file', mrtFile, `mrt-${mrtFile.name}`);
    if (busFile) config.append('file', busFile, `bus-${busFile.name}`);
    if (taxiFile) config.append('file', taxiFile, `taxi-${taxiFile.name}`);
    for (let ind = 0, l = bus.length; ind < l; ind += 1) {
      if (bus[ind].logoFile)
        config.append(
          'file',
          bus[ind].logoFile,
          `bus_${ind}-${bus[ind].logoFile.name}`
        );
    }
    for (let ind = 0, l = mrt.length; ind < l; ind += 1) {
      if (mrt[ind].logoFile)
        config.append(
          'file',
          mrt[ind].logoFile,
          `mrt_${ind}-${mrt[ind].logoFile.name}`
        );
    }
    for (let ind = 0, l = taxi.length; ind < l; ind += 1) {
      if (taxi[ind].logoFile)
        config.append(
          'file',
          taxi[ind].logoFile,
          `taxi_${ind}-${taxi[ind].logoFile.name}`
        );
    }

    let response;
    try {
      response = await axios.post(`${url}/configuration/update`, config, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        CONFIGS_CREATE_CONFIG_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    log(response, 'response config post');
    toastr.success('', 'config created successfully');
    dispatch(CONFIGS_CREATE_CONFIG_SUCCESS({ config }));
    return dispatch(fetchConfigs({ siteId }));
  };
};

// const toBase64 = (file) =>
//   new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = reject;
//   });
export const amenitiesUpdate = ({ siteId, amenities }) => {
  return async (dispatch) => {
    dispatch(CONFIGS_CREATE_CONFIG_INIT());

    const config = new FormData();
    config.set('siteId', siteId);

    for (let ind = 0, l = amenities.length; ind < l; ind += 1) {
      if (amenities[ind].activeFile) {
        config.append(
          'file',
          amenities[ind].activeFile,
          `amenities_${ind}_active-${amenities[ind].activeFile.name}`
        );
      }

      if (amenities[ind].inactiveFile) {
        config.append(
          'file',
          amenities[ind].inactiveFile,
          `amenities_${ind}_inactive-${amenities[ind].inactiveFile.name}`
        );
      }
    }
    // console.log(amenities, 'amenities');
    config.set('amenities', JSON.stringify(amenities));

    let response;
    try {
      response = await axios.post(`${url}/configuration/update`, config, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        CONFIGS_CREATE_CONFIG_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    log(response, 'response config post');
    toastr.success('', 'config created successfully');
    dispatch(CONFIGS_CREATE_CONFIG_SUCCESS({ config }));
    return dispatch(fetchConfigs({ siteId }));
  };
};
export const updateConfig = ({
  name,
  description,
  siteId,
  siteName,
  modifiedAt,
  modifiedBy,
}) => {
  return async (dispatch) => {
    dispatch(CONFIGS_CREATE_CONFIG_INIT());

    const config = {
      name,
      description,
      siteId,
      siteName,
      modifiedAt,
      modifiedBy,
    };

    try {
      await axios.post(`${url}/config/create`, config, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        CONFIGS_CREATE_CONFIG_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'config created successfully');
    return dispatch(CONFIGS_CREATE_CONFIG_SUCCESS({ config }));
  };
};

export const modifyConfig = ({ name, description, siteId, siteName, id }) => {
  return async (dispatch) => {
    dispatch(CONFIGS_MODIFY_CONFIG_INIT());

    const configData = {
      name,
      description,
      siteId,
      siteName,
    };

    try {
      await axios.post(`${url}/config/${id}`, configData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        CONFIGS_MODIFY_CONFIG_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'Config updated successfully');

    return dispatch(CONFIGS_MODIFY_CONFIG_SUCCESS({ config: configData, id }));
  };
};

export const configsCleanUp = () => (dispatch) => dispatch(CONFIGS_CLEAN_UP());

// added by me
export const setActiveTab = (tab) => {
  return (dispatch) => {
    dispatch(SET_CONFIG_TAB(tab));
  };
};

export const setActiveConfig = (config, cb) => {
  return (dispatch) => {
    dispatch(SET_ACTIVE_CONFIG(config));
    cb();
  };
};
