/* eslint-disable jsx-a11y/label-has-associated-control */
import { Modal, Tooltip, Upload } from 'antd';
import React, { useState } from 'react';
// import InputMask from 'react-input-mask';
import {  useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import ReactQuill from 'react-quill';
import { ExportIcon } from 'assets/icon/dynamic';
import { imgUrl } from 'utils/url';
import { log } from 'utils';
import "react-quill/dist/quill.snow.css";
import { touristUpdate } from 'state/actions/configuration';
import ModalConfirmationContent from 'components/ModalConfirmationContent';
import ImageUploadPreview from 'components/ImageUploadPreview';
import './TouristProgrammeForm.css';


const TouristProgrammeForm = ({
  configuration,
  setConfiguration,
  loading
}) => {
  const { siteId,  } = useParams();

  const [isDragged, setIsDragged] = useState(false);
  const [errors, setErrors] = useState();


  const dispatch = useDispatch();


  const onFileChangedHandler = (file) => {
    setConfiguration((prevState) => ({ ...prevState, touristFile:file }));
  };

  const handleDeletePreviewImage = () => {
    setConfiguration((prevState) => ({
      ...prevState,
      touristFile: null,
      touristMedia: null,
    }));
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (
      !configuration.touristFile && !configuration.touristMedia
    ) {
      const newErrors = {
        errors: {},
        message: 'Something went wrong!',
      };

      newErrors.errors.file = !configuration.touristFile && !configuration.touristMedia&& [
          {
            msg: 'This field is required',
          },
        ];

      setErrors(newErrors);
    } else {
      log(configuration, 'poi');
      Modal.confirm({
        title:'Confirmation',
        content: <ModalConfirmationContent/>,
        onOk: ()=>
        dispatch(
          touristUpdate({
           ...configuration,
            siteId,
            
          })
        )
        
        
      });
    }
  };

  const onReset = (e) =>{
    e.preventDefault();
    const newConf = {
      ...configuration,
      primaryColor:'#FFFFFF',
      secondaryColor:'#FFFFFF',
      touristFile:null,
      touristMedia: null,
      touristDescrition: null,
      
    };
    setConfiguration({...newConf});
  };

  // const canSubmit = poi.name && poi.description;

  const imagePreviewUrl = configuration?.touristFile
  ? configuration?.touristFile && URL.createObjectURL(configuration?.touristFile)
  : configuration?.touristMedia && `${imgUrl}/media/${configuration?.touristMedia}`;

  return (
    <div className="tourist-form">
      <h1>Tourist Programme</h1>
      <form className="form-wrapper">
        <div>
          <div className="form">
            <label className="form-label">Upload Image</label>
            {imagePreviewUrl ? (
              <div className="upload-image-preview">
                <Tooltip placement="top" title="Delete">
                  <button
                    type="button"
                    onClick={handleDeletePreviewImage}
                    className="btn-remove"
                  >
                    <i className="feather-x" />
                  </button>
                </Tooltip>
                <ImageUploadPreview
                  imagePreviewUrl={imagePreviewUrl}
                  fileParams={configuration?.touristFile}
                />
              </div>
            ) : (
              <Upload
                accept="image/*,video/mp4"
                showUploadList={false}
                beforeUpload={onFileChangedHandler}
              >
                <div
                  onDragLeave={() => setIsDragged(false)}
                  onDragOver={() => setIsDragged(true)}
                  className={`upload-drag-and-drop ${
                    isDragged ? 'active' : ''
                  }`}
                >
                  <ExportIcon size={22} color="#C2C2C2" />
                  <p className="upload-title">
                    Drag or <span className="upload-highlight">Browse</span>
                  </p>
                  <p className="upload-subtitle">
                    File format supported: jpg, png (Up to 5MB){' '}
                  </p>
                </div>
              </Upload>
            )}
              {errors && errors.errors && errors.errors.file && (
                <span className="error-message">
                  {errors.errors.file[0].msg}
                </span>
              )}
          </div>
          
            <div className="form">
              <label className="form-label">Description</label>

              <ReactQuill
                modules={{
                  toolbar: [
                    [{ 'header': '1'}, {'header': '2'}],
                    [{size: []}],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{'list': 'ordered'}, {'list': 'bullet'}]
                  ],
                  clipboard: {
                    // toggle to add extra line breaks when pasting HTML:
                    matchVisual: false,
                  }
                }}
                formats={[
                  'header', 'font', 'size',
                  'bold', 'italic', 'underline', 'strike', 'blockquote',
                  'list', 'bullet', 'indent',
                  'link', 'image', 'video'
                ]}
                theme='snow'
                
                value={configuration?.touristDescription??''}
                onChange={(val)=> setConfiguration({...configuration, touristDescription: val})}
              />

              {errors && errors.errors && errors.errors.description && (
                <span className="error-message">
                  {errors.errors.description[0].msg}
                </span>
              )}
            </div>
          
      <div
        className="button-wrapper"
      >
        <div>
          {loading ? (
            <button className="button-secondary" disabled>
              Loading...
            </button>
          ) : (
            <button className="button-secondary" onClick={onReset}>
              Reset
            </button>
          )}
          {/* <button className="button-secondary" onClick={handleReset}>
            Reset
          </button> */}
        </div>
        <div>
          {loading ? (
            <button className="button-primary" disabled>
              Loading...
            </button>
          ) : (
            <button className="button-primary" onClick={onSubmitHandler}>
              Save & Apply
            </button>
          )}
          {/* <button className="button-secondary" onClick={handleReset}>
            Reset
          </button> */}
        </div>
      </div>
        </div>
      </form>
    </div>
  );
};

export default TouristProgrammeForm;
