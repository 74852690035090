import React from 'react';
import moment from 'moment';
import TooltipMenu from 'elements/TooltipMenu';
import {
  MaximizeIcon,
  LayerIcon,
  DetailCode,
  AdobeAEM,
  EditIcon,
  TrashIcon,
} from 'assets/icon';
import CardInfo from 'elements/card-info';
import CardTitle from 'elements/card-title';
import { capitalandUrl, imgUrl } from 'utils/url';
import { shallowEqual, useSelector } from 'react-redux';
import { isEmpty } from 'underscore';
import './TenantCards.css';
import defaultLogo from '../../assets/default-logo.png';

const TenantCards = ({
  data,
  previewAction,
  cardClickAction,
  dataAction,
  deleteAction,
  editAction,
  isClickable = false,
}) => {
  const { categories } = useSelector(
    (state) => ({
      // isAdmin: state.auth.userData.isAdmin,
      unitnumber: state.pois.unitnumber,
      categories: state.pois.category,
      role: state.auth.userData.role,
    }),
    shallowEqual
  );
  const category =
    data.marketingcategory && !isEmpty(categories)
      ? data.marketingcategory.map((unit) => {
          const keys = unit.split('/');

          let cat = categories;
          for (let i = 2; i < keys.length; i += 1) {
            cat = cat[keys[i]];
          }
          cat = cat ? cat['jcr:title'] : '';

          return cat;
        })
      : [];

  const finalCat = Array.isArray(category)
    ? category?.filter((cat) => cat)
    : category;

  return (
    <>
      <div
        className={`card-tenant-container ${data?.source ? 'adsign-card' : ''}`}
        onClick={isClickable ? cardClickAction : () => {}}
      >
        <img
          src={
            data.thumbnail
              ? `${data.source ? `${imgUrl}/` : capitalandUrl}${data.thumbnail}`
              : defaultLogo
          }
          alt="default"
          className="card-tenant-img"
          draggable={false}
        />

        <div className="card-tenant-details">
          <CardTitle title={data['jcr:title']} />

          {data.marketingcategory && (
            <CardInfo
              icon={<LayerIcon style={{ width: 16, height: 16 }} />}
              title={finalCat.join(', ')}
            />
          )}
          <CardInfo
            icon={<LayerIcon style={{ width: 16, height: 16 }} />}
            title={data.locations ? data.locations.join(',') : ''}
          />
        </div>
      </div>
      <div
        className={`card-tenant-footer ${data?.source ? 'adsign-footer' : ''}`}
      >
        <div className="card-tenant-footer-row">
          {!data?.source && <AdobeAEM />}
          <div className="card-tenant-footer-column">
            <div className="created-by">
              <p>Last modified by:</p>
              <p>{data?.source ? data?.modifiedBy : 'Adobe AEM'}</p>
            </div>
            <div className="created-by">
              <p>Last modified at:</p>
              {data?.source ? (
                <p>{`${moment(data.modifiedAt).format('DD/MM/YYYY HH:mm')}`}</p>
              ) : moment(data['cq:lastModified']).isValid() ? (
                <p>{`${moment(data['cq:lastModified']).format(
                  'DD/MM/YYYY HH:mm'
                )}`}</p>
              ) : (
                <p>{`${moment(data.firstPublished).format(
                  'DD/MM/YYYY HH:mm'
                )}`}</p>
              )}
            </div>
          </div>
        </div>
        <div
          className="card-tenant-footer-right"
          onClick={(e) => e.stopPropagation()}
        >
          <ul>
            <li>
              <TooltipMenu
                title="Json"
                clickAction={dataAction}
                icon={<DetailCode style={{ width: 18, height: 18 }} />}
              />
            </li>
            <li>
              <TooltipMenu
                title="Preview"
                clickAction={previewAction}
                icon={<MaximizeIcon style={{ width: 18, height: 18 }} />}
              />
            </li>
            {data.source && (
              <>
                <li>
                  <TooltipMenu
                    title="Edit"
                    clickAction={() => editAction(data?._id)}
                    icon={<EditIcon style={{ width: 18, height: 18 }} />}
                  />
                </li>
                <li>
                  <TooltipMenu
                    title="Delete"
                    clickAction={deleteAction}
                    icon={<TrashIcon style={{ width: 18, height: 18 }} />}
                  />
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default TenantCards;
