import { createReducer } from 'redux-act';

import {
  DEALS_FETCH_DATA_INIT,
  DEALS_FETCH_DATA_SUCCESS,
  DEALS_FETCH_DATA_FAIL,
  DEALS_DELETE_DEAL_INIT,
  DEALS_DELETE_DEAL_SUCCESS,
  DEALS_DELETE_DEAL_FAIL,
  DEALS_CLEAR_DATA,
  DEALS_CREATE_DEAL_INIT,
  DEALS_CREATE_DEAL_SUCCESS,
  DEALS_CREATE_DEAL_FAIL,
  DEALS_MODIFY_DEAL_INIT,
  DEALS_MODIFY_DEAL_SUCCESS,
  DEALS_MODIFY_DEAL_FAIL,
  DEALS_CLEAN_UP,
  DEALS_CLEAR_DATA_LOGOUT,
  DEALS_SYNC_DEAL_INIT,
  DEALS_SYNC_DEAL_SUCCESS,
  DEALS_SYNC_DEAL_FAIL,
} from 'state/actions/deal';

const initialState = {
  list: [],
  deal: {},
  loading: false,
  downloading: false,
  error: {
    errors: null,
    message: '',
  },
  success: false,
  deleted: false,
};

export const dealReducer = createReducer(
  {
    [DEALS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [DEALS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      list: payload.list || [],
      poi: payload.poi || initialState.poi,
      loading: false,
      error: {
        errors: null,
        message: '',
      },
    }),
    [DEALS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [DEALS_DELETE_DEAL_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [DEALS_DELETE_DEAL_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.filter((elem) => elem._id !== payload.id),
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      deleted: true,
    }),
    [DEALS_DELETE_DEAL_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [DEALS_CLEAR_DATA]: (state) => ({
      ...initialState,
      list: state.list,
    }),
    [DEALS_CREATE_DEAL_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [DEALS_CREATE_DEAL_SUCCESS]: (state) => ({
      ...state,
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      success: true,
    }),
    [DEALS_CREATE_DEAL_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [DEALS_MODIFY_DEAL_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [DEALS_MODIFY_DEAL_SUCCESS]: (state) => ({
      ...state,

      loading: false,
      error: {
        errors: null,
        message: '',
      },
      success: true,
    }),
    [DEALS_MODIFY_DEAL_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [DEALS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      success: false,
      deleted: false,
    }),
    [DEALS_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
    [DEALS_SYNC_DEAL_INIT]: (state) => ({
      ...state,
      downloading: true,
    }),
    [DEALS_SYNC_DEAL_SUCCESS]: (state) => ({
      ...state,
      downloading: false,
      error: {
        errors: null,
        message: '',
      },
    }),
    [DEALS_SYNC_DEAL_FAIL]: (state, payload) => ({
      ...state,
      downloading: false,
      error: payload.error,
    }),
  },
  initialState
);
