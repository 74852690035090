import React, { useEffect, useState } from 'react';
import {
  Modal,
  Empty,
  Popover,
  DatePicker,
  // Tag,
  // message,
} from 'antd';
import _ from 'underscore';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  bulkMedia,
  deleteMedia,
  fetchMedia,
  bulkDeleteMedia,
  mediaPaste,
  bulkUpdate,
  bulkApproval,
  removeMedia,
  setMediaCategoryFilter,
} from 'state/actions/media';
import { imgUrl } from 'utils/url';
import { useParams, Link } from 'react-router-dom';

import { regexInput } from 'utils/regex';
import {
  createMediaCategory,
  deleteMediaCategory,
  fetchMediaCategories,
  modifyMediaCategory,
  setMediaCategory,
} from 'state/actions/mediaCategories';
// import PlayListGrid from './PlayListGrid';
// import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import ModalMediaCategoryForm from 'components/ModalMediaCategoryForm';
import { saveAs } from 'file-saver';
import { CalendarTickIcon, ForwardIcon } from 'assets/icon';
import {
  CopyIcon,
  PasteIcon,
  StickyNoteIcon,
  TrashIcon as TrashIconDanger,
} from 'assets/icon/dynamic';
import { EmptyIcon } from 'assets/icon/bigicon';
import ModalDelete from 'components/ModalDelete';
import ModalConfirmation from 'components/ModalConfirmation';
import MFAImage from 'assets/mfa.png';
import Cards from 'design-components/Cards';
import CategoryDropdown from 'components/CategoryDropdown';
import { setTabMedia } from 'state/actions/breadcrumb';
import { filterAccess } from 'components/Report/utils';
import { fetchSyncedCategories } from 'state/actions/syncedCategories';
import ModalSyncedCategory from 'components/ModalSyncedCategory';

import './MediaLibrary.css';

// const { Panel } = Collapse;

function Media({ history }) {
  const { siteId, siteName } = useParams();
  // const defaultCategory = {
  //   name: '',
  //   description: '',
  // };

  const [search, setSearch] = useState();
  const [error, setError] = useState(null);
  // const [selectedMediaTags, setSelectedMediaTags] = useState([]);
  const [bulkDeleteModal, setBulkDeleteModal] = useState(false);

  // const [category, setCategory] = useState(defaultCategory);
  // const [isEditing, setIsEditing] = useState(false);
  // const [selectedTab, setSelectedTab] = useState('published');
  const [dragDropElement, setDragDropElement] = useState({
    dragged: '',
    enter: '',
  });
  const [playlistOpen, setPlaylistOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [isDeleteCatModalShow, setIsDeleteCatModalShow] = useState(false);
  const [deletedCategory, setDeletedCategory] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const [copied, setCopied] = useState(false);
  const [bulkUpdateModal, setBulkUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);
  const [editAccess, setEditAccess] = useState(false);
  const [deletedId, setDeletedId] = useState('');
  const [bulk, setBulk] = useState({
    start_at: null,
    end_at: null,
  });

  const {
    mediaList,
    loading,
    loadingCategory,
    updated,
    categoriesList,
    deleted,
    userData,
    category,
    selectedTab,
    selectedCategory,
    sites,
    deletedSync,
  } = useSelector(
    (state) => ({
      mediaList: state.media.list,
      sites: state.sites.list,
      categoriesList: state.mediaCategories.list,
      loading: state.media.loading,
      loadingCategory: state.mediaCategories.loading,
      updated: state.media.updated,
      deleted: state.media.deleted,
      userData: state.auth.userData,
      role: state.auth.userData.role,
      category: state.mediaCategories.mediaCategory,
      selectedTab: state.breadcrumb.media,
      selectedCategory: state.media.selectedCategory,
      deletedSync: state.syncedCategories.deleted,
    }),
    shallowEqual
  );

  const [tempSelectedCategory, setTempSelectedCategory] =
    useState(selectedCategory);
  const [isModalCategoryShow, setIsModalCategoryShow] = useState(false);
  const [visible, setVisible] = useState(false);

  const uid = userData.id;

  const [mediaPreviewModal, setMediaPreviewModal] = useState({
    isOpen: false,
  });
  useEffect(() => {
    setDeleteAccess(
      filterAccess({ userData, siteId, param: 'media', action: 'delete' })
    );
    setEditAccess(
      filterAccess({ userData, siteId, param: 'media', action: 'update' })
    );
  }, [siteId, userData]);

  useEffect(() => {
    setSelectedMedia([]);
  }, [selectedTab]);
  useEffect(() => {
    setTempSelectedCategory(selectedCategory);
  }, [selectedCategory]);

  const [openModal, setOpenModal] = useState({
    isOpen: false,
    title: '',
    description: '',
    icon: '',
    mediaId: [],
    status: 'approved',
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (deleted) dispatch(fetchSyncedCategories({ siteId }));
  }, [deletedSync]); //eslint-disable-line

  useEffect(() => {
    dispatch(
      fetchMedia({
        siteId,
        categoryId: selectedCategory,
        type: selectedTab,
      })
    );
    dispatch(fetchMediaCategories({ siteId }));
    dispatch(fetchSyncedCategories({ siteId }));
    // dispatch(setMediaCategoryFilter('all'));
    // setMedias(mediaList);
  }, [siteId]); // eslint-disable-line

  useEffect(() => {
    dispatch(
      fetchMedia({
        siteId,
        categoryId: selectedCategory,
        type: selectedTab,
      })
    );
    dispatch(fetchMediaCategories({ siteId }));
    // setMedias(mediaList);
  }, [selectedTab]); // eslint-disable-line
  useEffect(() => {
    dispatch(
      fetchMedia({
        siteId,
        categoryId: selectedCategory,
        type: selectedTab,
      })
    );
    dispatch(fetchMediaCategories({ siteId }));
    // setMedias(mediaList);
  }, [selectedCategory]); // eslint-disable-line

  useEffect(() => {
    if (!loading && deleted) {
      dispatch(
        fetchMedia({
          siteId,
          categoryId: selectedCategory,
          type: selectedTab,
        })
      );
    }
  }, [loading, deleted]); // eslint-disable-line

  useEffect(() => {
    if (!loading && updated) {
      dispatch(
        fetchMedia({
          siteId,
          categoryId: selectedCategory,
          type: selectedTab,
        })
      );
    }
  }, [loading, updated]); // eslint-disable-line

  const onRemoveButtonClickHandler = () => {
    if (selectedTab === 'archived') {
      dispatch(
        deleteMedia({ id: deletedId, siteId }, () => {
          setDeleteModal(false);
          setDeletedId('');
        })
      );
    } else {
      dispatch(
        removeMedia({ id: deletedId, siteId }, () => {
          setDeleteModal(false);
          setDeletedId('');
        })
      );
    }
  };

  const onCloseModalHandler = () => {
    setOpenModal((prevState) => ({
      ...prevState,
      mediaId: [],
      isOpen: false,
    }));
  };

  const onDeleteMedia = (mediaId) => {
    setDeleteModal(true);
    setDeletedId(mediaId);
  };

  const data = search
    ? mediaList.filter((el) => {
        const clonedElem = { ...el };
        return clonedElem.name.toLowerCase().includes(search.toLowerCase());
      })
    : mediaList;

  const handleSearch = (e) => {
    setError(null);
    if (!regexInput(e.target.value) && e.target.value) {
      setError('Search cannot using special character');
    }

    setSearch(e.target.value);
  };

  const hidePreviewModal = () => {
    setMediaPreviewModal((prevState) => ({
      ...prevState,
      isOpen: !prevState.isOpen,
    }));
  };

  const onPreviewModalClickHandler = (media) => {
    setMediaPreviewModal((prevState) => ({
      ...prevState,
      isOpen: true,
      media,
    }));
  };

  const onBulkDeleteMediaHandler = () => {
    dispatch(
      bulkDeleteMedia(
        {
          siteId,
          data: selectedMedia.filter((media) => media.isChecked),
        },
        () => {
          setBulkDeleteModal(false);
          setSelectedMedia([]);
        }
      )
    );
    // setBulkDeleteModal(false);
  };

  const onCloseDeleteModalHandler = () => {
    setBulkDeleteModal(false);
  };

  const handleAddCategory = () => {
    dispatch(
      setMediaCategory({
        name: '',
        description: '',
      })
    );

    setIsModalCategoryShow(true);
  };

  const onCreate = () => {
    const action = category._id ? modifyMediaCategory : createMediaCategory;

    dispatch(
      action(
        { uid, ...category, siteId, id: category._id },
        setIsModalCategoryShow
      )
    );
  };

  const downloadImage = (url, name, type) => {
    const fileType = type.split('/');
    saveAs(url, `${name}.${fileType[1]}`);
  };

  const handleEdit = (id) => {
    history.push(`/${siteId}/information/${siteName}/media/${id}`);
  };

  const handleEditCategory = (id) => {
    const findCategory = categoriesList.find((cat) => cat._id === id);

    // setIsEditing(true);
    dispatch(setMediaCategory(findCategory));
    setIsModalCategoryShow(true);
  };

  const onNewSyncedCategory = () => {
    setVisible(true);
  };

  const handleDeleteCategory = async (item) => {
    setDeletedCategory(item);
    setIsDeleteCatModalShow(true);
    // Modal.confirm({
    //   className: 'adsign-modal',
    //   closable: true,
    //   icon: false,
    //   closeIcon: <i className="feather-x" />,
    //   title: 'Delete Media',
    //   content: (
    //     <div>
    //       <img src={DeleteIcon} />
    //       <p>Delete Confirmation</p>
    //       <p>
    //         Are you sure want to delete this data? Deleted data cannot be
    //         restored, and will be destroyed permanently
    //       </p>
    //     </div>
    //   ),
    //   onOk: async () => {
    //     dispatch(deleteMediaCategory({ id, siteId }));
    //     setMediaCategoryFilter('all');
    //   },
    // });
  };

  const handleCancelDeleteCategory = () => {
    setDeletedCategory(null);
    setIsDeleteCatModalShow(false);
  };

  const handleOkDeleteCategory = () => {
    dispatch(
      deleteMediaCategory({ id: deletedCategory._id, siteId }, () =>
        setIsDeleteCatModalShow(false)
      )
    );
    dispatch(setMediaCategoryFilter('all'));
  };

  const handleSetPlaylist = () => {
    dispatch(setMediaCategoryFilter(tempSelectedCategory));
    setPlaylistOpen(false);
  };

  const getCategory = () => {
    const findCategory = categoriesList.find(
      (cat) => cat._id === selectedCategory
    );
    return findCategory?.name;
  };

  const getMedia = () => {
    // const findMedia = mediaList.find((cat) => cat._id === mediaId);
    // return mediaId ? findMedia?.name : '';
    return '';
  };

  const handleSelectMedia = (data) => {
    // if (!copied) {
    const newMedia = [...selectedMedia];
    const findMedia = newMedia.find((media) => media._id === data._id);
    if (findMedia) {
      findMedia.isChecked = !findMedia.isChecked;
      setSelectedMedia(newMedia);
    } else {
      setSelectedMedia([
        ...selectedMedia,
        {
          ...data,
          isChecked: true,
        },
      ]);
    }
    // }
  };

  const findSelectedMedia = (id) => {
    const findIt = selectedMedia.find((media) => media._id === id);
    return findIt ? findIt.isChecked : false;
  };

  const handleCheckAll = () => {
    const checkToggle =
      selectedMedia.filter((media) => media.isChecked).length ===
      mediaList.length;
    if (checkToggle) {
      const newSelectedMedia = selectedMedia.map((media) => {
        return {
          ...media,
          isChecked: false,
        };
      });
      setSelectedMedia(newSelectedMedia);
    } else {
      const newSelectedMedia = mediaList.map((media) => {
        return {
          ...media,
          isChecked: true,
        };
      });
      setSelectedMedia(newSelectedMedia);
    }
  };

  const handleCopy = () => {
    setIsCopied(true);
    setCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };
  const handleReset = () => {
    setCopied(false);
    setSelectedMedia([]);
    dispatch(
      fetchMedia({
        siteId,
        categoryId: selectedCategory,
        type: selectedTab,
      })
    );
  };
  const handlePaste = () => {
    const selectedData = selectedMedia.filter((media) => media.isChecked);

    const checkCategory = categoriesList.find(
      (cat) => cat._id === selectedCategory
    );
    if (checkCategory) {
      dispatch(
        mediaPaste(
          {
            directoryList: _.pluck(selectedData, 'directoryId'),
            siteId,
            categoryId: checkCategory._id,
            categoryName: checkCategory.name,
          },
          () => handleReset()
        )
      );
    }
  };

  const handleBulkDelete = () => {
    setBulkDeleteModal(true);
  };

  const handleUpdateBulk = () => {
    dispatch(
      bulkUpdate(
        {
          start: bulk.start_at,
          end: bulk.end_at,
          ids: _.pluck(
            selectedMedia.filter((media) => media.isChecked),
            '_id'
          ),
          siteId,
        },
        () => {
          setBulkUpdateModal(false);
          handleReset();
          setBulk({
            start_at: null,
            end_at: null,
          });
        }
      )
    );
  };

  const handleBulkApprove = () => {
    setOpenModal({
      isOpen: true,
      header: 'Media Approval',
      title: 'Approve media',
      description: 'Do you want to approve these media?',
      mediaId: _.pluck(
        selectedMedia.filter((media) => media.isChecked),
        '_id'
      ),
      status: 'approved',
      icon: MFAImage,
    });
  };

  const handleBulkDecline = () => {
    setOpenModal({
      isOpen: true,
      header: 'Media Approval',
      title: 'Decline media',
      description: 'Do you want to decline these media?',
      mediaId: _.pluck(
        selectedMedia.filter((media) => media.isChecked),
        '_id'
      ),
      status: 'declined',
      icon: MFAImage,
    });
  };

  const handleChangeDate = (value) => {
    setBulk({
      start_at: value[0],
      end_at: value[1],
    });
  };

  const setCategory = (data) => {
    dispatch(setMediaCategory(data));
  };

  const onTabClicked = (value) => {
    dispatch(setTabMedia(value));
  };

  return (
    <div className="media-library">
      <ModalMediaCategoryForm
        visible={isModalCategoryShow}
        onCancel={() => {
          setIsModalCategoryShow(false);
        }}
        onCreate={onCreate}
        category={category}
        setCategory={setCategory}
        isEditing={!!category._id}
      />

      <ModalSyncedCategory
        visible={visible}
        categories={categoriesList}
        onCancel={() => setVisible(false)}
      />

      <Modal
        visible={bulkUpdateModal}
        title="Set Date"
        className="adsign-modal"
        closeIcon={<i className="feather-x" />}
        onOk={handleUpdateBulk}
        onCancel={() => setBulkUpdateModal(false)}
        okText="Submit"
        okButtonProps={{
          className: 'button-submit',
        }}
        cancelButtonProps={{
          className: 'button-cancel',
        }}
      >
        <div className="form">
          <label className="form-label">
            Start Date / End Date <span className="is-required">*</span>
          </label>
          <DatePicker.RangePicker
            style={{ width: '100%' }}
            className="datepicker-adsign"
            format="DD/MM/YYYY HH:mm"
            showTime
            value={
              bulk.start_at && bulk.end_at
                ? [moment(bulk.start_at), moment(bulk.end_at)]
                : undefined
            }
            onChange={handleChangeDate}
            // value={
            //   media.start && media.end
            //     ? [moment(media.start), moment(media.end)]
            //     : undefined
            // }
          />
        </div>
      </Modal>

      {mediaPreviewModal.isOpen && (
        <Modal
          title={null}
          visible={mediaPreviewModal.isOpen}
          footer={null}
          onCancel={hidePreviewModal}
          className="custom-modal"
        >
          {mediaPreviewModal.media.logo &&
          mediaPreviewModal.media.fileType.split('/')[0] === 'image' ? (
            <img
              src={`${imgUrl}/media/${mediaPreviewModal.media.logo}`}
              alt="post"
            />
          ) : mediaPreviewModal.media.logo &&
            mediaPreviewModal.media.fileType.split('/')[0] === 'video' ? (
            <video id="my-video" className="video-js" data-setup="{}" controls>
              <source src={`${imgUrl}/media/${mediaPreviewModal.media.logo}`} />
            </video>
          ) : null}

          <div className="custom-modal-content">
            <h1 className="custom-modal-title">
              {mediaPreviewModal.media.title
                ? mediaPreviewModal.media.title
                : 'No Title'}
            </h1>
            <div className="custom-modal-details">
              <ul>
                <li>
                  <ForwardIcon /> {mediaPreviewModal.media.categoryName}
                </li>
                <li>
                  <CalendarTickIcon />

                  {mediaPreviewModal.media.type === 'date'
                    ? `${moment(mediaPreviewModal.media.start).format(
                        'MMM, D YYYY'
                      )} - ${moment(mediaPreviewModal.media.end).format(
                        'MMM, D YYYY'
                      )}`
                    : mediaPreviewModal.media.type === 'day'
                    ? `${mediaPreviewModal.media.days[0].day} - ${
                        mediaPreviewModal.media.days[
                          mediaPreviewModal.media.days.length - 1
                        ].day
                      }`
                    : mediaPreviewModal.media.start &&
                      mediaPreviewModal.media.end
                    ? `${moment(mediaPreviewModal.media.start).format(
                        'MMM, D YYYY'
                      )} - ${moment(mediaPreviewModal.media.end).format(
                        'MMM, D YYYY'
                      )}`
                    : 'All Day'}
                </li>
              </ul>
            </div>
          </div>
        </Modal>
      )}

      <ModalDelete
        isVisible={isDeleteCatModalShow}
        onOk={handleOkDeleteCategory}
        onCancel={handleCancelDeleteCategory}
        title="Delete Playlist"
        subtitle={deletedCategory ? deletedCategory.name : ''}
        loading={loadingCategory}
      />

      <ModalDelete
        isVisible={bulkDeleteModal}
        onOk={onBulkDeleteMediaHandler}
        onCancel={onCloseDeleteModalHandler}
        title={`Delete Media (${
          selectedMedia.filter((media) => media.isChecked).length
        })`}
        subtitle={selectedMedia
          .filter((media) => media.isChecked)
          .map((media) => media.name)
          .join(', ')}
        loading={loading}
      />

      <ModalConfirmation
        title={openModal.title}
        onCloseHandler={onCloseModalHandler}
        header={openModal.header}
        onConfirmHandler={() =>
          dispatch(
            bulkApproval(
              {
                status: openModal.status,
                ids: _.pluck(
                  selectedMedia.filter((media) => media.isChecked),
                  '_id'
                ),
                siteId,
              },
              () => {
                handleReset();
                setOpenModal((prevState) => ({
                  ...prevState,
                  mediaId: [],
                  isOpen: false,
                }));
              }
            )
          )
        }
        description={openModal.description}
        visible={openModal.isOpen}
        icon={openModal.icon}
      />

      <ModalDelete
        isVisible={deleteModal}
        onOk={onRemoveButtonClickHandler}
        onCancel={() => setDeleteModal(false)}
        title="Delete Media"
        subtitle={getMedia(deletedId)}
        loading={loading}
      />

      <div className="media-header">
        <div className="media-header-left">
          <div className="searchbar-wrapper">
            <div className="searchbar">
              <i className="feather-search" />
              <input
                placeholder="Search"
                className="search-input"
                value={search}
                onChange={handleSearch}
              />
            </div>
            {error && <div className="search-error">{error}</div>}
          </div>
          <div
            className="playlist-wrapper"
            onClick={() => setPlaylistOpen(!playlistOpen)}
          >
            <div className="playlist-selection">
              <ForwardIcon />
              <span>
                {selectedCategory === 'all'
                  ? 'All Configuration'
                  : getCategory(selectedCategory)}
              </span>
            </div>
            <i
              className={`feather-chevron-down ${
                playlistOpen ? 'playlist-open' : 'playlist-closed'
              }`}
            />

            <CategoryDropdown
              categories={categoriesList}
              setTempSelectedCategory={(id) => setTempSelectedCategory(id)}
              handleEditCategory={(cat) => handleEditCategory(cat._id)}
              handleDeleteCategory={(cat) => handleDeleteCategory(cat)}
              modalOpen={playlistOpen}
              handleSetPlaylist={handleSetPlaylist}
              tempSelectedCategory={tempSelectedCategory}
              handleNew={handleAddCategory}
              filter
              title="Configuration"
            />
          </div>
        </div>
        <div className="actions__wrapper">
          {/* <Button
            type="danger"
            className="btn btn-danger media__delete"
            onClick={onDeleteButtonHandler}
          >
            Delete Media
          </Button> */}
          {selectedMedia.filter((media) => media.isChecked).length > 0 ? (
            <div className="btn-group">
              {selectedCategory !== 'all' && copied && (
                <button className="btn-outline-secondary" onClick={handlePaste}>
                  <PasteIcon size={18} color="#2C82F6" />
                  Paste (
                  {selectedMedia.filter((media) => media.isChecked).length})
                </button>
              )}

              <Popover
                visible={isCopied}
                content={
                  <p className="copied-popover">
                    <i className="feather-check" />
                    {
                      selectedMedia.filter((media) => media.isChecked).length
                    }{' '}
                    {`Media${
                      selectedMedia.filter((media) => media.isChecked).length >
                      1
                        ? 's'
                        : ''
                    } Copied!`}
                  </p>
                }
              >
                <button className="btn-outline-primary" onClick={handleCopy}>
                  <CopyIcon size={18} color="#64D5D2" />
                  Copy
                </button>
              </Popover>

              <button
                className="btn-outline-warning"
                onClick={() => setBulkUpdateModal(true)}
              >
                <StickyNoteIcon size={18} color="#FFBC00" />
                Set Date
              </button>
              {selectedTab === 'unpublished' &&
                selectedMedia.filter(
                  (selected) => selected.approval !== 'pending'
                ).length === 0 &&
                sites.find((site) => site._id === siteId)?.isReqApproval &&
                filterAccess({
                  siteId,
                  userData,
                  param: 'approval',
                  action: 'update',
                }) && (
                  <>
                    <button
                      className="btn-outline-primary"
                      onClick={handleBulkApprove}
                    >
                      {/* <TrashIconDanger size={18} color="#CB3A31" /> */}
                      Approve
                    </button>
                    <button
                      className="btn-outline-danger"
                      onClick={handleBulkDecline}
                    >
                      {/* <TrashIconDanger size={18} color="#CB3A31" /> */}
                      Decline
                    </button>
                  </>
                )}
              <button className="btn-outline-danger" onClick={handleBulkDelete}>
                <TrashIconDanger size={18} color="#CB3A31" />
                Delete Selected
              </button>
            </div>
          ) : (
            <>
              <button className="btn-primary" onClick={onNewSyncedCategory}>
                <i className="feather-plus" /> Add Synced Categories
              </button>

              {filterAccess({
                siteId,
                userData,
                param: 'media',
                action: 'create',
              }) && (
                <Link
                  to={`/${siteId}/information/${siteName}/media/new`}
                  className="btn-primary"
                >
                  <i className="feather-plus mr-1" />
                  Add Media
                </Link>
              )}
            </>
          )}
        </div>
      </div>
      <div className="media-content-wrapper">
        <div className="media-content">
          <div className="media-tab-wrapper">
            <div className="media-tab-left">
              <div
                onClick={() => onTabClicked('published')}
                className={`media-tab ${
                  selectedTab === 'published' ? 'active' : ''
                }`}
              >
                Published Items
              </div>
              <div
                onClick={() => onTabClicked('unpublished')}
                className={`media-tab ${
                  selectedTab === 'unpublished' ? 'active' : ''
                }`}
              >
                Unpublished Items
              </div>
              <div
                onClick={() => onTabClicked('archived')}
                className={`media-tab ${
                  selectedTab === 'archived' ? 'active' : ''
                }`}
              >
                Archived Items
              </div>
            </div>
            <div className="media-tab-right">
              {selectedMedia.filter((media) => media.isChecked).length > 0 && (
                <>
                  <p>
                    {selectedMedia.filter((media) => media.isChecked).length}{' '}
                    Media Selected
                  </p>
                  <div>
                    <span>Select All</span>
                    <div
                      onClick={handleCheckAll}
                      className={`checkbox ${
                        selectedMedia.filter((media) => media.isChecked)
                          .length === mediaList.length
                          ? 'checked'
                          : ''
                      }`}
                    >
                      {selectedMedia.filter((media) => media.isChecked)
                        .length === mediaList.length && (
                        <i className="feather-check" />
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="media-list-content">
            {data.length > 0 ? (
              _.chunk(_.sortBy(data, 'sequence'), 3).map((chunk, index) => {
                return (
                  <div className="media-list-chunk" key={index}>
                    {chunk.map((media) => {
                      return (
                        <div
                          key={media._id}
                          data-id={media._id}
                          className={`media-list ${
                            dragDropElement.dragged === media._id
                              ? 'dragged'
                              : ''
                          } ${
                            dragDropElement.enter === media._id ? 'entered' : ''
                          }`}
                          draggable
                          onDragStart={() =>
                            setDragDropElement({
                              ...dragDropElement,
                              dragged: media._id,
                            })
                          }
                          onDragEnd={() =>
                            setDragDropElement({
                              ...dragDropElement,
                              dragged: '',
                            })
                          }
                          onDragEnter={() => {
                            setDragDropElement({
                              ...dragDropElement,
                              enter: media._id,
                            });
                          }}
                          onDragOver={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onDrop={(e) => {
                            e.stopPropagation();
                            const findMedia = mediaList.find(
                              (media) => media._id === dragDropElement.dragged
                            );
                            const sourceSequence = findMedia.sequence;
                            const newMedia = [...mediaList];
                            const findSource = newMedia.find(
                              (x) => x._id === findMedia._id
                            );

                            if (findSource) {
                              findSource.sequence = media.sequence;
                            }

                            const findTarget = newMedia.find(
                              (x) => x._id === media._id
                            );

                            if (findTarget) {
                              findTarget.sequence = sourceSequence;
                            }
                            dispatch(
                              bulkMedia({
                                data: _.sortBy(newMedia, 'sequence'),
                                siteId,
                              })
                            );

                            setDragDropElement({
                              dragged: '',
                              enter: '',
                            });
                          }}
                          onClick={() => handleSelectMedia(media)}
                        >
                          {findSelectedMedia(media._id) && (
                            <div className="media-overlayed">
                              <div className="media-overlayed-check">
                                <i className="feather-check" />
                              </div>
                            </div>
                          )}
                          <Cards
                            data={media}
                            type="media"
                            isShowMedia
                            editAction={
                              editAccess ? () => handleEdit(media._id) : null
                            }
                            deleteAction={
                              deleteAccess
                                ? () => onDeleteMedia(media._id)
                                : null
                            }
                            previewAction={() =>
                              onPreviewModalClickHandler(media)
                            }
                            downloadAction={() =>
                              downloadImage(
                                `${imgUrl}/media/${media.logo}`,
                                media.name.split(' ').join('-'),
                                media.fileType
                              )
                            }
                            isPreview
                            isDownload
                          />
                        </div>
                      );
                    })}
                    {chunk.length < 2 && <div className="media-list-empty" />}
                    {chunk.length < 3 && <div className="media-list-empty" />}
                  </div>
                );
              })
            ) : (
              <div className="media-empty">
                <Empty image={EmptyIcon} description="No Media" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Media;
