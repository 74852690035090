/* eslint-disable react/no-array-index-key */
import { Empty } from 'antd';
import { EmptyIcon } from 'assets/icon/bigicon';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import _ from 'underscore';
import { deleteEvent, fetchEvents } from 'state/actions/event';
import EventCards from 'design-components/EventCards';
import ModalPreview from 'components/ModalPreview';
import { CircularProgress } from '@material-ui/core';
import ModalCodeBlock from 'components/ModalCodeBlock';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import ModalDelete from 'components/ModalDelete';
import { FilterIcon } from 'assets/icon';
import CategoryDropdown from 'components/CategoryDropdown';
import { setTabEvent } from 'state/actions/breadcrumb';
import { filterAccess } from 'components/Report/utils';
import './Events.css';

function Events({ history }) {
  const [search, setSearch] = useState();
  const [modalPreview, setModalPreview] = useState({ isOpen: false, data: {} });
  const [modalCode, setModalCode] = useState({ isOpen: false, data: {} });
  const [deleteModal, setDeleteModal] = useState({ isOpen: false, id: '' });
  const [typeOpen, setTypeOpen] = useState(false);
  const [selectedType, setSelectedType] = useState();
  const [tempSelectedType, setTempSelectedType] = useState(selectedType);
  const [deleteAccess, setDeleteAccess] = useState(false);
  const [editAccess, setEditAccess] = useState(false);
  const [eventData, setEventData] = useState();
  const { siteId, siteName } = useParams();

  const { eventList, loading, selectedTab, userData } = useSelector(
    (state) => ({
      eventList: state.events.list,
      loading: state.events.loading,
      downloading: state.events.downloading,

      userData: state.auth.userData,
      role: state.auth.userData.role,

      selectedTab: state.breadcrumb.event,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchEvents({ siteId, type: selectedTab }));
  }, [siteId]); // eslint-disable-line
  useEffect(() => {
    setDeleteAccess(
      filterAccess({ userData, siteId, param: 'event', action: 'delete' })
    );
    setEditAccess(
      filterAccess({ userData, siteId, param: 'event', action: 'update' })
    );
  }, [siteId, userData]);
  useEffect(() => {
    if (eventList) setEventData(eventList);
  }, [eventList]); // eslint-disable-line
  useEffect(() => {
    let filtered;
    if (selectedType === 'adsign') {
      filtered = eventList.filter((event) => event.source === 'adsign');
    }

    if (selectedType === 'aem') {
      filtered = eventList.filter((event) => !event.source);
    }
    if (selectedType === 'all') {
      filtered = eventList;
    }
    setEventData(filtered);
  }, [selectedType]); // eslint-disable-line

  // const handleFetch = () => {
  //   dispatch(syncEvent());
  // };

  const chartType = [
    { _id: 'adsign', name: 'Adsign' },
    { _id: 'aem', name: 'Adobe AEM' },
  ];

  const getType = (type) => {
    if (type === 'all') {
      return 'Show All';
    }
    const findCategory = chartType.find((cat) => cat._id === type);
    return findCategory?.name;
  };

  const handleSearch = (e) => {
    // setSearchError(null);
    // if (!regexInput(e.target.value) && e.target.value) {
    //   setSearchError('Search cannot using special character');
    // }
    setSearch(e.target.value);
  };
  const handleDeleteEvent = () => {
    dispatch(
      deleteEvent({ id: deleteModal.id, siteId }, () => {
        setDeleteModal({ isOpen: false, id: null });
      })
    );
  };

  useEffect(() => {
    dispatch(fetchEvents({ siteId, type: selectedTab }));
  }, [selectedTab]); // eslint-disable-line

  const handleSetType = () => {
    setSelectedType(tempSelectedType);
    setTypeOpen(false);
  };
  const onTabClicked = (value) => {
    dispatch(setTabEvent(value));
  };
  const filteredData = search
    ? eventData.filter((el) => {
        const clonedElem = { ...el };
        return clonedElem['jcr:title']
          .toLowerCase()
          .includes(search.toLowerCase());
      })
    : eventData;

  return (
    <div className="media-library">
      <ModalPreview
        isVisible={modalPreview.isOpen}
        data={modalPreview.data}
        title="Preview Event"
        onCancel={() => {
          setModalPreview({ isOpen: false, data: {} });
        }}
      />

      <ModalDelete
        isVisible={deleteModal.isOpen}
        onOk={handleDeleteEvent}
        onCancel={() => setDeleteModal({ isOpen: false, id: null })}
        title="Delete Event"
        loading={loading}
      />

      <ModalCodeBlock
        isVisible={modalCode.isOpen}
        data={modalCode.data}
        title="Raw Data Event"
        onCancel={() => setModalCode({ isOpen: false, data: {} })}
      />

      <div className="media-header">
        <div className="media-header-left">
          <div className="searchbar-wrapper">
            <div className="searchbar">
              <i className="feather-search" />
              <input
                placeholder="Search"
                className="search-input"
                value={search}
                onChange={handleSearch}
              />
            </div>
          </div>
          <div
            className="playlist-wrapper"
            onClick={() => !loading && setTypeOpen(!typeOpen)}
          >
            <div className="playlist-selection">
              <FilterIcon />
              <span>{!selectedType ? 'Source' : getType(selectedType)}</span>
            </div>
            <i
              className={`feather-chevron-down ${
                typeOpen ? 'playlist-open' : 'playlist-closed'
              }`}
            />

            <CategoryDropdown
              categories={chartType}
              modalOpen={typeOpen}
              handleSetPlaylist={handleSetType}
              setTempSelectedCategory={(id) => setTempSelectedType(id)}
              tempSelectedCategory={tempSelectedType}
              filter
              title="Source"
            />
          </div>
        </div>
        <div className="actions__wrapper">
          {filterAccess({
            userData,
            siteId,
            param: 'event',
            action: 'create',
          }) && (
            <Link
              to={`/${siteId}/information/${siteName}/event/new`}
              className="btn-primary"
            >
              <i className="feather-plus mr-1" />
              Add Event
            </Link>
          )}
        </div>
      </div>

      <div className="media-header" />
      <div className="media-content-wrapper events-content">
        <div className="media-content">
          <div className="media-tab-wrapper">
            <div className="media-tab-left">
              <div
                onClick={() => onTabClicked('published')}
                className={`media-tab ${
                  selectedTab === 'published' ? 'active' : ''
                }`}
              >
                Published Items
              </div>
              <div
                onClick={() => onTabClicked('unpublished')}
                className={`media-tab ${
                  selectedTab === 'unpublished' ? 'active' : ''
                }`}
              >
                Unpublished Items
              </div>
            </div>
          </div>
          <div className="media-list-content">
            {filteredData && filteredData?.length > 0 ? (
              _.chunk(filteredData, 3).map((chunk, index) => {
                return (
                  <div className="media-list-chunk" key={index}>
                    {chunk.map((poi, index) => {
                      return (
                        <div
                          key={poi?._id ?? index}
                          data-id={poi?._id ?? index}
                          className="media-list"
                        >
                          <EventCards
                            data={poi}
                            isShowMedia
                            deleteAccess={deleteAccess}
                            editAccess={editAccess}
                            dataAction={() => {
                              setModalCode({ isOpen: true, data: poi });
                            }}
                            deleteAction={(id) =>
                              setDeleteModal({ isOpen: true, id })
                            }
                            editAction={(id) =>
                              history.push(
                                `/${siteId}/information/${siteName}/event/${id}`
                              )
                            }
                            previewAction={() =>
                              setModalPreview({ isOpen: true, data: poi })
                            }
                          />
                        </div>
                      );
                    })}
                    {chunk.length < 2 && <div className="media-list-empty" />}
                    {chunk.length < 3 && <div className="media-list-empty" />}
                  </div>
                );
              })
            ) : (
              <div className="media-empty">
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Empty image={EmptyIcon} description="No Event" />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Events;
