import { Form, Modal, Select, Tooltip } from 'antd';
import InformationBox from 'components/InformationBox';
import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { useParams } from 'react-router-dom/cjs/react-router-dom';
import {
  createSyncedCategory,
  deleteSyncedCategory,
} from 'state/actions/syncedCategories';

export default function ModalSyncedCategory({ categories, visible, onCancel }) {
  const { siteId } = useParams();

  const [form] = Form.useForm();

  const [synced, setSynced] = useState({ siteId, categoryIds: [] });
  const { loading, syncedCategories } = useSelector(
    (state) => ({
      loading: state.sites.loading,
      syncedCategories: state.syncedCategories.list,
    }),
    shallowEqual
  );

  const [errors] = useState({
    name: '',
    description: '',
  });

  const dispatch = useDispatch();

  // useEffect(() => {
  //   return () => dispatch(directoriesCleanUp());
  // }, [dispatch]);

  const getCategoryName = (catId) => {
    const { name } = categories.find((cat) => cat._id === catId) || '';
    return name;
  };

  return (
    <Modal
      className="adsign-modal"
      visible={visible}
      title="Synced Categories"
      okText="Submit"
      confirmLoading={loading}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      // onOk={() => {}}
      footer={
        <div>
          <button
            className="button-danger-secondary mr-2"
            onClick={() => {
              onCancel();
              form.resetFields();
            }}
          >
            Cancel
          </button>
        </div>
      }
    >
      <Form className="form" layout="vertical" form={form}>
        <InformationBox
          title="The following requirements must be met to make sure a media(s) is playing in synced within two (or more) configurations:"
          list={[
            'Total time duration of media BEFORE playing the (synced) media(s) needs to be the same across all configurations registered inside the synced configurations',
            'Total time duration of media AFTER playing the (synced) media(s) needs to be the same across all configurations registered inside the synced configurations',
            'MUST have at least 1 image inside each configuration of all involving synced configurations',
          ]}
          nb="Failure to meet one of the requirements above would result to all media to still play normally, but the desired media(s) will NOT play on sync."
        />
        <Form.Item
          label="Synced Categories"
          name="categoryIds"
          rules={[{ required: true }]}
          validateStatus={errors.name ? 'error' : 'success'}
          help={errors.leftTenants ? errors.leftTenants : null}
        >
          <Select
            mode="multiple"
            options={categories.map((poi) => ({
              value: poi._id,
              label: `${poi.name}`,
            }))}
            value={synced.categoryIds}
            onChange={(e) => setSynced({ ...synced, categoryIds: e })}
          />
        </Form.Item>
      </Form>

      <button
        className="button-primary"
        onClick={() => {
          // console.log(synced, 'synced');
          dispatch(
            createSyncedCategory({ categoryIds: synced.categoryIds, siteId })
          );
        }}
      >
        Check
      </button>

      {syncedCategories.map((sync) => (
        <div className="synced-row">
          <div className="category-name">
            {sync.categoryIds.map((cat) => (
              <span>{getCategoryName(cat)}</span>
            ))}
          </div>
          <Tooltip placement="top" title="Delete">
            <button
              type="button"
              onClick={() =>
                dispatch(deleteSyncedCategory({ id: sync._id, siteId }))
              }
              className="btn-remove"
            >
              <i className="feather-x" />
            </button>
          </Tooltip>
        </div>
      ))}
    </Modal>
  );
}
