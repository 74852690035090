import { Empty } from 'antd';
import { EmptyIcon } from 'assets/icon/bigicon';
// import { ImportIcon } from 'assets/icon/dynamic';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// import { fetchMaps } from 'state/actions/maps';
import _ from 'underscore';
import { deleteDeal, fetchDeal } from 'state/actions/deal';
import EventCards from 'design-components/EventCards';
import ModalPreview from 'components/ModalPreview';
import { CircularProgress } from '@material-ui/core';
import ModalCodeBlock from 'components/ModalCodeBlock';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import ModalDelete from 'components/ModalDelete';

import { FilterIcon } from 'assets/icon';
import CategoryDropdown from 'components/CategoryDropdown';
import { setTabDeal } from 'state/actions/breadcrumb';
import { filterAccess } from 'components/Report/utils';
import './Deals.css';
// import { setTabTenant } from 'state/actions/breadcrumb';

// const { Option } = Select;
// const { Panel } = Collapse;

function Deals({ history }) {
  const [search, setSearch] = useState();
  const [modalPreview, setModalPreview] = useState({ isOpen: false, data: {} });
  const [modalCode, setModalCode] = useState({ isOpen: false, data: {} });
  const [deleteModal, setDeleteModal] = useState({ isOpen: false, id: '' });

  // access variable
  const [editAccess, setEditAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);

  const [typeOpen, setTypeOpen] = useState(false);
  const [selectedType, setSelectedType] = useState();
  const [tempSelectedType, setTempSelectedType] = useState(selectedType);
  const [dealData, setDealData] = useState();

  const { siteId, siteName } = useParams();

  const { dealList, loading, selectedTab, userData } = useSelector(
    (state) => ({
      dealList: state.deals.list,
      loading: state.deals.loading,
      userData: state.auth.userData,
      downloading: state.deals.downloading,
      selectedTab: state.breadcrumb.deal,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const chartType = [
    { _id: 'adsign', name: 'Adsign' },
    { _id: 'aem', name: 'Adobe AEM' },
  ];
  const getType = (type) => {
    if (type === 'all') {
      return 'Show All';
    }

    const findCategory = chartType.find((cat) => cat._id === type);
    return findCategory?.name;
  };

  useEffect(() => {
    setDeleteAccess(
      filterAccess({ userData, siteId, param: 'deal', action: 'delete' })
    );
    setEditAccess(
      filterAccess({ userData, siteId, param: 'deal', action: 'update' })
    );
  }, [siteId, userData]);
  useEffect(() => {
    dispatch(fetchDeal({ siteId, type: selectedTab }));
  }, [siteId]); // eslint-disable-line

  useEffect(() => {
    if (dealList) setDealData(dealList);
  }, [dealList]); // eslint-disable-line
  useEffect(() => {
    let filtered;
    if (selectedType === 'adsign') {
      filtered = dealList.filter((event) => event.source === 'adsign');
    }

    if (selectedType === 'aem') {
      filtered = dealList.filter((event) => !event.source);
    }
    if (selectedType === 'all') {
      filtered = dealList;
    }
    setDealData(filtered);
  }, [selectedType]); // eslint-disable-line

  useEffect(() => {
    dispatch(fetchDeal({ siteId, type: selectedTab }));
  }, [selectedTab]); // eslint-disable-line
  // const handleFetch = () => {
  //   dispatch(syncDeal());
  // };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const filteredData = search
    ? dealData.filter((el) => {
        const clonedElem = { ...el };
        return clonedElem['jcr:title']
          .toLowerCase()
          .includes(search.toLowerCase());
      })
    : dealData;

  const handleDeleteDeal = () => {
    dispatch(
      deleteDeal({ id: deleteModal.id, siteId }, () => {
        setDeleteModal({ isOpen: false, id: null });
      })
    );
  };

  const handleSetType = () => {
    setSelectedType(tempSelectedType);
    setTypeOpen(false);
  };

  const onTabClicked = (value) => {
    dispatch(setTabDeal(value));
  };

  return (
    <div className="media-library">
      <ModalPreview
        isVisible={modalPreview.isOpen}
        data={modalPreview.data}
        title="Preview Deal"
        onCancel={() => {
          setModalPreview({ isOpen: false, data: {} });
        }}
      />
      <ModalDelete
        isVisible={deleteModal.isOpen}
        onOk={handleDeleteDeal}
        onCancel={() => setDeleteModal({ isOpen: false, id: null })}
        title="Delete Deal"
        loading={loading}
      />

      <ModalCodeBlock
        isVisible={modalCode.isOpen}
        data={modalCode.data}
        title="Raw Data Deals"
        onCancel={() => {
          setModalCode({ isOpen: false, data: {} });
        }}
      />

      <div className="media-header">
        <div className="media-header-left">
          <div className="searchbar-wrapper">
            <div className="searchbar">
              <i className="feather-search" />
              <input
                placeholder="Search"
                className="search-input"
                value={search}
                onChange={handleSearch}
              />
            </div>
          </div>
          <div
            className="playlist-wrapper"
            onClick={() => !loading && setTypeOpen(!typeOpen)}
          >
            <div className="playlist-selection">
              <FilterIcon />
              <span>{!selectedType ? 'Source' : getType(selectedType)}</span>
            </div>
            <i
              className={`feather-chevron-down ${
                typeOpen ? 'playlist-open' : 'playlist-closed'
              }`}
            />

            <CategoryDropdown
              categories={chartType}
              modalOpen={typeOpen}
              handleSetPlaylist={handleSetType}
              setTempSelectedCategory={(id) => setTempSelectedType(id)}
              tempSelectedCategory={tempSelectedType}
              filter
              title="Source"
            />
          </div>
        </div>
        <div className="actions__wrapper">
          {filterAccess({
            userData,
            siteId,
            param: 'deal',
            action: 'create',
          }) && (
            <Link
              to={`/${siteId}/information/${siteName}/deal/new`}
              className="btn-primary"
            >
              <i className="feather-plus mr-1" />
              Add Deal
            </Link>
          )}
          {/* <button className="btn-outline-primary" onClick={handleFetch}>
            {downloading ? (
              <CircularProgress color="#64D5D2" size={18} />
            ) : (
              <ImportIcon color="#64D5D2" size={18} />
            )}
            Fetch Deal
          </button> */}
        </div>
      </div>

      <div className="media-header" />
      <div className="media-content-wrapper deals-content">
        <div className="media-content">
          <div className="media-tab-wrapper">
            <div className="media-tab-left">
              <div
                onClick={() => onTabClicked('published')}
                className={`media-tab ${
                  selectedTab === 'published' ? 'active' : ''
                }`}
              >
                Published Items
              </div>
              <div
                onClick={() => onTabClicked('unpublished')}
                className={`media-tab ${
                  selectedTab === 'unpublished' ? 'active' : ''
                }`}
              >
                Unpublished Items
              </div>
            </div>
          </div>
          <div className="media-list-content">
            {filteredData && filteredData?.length > 0 ? (
              _.chunk(filteredData, 3).map((chunk, index) => {
                return (
                  <div className="media-list-chunk" key={index}>
                    {chunk.map((poi) => {
                      return (
                        <div
                          key={poi._id}
                          data-id={poi._id}
                          className="media-list"
                        >
                          <EventCards
                            data={poi}
                            isShowMedia
                            editAccess={editAccess}
                            deleteAccess={deleteAccess}
                            previewAction={() =>
                              setModalPreview({ isOpen: true, data: poi })
                            }
                            dataAction={() =>
                              setModalCode({ isOpen: true, data: poi })
                            }
                            deleteAction={(id) =>
                              setDeleteModal({ isOpen: true, id })
                            }
                            editAction={(id) =>
                              history.push(
                                `/${siteId}/information/${siteName}/deal/${id}`
                              )
                            }
                          />
                        </div>
                      );
                    })}
                    {chunk.length < 2 && <div className="media-list-empty" />}
                    {chunk.length < 3 && <div className="media-list-empty" />}
                  </div>
                );
              })
            ) : (
              <div className="media-empty">
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Empty image={EmptyIcon} description="No Deal" />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Deals;
