/* eslint-disable jsx-a11y/label-has-associated-control */
import { Tooltip, Upload, ColorPicker, Checkbox, Modal, Input } from 'antd';
import React, { useState } from 'react';
// import InputMask from 'react-input-mask';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ExportIcon } from 'assets/icon/dynamic';
import { imgUrl } from 'utils/url';
import { log } from 'utils';
import { masterComponentUpdate } from 'state/actions/configuration';

import ModalConfirmationContent from 'components/ModalConfirmationContent';
import ImageUploadPreview from 'components/ImageUploadPreview';
import { filterAccess } from 'components/Report/utils';
import './MasterComponentForm.css';
import ModalResetContent from 'components/ModalResetContent';

const MasterComponentForm = ({
  configuration,
  setConfiguration,
  loading,
  userData,
}) => {
  /* eslint-disable jsx-a11y/media-has-caption */

  const { siteId } = useParams();
  const [isDragged, setIsDragged] = useState(false);
  const [errors, setErrors] = useState();

  const dispatch = useDispatch();

  // useEffect(() => {
  //   return () => dispatch(configurationsCleanUp());
  // }, [dispatch]);

  const onFileChangedHandler = (file, params) => {
    const config = { ...configuration };
    config[params] = file;
    setConfiguration(config);
  };

  const handleDeletePreviewImage = (file, media) => {
    const config = { ...configuration };
    config[file] = null;
    config[media] = null;
    setConfiguration({ ...config });
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (
      (!configuration.logo && !configuration.logoFile) ||
      (!configuration.backgroundFile && !configuration.background) ||
      (configuration.findmycarActive && !configuration.findmycarWarning) ||
      !configuration.primaryColor ||
      !configuration.secondaryColor ||
      !configuration.tertiaryColor ||
      !configuration.fb ||
      !configuration.ig
    ) {
      const newErrors = {
        errors: {},
        message: 'Something went wrong!',
      };

      newErrors.errors.logo = !configuration.logo &&
        !configuration.logoFile && [
          {
            msg: 'This field is required',
          },
        ];
      newErrors.errors.background = !configuration.backgroundFile &&
        !configuration.background && [
          {
            msg: 'This field is required',
          },
        ];
      newErrors.errors.primary = !configuration.primaryColor && [
        {
          msg: 'This field is required',
        },
      ];
      newErrors.errors.secondary = !configuration.secondaryColor && [
        {
          msg: 'This field is required',
        },
      ];
      newErrors.errors.tertiary = !configuration.tertiaryColor && [
        {
          msg: 'This field is required',
        },
      ];
      newErrors.errors.fb = !configuration.fb && [
        {
          msg: 'This field is required',
        },
      ];
      newErrors.errors.ig = !configuration.ig && [
        {
          msg: 'This field is required',
        },
      ];
      newErrors.errors.findmycarWarning = configuration.findmycarActive &&
        !configuration.findmycarWarning && [
          {
            msg: 'This field is required',
          },
        ];

      setErrors(newErrors);
    } else {
      log(configuration, 'configuration');
      Modal.confirm({
        title: 'Confirmation',
        content: <ModalConfirmationContent />,
        onOk: () =>
          dispatch(
            masterComponentUpdate({
              ...configuration,
              siteId,
            })
          ),
      });
    }
  };

  const onReset = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: 'Confirmation',
      content: <ModalResetContent />,
      onOk: () => {
        const newConf = {
          ...configuration,
          primaryColor: '#FFFFFF',
          secondaryColor: '#FFFFFF',
          logoFile: null,
          logo: null,
          backgroundFile: null,
          background: null,
          findmycarActive: false,
          touristActive: false,
        };
        setConfiguration({ ...newConf });
      },
    });
  };

  // const canSubmit = configuration.name && configuration.description;

  const logoPreviewUrl = configuration?.logoFile
    ? configuration?.logoFile && URL.createObjectURL(configuration?.logoFile)
    : configuration?.logo && `${imgUrl}/media/${configuration?.logo}`;
  const bgPreviewUrl = configuration?.backgroundFile
    ? configuration?.backgroundFile &&
      URL.createObjectURL(configuration?.backgroundFile)
    : configuration?.background &&
      `${imgUrl}/media/${configuration?.background}`;

  return (
    <div className="master-form">
      <h1>Master Component</h1>
      <form className="form-wrapper">
        <div>
          <div className="form">
            <label className="form-label">Upload Mall&apos;s Logo</label>
            <div className="form-row">
              <div className="logo-form">
                {logoPreviewUrl ? (
                  <div className="upload-image-preview">
                    <Tooltip placement="top" title="Delete">
                      <button
                        type="button"
                        onClick={() =>
                          handleDeletePreviewImage('logoFile', 'logo')
                        }
                        className="btn-remove"
                      >
                        <i className="feather-x" />
                      </button>
                    </Tooltip>
                    <img
                      className="media-avatar"
                      src={logoPreviewUrl}
                      alt="User profile logo preview"
                    />
                  </div>
                ) : (
                  <Upload
                    accept="image/*"
                    showUploadList={false}
                    beforeUpload={(file) =>
                      onFileChangedHandler(file, 'logoFile')
                    }
                  >
                    <div
                      onDragLeave={() => setIsDragged(false)}
                      onDragOver={() => setIsDragged(true)}
                      className={`upload-drag-and-drop ${
                        isDragged ? 'active' : ''
                      }`}
                    >
                      <ExportIcon size={22} color="#C2C2C2" />
                      <p className="upload-title">
                        Drag or <span className="upload-highlight">Browse</span>
                      </p>
                      {/* <p className="upload-subtitle">
                        File format supported: jpg, png
                      </p> */}
                    </div>
                  </Upload>
                )}
                {errors && errors.errors && errors.errors.logo && (
                  <span className="error-message">
                    {errors.errors.logo[0].msg}
                  </span>
                )}
              </div>
              <div className="form-info">
                {`Use PNG or JPG format with a \nratio 1:1 and max size 10mb`}
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="form">
              <label className="form-label">Primary Color</label>

              <ColorPicker
                showText
                value={configuration.primaryColor}
                onChange={(val) =>
                  setConfiguration({
                    ...configuration,
                    primaryColor: val.toHexString(),
                  })
                }
              />

              {errors && errors.errors && errors.errors.primary && (
                <span className="error-message">
                  {errors.errors.primary[0].msg}
                </span>
              )}
            </div>
            <div className="form">
              <label className="form-label">Secondary Color</label>
              <ColorPicker
                showText
                value={configuration.secondaryColor}
                onChange={(val) =>
                  setConfiguration({
                    ...configuration,
                    secondaryColor: val.toHexString(),
                  })
                }
              />

              {errors && errors.errors && errors.errors.secondary && (
                <span className="error-message">
                  {errors.errors.secondary[0].msg}
                </span>
              )}
            </div>
            <div className="form">
              <label className="form-label">Tertiary Color</label>
              <ColorPicker
                showText
                value={configuration.tertiaryColor}
                onChange={(val) =>
                  setConfiguration({
                    ...configuration,
                    tertiaryColor: val.toHexString(),
                  })
                }
              />

              {errors && errors.errors && errors.errors.tertiary && (
                <span className="error-message">
                  {errors.errors.tertiary[0].msg}
                </span>
              )}
            </div>
          </div>
          <div className="form">
            <label className="form-label">Upload Background</label>
            {bgPreviewUrl ? (
              <div className="upload-image-preview">
                <Tooltip placement="top" title="Delete">
                  <button
                    type="button"
                    onClick={() =>
                      handleDeletePreviewImage('backgroundFile', 'background')
                    }
                    className="btn-remove"
                  >
                    <i className="feather-x" />
                  </button>
                </Tooltip>
                <ImageUploadPreview
                  imagePreviewUrl={bgPreviewUrl}
                  fileParams={configuration.backgroundFile}
                />
              </div>
            ) : (
              <Upload
                accept="image/*, video/mp4"
                showUploadList={false}
                beforeUpload={(file) =>
                  onFileChangedHandler(file, 'backgroundFile')
                }
              >
                <div
                  onDragLeave={() => setIsDragged(false)}
                  onDragOver={() => setIsDragged(true)}
                  className={`upload-drag-and-drop ${
                    isDragged ? 'active' : ''
                  }`}
                >
                  <ExportIcon size={22} color="#C2C2C2" />
                  <p className="upload-title">
                    Drag or <span className="upload-highlight">Browse</span>
                  </p>
                  <p className="upload-subtitle">
                    File format supported: jpg, png (Up to 5MB){' '}
                  </p>
                </div>
              </Upload>
            )}
            {errors && errors.errors && errors.errors.background && (
              <span className="error-message">
                {errors.errors.background[0].msg}
              </span>
            )}
          </div>
          <div className="form">
            <label className="form-label">Instagram Account</label>

            <Input
              placeholder="Instagram"
              value={configuration.ig}
              onChange={(e) =>
                setConfiguration({ ...configuration, ig: e.target.value })
              }
            />

            {errors && errors.errors && errors.errors.ig && (
              <span className="error-message">{errors.errors.ig[0].msg}</span>
            )}
          </div>
          <div className="form">
            <label className="form-label">Facebook Profile</label>

            <Input
              placeholder="Facebook"
              value={configuration.fb}
              onChange={(e) =>
                setConfiguration({ ...configuration, fb: e.target.value })
              }
            />

            {errors && errors.errors && errors.errors.fb && (
              <span className="error-message">{errors.errors.fb[0].msg}</span>
            )}
          </div>
          <div className="form">
            <label className="form-label">Select additional menu</label>
            <div className="form-row">
              <Checkbox
                checked={configuration.touristActive}
                onChange={(e) =>
                  setConfiguration({
                    ...configuration,
                    touristActive: e.target.checked,
                  })
                }
              >
                {' '}
                Tourist Programme{' '}
              </Checkbox>
              <Checkbox
                checked={configuration.findmycarActive}
                onChange={(e) =>
                  setConfiguration({
                    ...configuration,
                    findmycarActive: e.target.checked,
                  })
                }
              >
                {' '}
                Find My Car{' '}
              </Checkbox>
            </div>
          </div>

          {configuration.findmycarActive && (
            <div className="form">
              <label className="form-label">Find My Car Disclaimer</label>

              <Input
                placeholder="Find My Car Disclaimer"
                value={configuration.findmycarWarning}
                onChange={(e) =>
                  setConfiguration({
                    ...configuration,
                    findmycarWarning: e.target.value,
                  })
                }
              />

              {errors && errors.errors && errors.errors.findmycarWarning && (
                <span className="error-message">
                  {errors.errors.findmycarWarning[0].msg}
                </span>
              )}
            </div>
          )}
          {(filterAccess({
            userData,
            siteId,
            param: 'config',
            action: 'create',
          }) ||
            filterAccess({
              userData,
              siteId,
              param: 'config',
              action: 'update',
            })) && (
            <div className="button-wrapper">
              <div>
                {loading ? (
                  <button className="button-secondary" disabled>
                    Loading...
                  </button>
                ) : (
                  <button className="button-secondary" onClick={onReset}>
                    Reset
                  </button>
                )}
                {/* <button className="button-secondary" onClick={handleReset}>
            Reset
          </button> */}
              </div>
              <div>
                {loading ? (
                  <button className="button-primary" disabled>
                    Loading...
                  </button>
                ) : (
                  <button className="button-primary" onClick={onSubmitHandler}>
                    Save & Apply
                  </button>
                )}
                {/* <button className="button-secondary" onClick={handleReset}>
            Reset
          </button> */}
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default MasterComponentForm;
