import { format } from 'date-fns';

export const formatDate = (string) => {
  return format(
    new Date(
      string.substring(0, 4),
      string.substring(5, 7),
      string.substring(8, 10)
    ),
    'yyyyMMdd'
  );
};

export const transformToDate = (string) => {
  return new Date(
    string.substring(0, 4),
    string.substring(5, 7),
    string.substring(8, 10)
  );
};

export const filterAccess = ({ userData, siteId, param, action }) => {
  if (userData.role === 'superadmin') {
    return true;
  }
  const acc = userData.roleAccess
    ? userData.roleAccess.access.find(
        (userAccess) => userAccess.siteId === siteId
      )
    : userData.access.find((userAccess) => userAccess.siteId === siteId) ??
      userData.access[0];
  if (acc) {
    return acc[param][action];
  }
  return false;
};
