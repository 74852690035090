/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  Checkbox,
  ColorPicker,
  DatePicker,
  Input,
  Select,
  Tooltip,
  Upload,
} from 'antd';
import { useChangeHandler } from 'hooks';
import React, { useEffect, useState } from 'react';
// import InputMask from 'react-input-mask';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { dealsCleanUp } from 'state/actions/deal';
import { regexDescription, regexInput } from 'utils/regex';
import { imgUrl } from 'utils/url';
import { log } from 'utils';
import { ExportIcon } from 'assets/icon/dynamic';
import dayjs from 'dayjs';
import { fetchPois } from 'state/actions/pois';
import './DealForm.css';
import './DealForm.scss';

const DealForm = ({ deal, setDeal, success, action, id, history, error }) => {
  const { siteId, siteName } = useParams();
  log(error);

  const [isDragged, setIsDragged] = useState(false);
  const [errors, setErrors] = useState(error);

  useEffect(() => {
    setErrors(error);
  }, [error]);

  const { loading, userData, poiList } = useSelector(
    (state) => ({
      loading: state.deals.loading,
      userData: state.auth.userData,
      categoriesList: state.categories.list,
      poiList: state.pois.tenant,
      category: state.categories.category,
      floor: state.floor.floor,
    }),
    shallowEqual
  );

  const uid = userData.id;

  const dispatch = useDispatch();

  useEffect(() => {
    if (success) {
      history.push(`/${siteId}/information/${siteName}`);
    }
  }, [success]); // eslint-disable-line
  useEffect(() => {
    dispatch(fetchPois({ siteId }));
  }, []); // eslint-disable-line

  useEffect(() => {
    return () => dispatch(dealsCleanUp());
  }, [dispatch]);

  const onChangeHandler = useChangeHandler(setDeal);

  const onFileChangedHandler = (file) => {
    setDeal((prevState) => ({ ...prevState, file, thumbnail: null }));
  };

  const handleDeletePreviewImage = () => {
    setDeal((prevState) => ({
      ...prevState,
      file: null,
      thumbnail: null,
    }));
  };

  const onSubmitHandler = (value) => {
    log(deal);
    value.preventDefault();
    if (
      (!regexInput(deal['jcr:title']) && deal['jcr:title']) ||
      (!regexDescription(deal['jcr:description']) && deal['jcr:description']) ||
      deal.tenants.length === 0
    ) {
      const newErrors = {
        ...errors,
        errors: {
          ...errors.errors,
        },
        message: 'Something went wrong!',
      };

      newErrors.errors.name = !regexInput(deal.name) &&
        deal.name && [
          {
            msg: 'This field cannot contain any special characters',
          },
        ];

      newErrors.errors.description = !regexDescription(deal.description) &&
        deal.description && [
          { msg: 'This field cannot contain any special characters' },
        ];
      newErrors.errors.tenants = deal.tenants.length === 0 &&
        deal.tenants && [{ msg: 'This field is required' }];

      setErrors(newErrors);
    } else {
      log(deal, 'deal');
      dispatch(
        action({
          uid,
          ...deal,
          siteId,
          id,
        })
      );
    }
  };

  // const canSubmit = deal.name && deal.description;

  const imagePreviewUrl = deal.file
    ? deal.file && URL.createObjectURL(deal.file)
    : deal.thumbnail && `${imgUrl}/${deal.thumbnail}`;

  // const goBackMessage = useFormatMessage('DealForm.goBack');

  // const pickAnotherFileMessage = useFormatMessage('DealForm.pickAnotherFile');
  // const pickFileMessage = useFormatMessage('DealForm.pickFile');

  const handleChangeDate = (value) => {
    setDeal({
      ...deal,
      start: value ? value[0] : null,
      end: value ? value[1] : null,
    });
  };

  return (
    <div className="deal-form">
      <form className="form-wrapper">
        <div>
          <div className="form">
            <label className="form-label">
              Name <span className="is-required">*</span>
            </label>
            <Input
              placeholder="Deal Name"
              className={`${
                errors && errors.errors && errors.errors.name ? 'has-error' : ''
              }`}
              name="jcr:title"
              value={deal['jcr:title']}
              onChange={onChangeHandler}
            />
            {errors && errors.errors && errors.errors.name && (
              <span className="error-message">{errors.errors.name[0].msg}</span>
            )}
          </div>

          {poiList.length > 0 && (
            <div className="form">
              <label className="form-label">Tenants</label>
              <Select
                mode="multiple"
                options={poiList.map((poi) => ({
                  value: poi.entityname,
                  label: `${poi['jcr:title']} - ${
                    poi.locations &&
                    poi.locations?.length > 0 &&
                    poi.locations.join(', ')
                  }`,
                }))}
                value={deal.tenants}
                onChange={(e) => setDeal({ ...deal, tenants: e })}
              />
            </div>
          )}

          <div className="form">
            <label className="form-label">Description</label>
            <Input.TextArea
              rows={5}
              placeholder="Deal Description"
              className={`${
                errors && errors.errors && errors.errors.description
                  ? 'has-error'
                  : ''
              }`}
              name="jcr:description"
              value={deal['jcr:description']}
              onChange={onChangeHandler}
            />
            {errors && errors.errors && errors.errors.description && (
              <span className="error-message">
                {errors.errors.description[0].msg}
              </span>
            )}
          </div>
          <div className="form">
            <label className="form-label">Ribbon</label>
            <Checkbox
              placeholder="Deal Description"
              name="isRibbon"
              checked={deal.isRibbon}
              onChange={onChangeHandler}
            >
              Ribbon
            </Checkbox>
          </div>
          {deal.isRibbon && (
            <>
              <div className="form">
                <label className="form-label">Ribbon</label>
                <Input
                  placeholder="Ribbon Title"
                  name="ribbon"
                  value={deal.ribbon}
                  onChange={onChangeHandler}
                />
              </div>
              <div className="form">
                <label className="form-label">Ribbon Color</label>
                <ColorPicker
                  placeholder="Ribbon Color"
                  name="ribboncolor"
                  value={deal.ribboncolor}
                  onChange={(val) =>
                    setDeal({ ...deal, ribboncolor: val.toHexString() })
                  }
                />
              </div>
            </>
          )}
        </div>
        <div>
          <div className="form">
            <label className="form-label">Image</label>
            {imagePreviewUrl ? (
              <div className="upload-image-preview">
                <Tooltip placement="top" title="Delete">
                  <button
                    type="button"
                    onClick={handleDeletePreviewImage}
                    className="btn-remove"
                  >
                    <i className="feather-x" />
                  </button>
                </Tooltip>
                <img
                  className="media-avatar"
                  src={imagePreviewUrl}
                  alt="User profile logo preview"
                />
              </div>
            ) : (
              <Upload
                accept="image/*"
                showUploadList={false}
                beforeUpload={onFileChangedHandler}
              >
                <div
                  onDragLeave={() => setIsDragged(false)}
                  onDragOver={() => setIsDragged(true)}
                  className={`upload-drag-and-drop ${
                    isDragged ? 'active' : ''
                  }`}
                >
                  <ExportIcon size={22} color="#C2C2C2" />
                  <p className="upload-title">
                    Drag or <span className="upload-highlight">Browse</span>
                  </p>
                  <p className="upload-subtitle">
                    File format supported: jpg, png (Up to 5MB){' '}
                  </p>
                </div>
              </Upload>
            )}
          </div>

          <div className="form">
            <label className="form-label">Scheduling:</label>
            <DatePicker.RangePicker
              style={{ width: '100%' }}
              className="datepicker-adsign"
              format="DD/MM/YYYY HH:mm"
              showTime
              onChange={handleChangeDate}
              value={
                deal.start && deal.end
                  ? [dayjs(deal.start), dayjs(deal.end)]
                  : undefined
              }
            />
          </div>
          <div className="form">
            <label className="form-label">Start - End Time</label>
            <div className="input-row">
              <Input
                placeholder="XX:XX AM/PM"
                className={`${
                  errors && errors.errors && errors.errors.starttime
                    ? 'has-error'
                    : ''
                }`}
                name="starttime"
                value={deal.starttime}
                onChange={onChangeHandler}
              />
              -
              <Input
                placeholder="XX:XX AM/PM"
                className={`${
                  errors && errors.errors && errors.errors.endtime
                    ? 'has-error'
                    : ''
                }`}
                name="endtime"
                value={deal.endtime}
                onChange={onChangeHandler}
              />
            </div>
            {errors &&
              errors.errors &&
              (errors.errors.starttime || errors.errors.endtime) && (
                <span className="error-message">
                  {errors.errors.starttime[0]?.msg ??
                    errors.errors.endtime[0]?.msg}
                </span>
              )}
          </div>
          <div className="form">
            <label className="form-label"> Deal Sort Importance</label>
            <Input
              placeholder="Deal Sort Importance"
              className={`${
                errors && errors.errors && errors.errors.sortimportance
                  ? 'has-error'
                  : ''
              }`}
              name="sortimportance"
              value={deal.sortimportance}
              onChange={onChangeHandler}
            />
            {errors && errors.errors && errors.errors.sortimportance && (
              <span className="error-message">
                {errors.errors.sortimportance[0].msg}
              </span>
            )}
          </div>
        </div>
      </form>
      <div
        className="button-wrapper"
        style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <div>
          {loading ? (
            <button className="button-primary" disabled>
              Loading...
            </button>
          ) : (
            <button className="btn-primary" onClick={onSubmitHandler}>
              Submit
            </button>
          )}
          {/* <button className="button-secondary" onClick={handleReset}>
            Reset
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default DealForm;
