import { CircularProgress } from '@material-ui/core';
import { Table } from 'antd';
import { ChartWrapper } from 'components/Report/styles';

import React, { useEffect, useState } from 'react';

import { Pie } from 'react-chartjs-2';

import { shallowEqual, useSelector } from 'react-redux';
import 'chartjs-plugin-datalabels';
import _ from 'underscore';
// import ScreensaverReport from './ScreensaverReport';

const LeaderboardReport = (props) => {
  const { loading } = props;

  const [pieData, setPieData] = useState();
  const { leaderboard, screens } = useSelector(
    (state) => ({
      leaderboard: state.analytic.leaderboard,
      screens: state.screens.list,
    }),
    shallowEqual
  );
  const pieCollorPallete = [
    '#4472C4',
    '#ED7D31',
    '#A5A5A5',
    '#FFC000',
    '#5B9BD5',
    '#70AD47',
    '#264478',
    '#9E480E',
    '#636363',
    '#997300',
  ];
  useEffect(() => {
    if (leaderboard && screens && !loading) {
      let actions = [...leaderboard];
      actions = _.first(_.sortBy(actions, 'count').reverse(), 10);

      console.log(actions, 'labels');
      setPieData({
        labels: actions.map(
          (act) =>
            screens.find((screen) => screen.adsumScreenId === act.screenId)
              ?.name
        ),
        datasets: [
          {
            label: 'count',
            data: actions.map((act) => act.count),
            borderWidth: 1,
            // borderWidth: 2,
            borderColor: pieCollorPallete,
            backgroundColor: pieCollorPallete,
          },
        ],
      });
      // let dataMonth = [];
      // dataMonth = locations.map((loc) => {
      //   return {
      //     location: loc.location,
      //     ratio: _.groupBy(loc.data, 'gender'),
      //   };
      // });
      // console.log(dataMonth, 'data month');
      // setDataTable(_.sortBy(dataMonth, ['location']));
    }
  }, [leaderboard, screens]); // eslint-disable-line

  const columns = [
    {
      title: 'Number of Touches',
      dataIndex: 'count',
      width: '50%',
      sorter: (a, b) => a.count - b.count,
      render: (text) => <div className="table-name">{text}</div>,
    },
    {
      title: 'Kiosk Location',
      dataIndex: 'screenId',
      width: '50%',
      sorter: (a, b) => a.select - b.select,
      render: (text) => (
        <div className="table-name">
          {screens.find((screen) => screen.adsumScreenId === text)?.name}
        </div>
      ),
    },
  ];
  const optionspie = {
    plugins: {
      datalabels: {
        display: true,
        color: 'white',
        font: {
          weight: 'bold',
        },
        formatter: (value, ctx) => {
          console.log(ctx, 'ctx formater');
          const total = ctx.chart.getDatasetMeta(0);
          const percentage = `${((value * 100) / total.total).toFixed(2)}%`;
          return percentage;
        },
      },
    },
  };

  return (
    <div className="analytic-chart">
      {pieData && !loading && (
        <>
          <ChartWrapper style={{ marginBottom: '2rem' }}>
            <Pie height={85} data={pieData} options={optionspie} />
          </ChartWrapper>
        </>
      )}
      {loading ? (
        <CircularProgress />
      ) : (
        <Table columns={columns} dataSource={leaderboard} />
      )}
    </div>
  );
};

export default LeaderboardReport;
