/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { log } from 'utils';
import paths from 'pages/Router/paths';
import { sitesCleanUp } from 'state/actions/sites';
import { useChangeHandler, useFormatMessage } from 'hooks';
import './SiteForm.scss';

const SiteForm = ({
  site,
  setSite,
  action,
  user,
  organizations,
  id,
  history,
  success,
  error,
}) => {
  const { loading } = useSelector(
    (state) => ({
      loading: state.users.loading,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const [value, setValue] = useState();

  useEffect(() => {
    return () => dispatch(sitesCleanUp());
  }, [dispatch]);

  useEffect(() => {
    if (site) {
      setValue(site.organizationId);
    }
  }, [site]);

  useEffect(() => {
    if (success) {
      history.push('/sites');
    }
  }, [success]); // eslint-disable-line 

  log(success);

  const onChangeHandler = useChangeHandler(setSite);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(
      action({
        ...site,
        id,
        modifiedAt: moment().format('DD-MM-YY hh:mm:ss'),
        modifiedBy: user.email,
      })
    );
  };

  const handleReset = () => {
    setSite({
      ...site,
      name: '',
      description: '',
      organizationId: '',
      organizationName: '',
    });
  };

  const onSelectHandler = (event) => {
    const organizationId = event.target.value;
    const organizationName = event.target[event.target.selectedIndex].text;
    setSite((prevState) => ({
      ...prevState,
      organizationId,
      organizationName,
    }));
  };

  // const canSubmit = site.name && site.description;

  const goBackMessage = useFormatMessage('SiteForm.goBack');

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-menu default" />
                </span>
                {useFormatMessage('SiteForm.siteInfo')}
              </p>
            </header>
            <div className="card-content">
              <form onSubmit={onSubmitHandler}>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('SiteForm.name')}{' '}
                      <span className="has-text-danger">*</span>
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          id="name"
                          className={`input ${
                            error && error.errors && error.errors.name
                              ? 'is-danger'
                              : ''
                          }`}
                          type="text"
                          required
                          name="name"
                          value={site.name}
                          onChange={onChangeHandler}
                        />
                      </div>
                      {error && error.errors && error.errors.name && (
                        <p className="help is-danger">
                          {error.errors.name[0].msg}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('UserForm.organization')}{' '}
                      <span className="has-text-danger">*</span>
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <div
                          style={{ width: '100%' }}
                          className={`select ${
                            error && error.errors && error.errors.organizationId
                              ? 'is-danger'
                              : ''
                          }`}
                        >
                          <select
                            onChange={onSelectHandler}
                            value={value}
                            style={{ width: '100%' }}
                          >
                            <option>Select</option>
                            {organizations &&
                              organizations.map((organization) => (
                                <option
                                  value={organization._id}
                                  key={organization.id}
                                >
                                  {organization.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      {error && error.errors && error.errors.organizationId && (
                        <p className="help is-danger">
                          {error.errors.organizationId[0].msg}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('SiteForm.description')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <textarea
                          style={{
                            height: 100,
                          }}
                          id="description"
                          className="input"
                          type="text"
                          required
                          name="description"
                          value={site.description}
                          onChange={onChangeHandler}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <hr />
                <div className="field is-horizontal">
                  <div className="field-label" />
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <button
                            type="button"
                            className={`button is-primary ${
                              loading && 'is-loading'
                            }`}
                            // disabled={!canSubmit}
                            onClick={onSubmitHandler}
                          >
                            <span>{useFormatMessage('UserForm.submit')}</span>
                          </button>
                        </div>
                        <div className="control">
                          <Link to={paths.SITES} className="button">
                            {goBackMessage}
                          </Link>
                        </div>
                        <button
                          type="reset"
                          className="button"
                          onClick={handleReset}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

SiteForm.propTypes = {
  userData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
  }),
  action: PropTypes.func.isRequired,
};

export default SiteForm;
