import React from 'react';
import moment from 'moment';
import CreatedInfo from 'elements/created-info';
import TooltipMenu from 'elements/TooltipMenu';
import {
  EditIcon,
  ForwardIcon,
  ImportIcon,
  MaximizeIcon,
  TrashIcon,
  CalendarTickIcon,
  LayerIcon,
  LocationIcon,
  ClockIcon,
} from 'assets/icon';
import CardInfo from 'elements/card-info';
import CardTitle from 'elements/card-title';
import { capitalandUrl, imgUrl } from 'utils/url';
import { shallowEqual, useSelector } from 'react-redux';

import './style.css';
import defaultLogo from '../../assets/default-logo.png';

const Cards = ({
  data,
  type,
  isShowMedia,
  editAction,
  deleteAction,
  previewAction,
  downloadAction,
  cardClickAction,
  isPreview = false,
  isDownload = false,
  isClickable = false,
}) => {
  const { role } = useSelector(
    (state) => ({
      // isAdmin: state.auth.userData.isAdmin,
      userData: state.auth.userData,
      role: state.auth.userData.role,
    }),
    shallowEqual
  );

  const mediaDetails = (
    <div className="card-list-details">
      <CardTitle title={data.name} />
      <CardInfo
        icon={<ForwardIcon style={{ width: 16, height: 16 }} />}
        title={
          data.categoryName && data.categoryName.length > 20
            ? `${data.categoryName.substr(0, 20)}...`
            : data.categoryName
        }
      />
      <CardInfo
        icon={<CalendarTickIcon style={{ width: 16, height: 16 }} />}
        title={
          data.type === 'date'
            ? `${moment(data.start).format('MMM, D YYYY')} - ${moment(
                data.end
              ).format('MMM, D YYYY')}`
            : data.type === 'day' && data.days
            ? `${data.days[0].day} - ${data.days[data.days.length - 1].day}`
            : data.start && data.end
            ? `${moment(data.start).format('MMM, D YYYY')} - ${moment(
                data.end
              ).format('MMM, D YYYY')}`
            : 'All Day'
        }
      />
      {data.end && moment(data.end).isBefore(moment()) && (
        <CardInfo
          icon={<ClockIcon />}
          title={`Archived until ${moment(data.end)
            .add(30, 'day')
            .format('MMM, D YYYY')}`}
        />
      )}
    </div>
  );

  const tenantDetails = (
    <div className="card-list-details">
      <CardTitle title={data['jcr:title']} />
      <CardInfo
        icon={<LayerIcon style={{ width: 16, height: 16 }} />}
        title={
          data.floorName && data.floorName.length > 20
            ? `${data.floorName.substr(0, 20)}...`
            : data.floorName
        }
      />
      <CardInfo
        icon={<LocationIcon style={{ width: 16, height: 16 }} />}
        title={
          data.location && data.location.length > 20
            ? `${data.location.substr(0, 20)}...`
            : data.location
        }
      />
    </div>
  );

  const descDetails = (
    <div className="card-list-details">
      <CardTitle title={data.name} />
      <p className="card-list-subtitle">{data.description}</p>
    </div>
  );

  const showByType = () => {
    switch (type) {
      case 'media':
        return mediaDetails;
      case 'tenant':
        return tenantDetails;
      default:
        return descDetails;
    }
  };

  const media = (
    <>
      {data.logo && data.fileType && data.fileType.split('/')[0] === 'image' ? (
        <img
          src={`${imgUrl}/media/${data.logo}`}
          alt="default"
          className="card-list-img"
          draggable={false}
        />
      ) : data.logo &&
        data.fileType &&
        data.fileType.split('/')[0] === 'video' ? (
        <video
          id="my-video"
          className="video-js card-list-video"
          data-setup="{}"
          preload="metadata"
        >
          <source src={`${imgUrl}/media/${data.logo}`} type="video/mp4" />
        </video>
      ) : null}
    </>
  );

  const pois = (
    <img
      src={data.thumbnail ? `${capitalandUrl}${data.thumbnail}` : defaultLogo}
      alt="default"
      className="card-list-img"
      draggable={false}
    />
  );

  return (
    <>
      <div
        className="card-list-container"
        onClick={isClickable ? cardClickAction : () => {}}
      >
        {type === 'tenant' && isShowMedia ? pois : null}
        {type === 'media' && isShowMedia ? media : null}
        {showByType()}
      </div>
      <div className="card-list-footer">
        <div className="card-list-footer-left">
          <CreatedInfo user={data.modifiedBy} />
        </div>
        <div
          className="card-list-footer-right"
          onClick={(e) => e.stopPropagation()}
        >
          <ul>
            {editAction && (
              <li>
                <TooltipMenu
                  title="Edit"
                  clickAction={editAction}
                  icon={<EditIcon style={{ width: 18, height: 18 }} />}
                />
              </li>
            )}
            {isPreview && (
              <li>
                <TooltipMenu
                  title="Preview"
                  clickAction={previewAction}
                  icon={<MaximizeIcon style={{ width: 18, height: 18 }} />}
                />
              </li>
            )}
            {type === 'screen' && role !== 'superadmin'
              ? null
              : isDownload && (
                  <li>
                    <TooltipMenu
                      title="Download"
                      clickAction={downloadAction}
                      icon={<ImportIcon style={{ width: 18, height: 18 }} />}
                    />
                  </li>
                )}
            {deleteAction && (
              <li>
                <TooltipMenu
                  title="Delete"
                  clickAction={deleteAction}
                  icon={<TrashIcon style={{ width: 18, height: 18 }} />}
                />
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Cards;
