
import { useFormatMessage } from 'hooks';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import DealForm from 'components/DealForm';
import { createDeal, fetchDeal, modifyDeal } from 'state/actions/deal';
import './Deal.css';

const Deal = ({ history }) => {
  const { id, siteId, siteName } = useParams();

  const { success, dealsList, dealData, sitesList, error } = useSelector(
    (state) => ({
      success: state.deals.success,
      dealsList: state.deals.list,
      dealData: state.deals.deal,
      sitesList: state.sites.list,
      error: state.deals.error,
    }),
    shallowEqual
  );

  const [deal, setDeal] = useState(dealData || {});

  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      const dealFetched = dealsList.find(
        (fetchedDeal) => fetchedDeal._id === id
      );
      if (dealFetched) {
        setDeal(dealFetched);
      } else if (dealData._id === id) {
        setDeal(dealData);
      } else {
        dispatch(fetchDeal(id));
      }
    }
  }, [id, dealData]); // eslint-disable-line

  const isEditing = !!id;

  const dealForm =
    !deal && id ? (
      <ClipLoader />
    ) : (
      <DealForm
        isEditing={isEditing}
        deal={deal}
        sites={sitesList}
        setDeal={setDeal}
        action={isEditing ? modifyDeal : createDeal}
        success={success}
        id={id}
        history={history}
        error={error}
      />
    );

  // const redirect = (error || success) && <Redirect to={paths.USERS} />;

  const editDealMessage = useFormatMessage('Deal.editDeal');

  const newDealMessage = useFormatMessage('Deal.newDeal');

  return (
    <div className="media">
      <div className="media-header">
        <h1>{isEditing ? editDealMessage : newDealMessage}</h1>
        <Link
          to={`/${siteId}/information/${siteName}`}
          className="button-secondary"
        >
          <i className="feather-arrow-left" />
          Back
        </Link>
      </div>
      <div className="media-content">{dealForm}</div>
    </div>
  );
};

export default Deal;
