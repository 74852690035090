/* eslint-disable jsx-a11y/label-has-associated-control */
import { Modal, Tooltip, Upload } from 'antd';
import React, { useState } from 'react';
// import InputMask from 'react-input-mask';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ExportIcon } from 'assets/icon/dynamic';
import { imgUrl } from 'utils/url';
import { log } from 'utils';
import 'react-quill/dist/quill.snow.css';
import { rewardUpdate } from 'state/actions/configuration';
import ModalConfirmationContent from 'components/ModalConfirmationContent';
import ImageUploadPreview from 'components/ImageUploadPreview';
import { filterAccess } from 'components/Report/utils';
import './RewardForm.css';
import ModalResetContent from 'components/ModalResetContent';

const RewardForm = ({ configuration, userData, setConfiguration, loading }) => {
  const { siteId } = useParams();

  const [isDragged, setIsDragged] = useState(false);
  const [errors, setErrors] = useState();

  const dispatch = useDispatch();

  const onFileChangedHandler = (file) => {
    setConfiguration((prevState) => ({ ...prevState, rewardFile: file }));
  };

  const handleDeletePreviewImage = () => {
    setConfiguration((prevState) => ({
      ...prevState,
      rewardFile: null,
      rewardMedia: null,
    }));
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (!configuration.rewardFile && !configuration.rewardMedia) {
      const newErrors = {
        errors: {},
        message: 'Something went wrong!',
      };

      newErrors.errors.file = !configuration.rewardFile &&
        !configuration.rewardMedia && [
          {
            msg: 'This field is required',
          },
        ];

      setErrors(newErrors);
    } else {
      log(configuration, 'configuration');
      Modal.confirm({
        title: 'Confirmation',
        content: <ModalConfirmationContent />,
        onOk: () =>
          dispatch(
            rewardUpdate({
              ...configuration,
              siteId,
            })
          ),
      });
    }
  };
  const onReset = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: 'Confirmation',
      content: <ModalResetContent />,
      onOk: () => {
        const newConf = {
          ...configuration,

          rewardFile: null,
          rewardMedia: null,
        };
        setConfiguration({ ...newConf });
      },
    });
  };

  // const canSubmit = poi.name && poi.description;

  const imagePreviewUrl = configuration?.rewardFile
    ? configuration?.rewardFile &&
      URL.createObjectURL(configuration?.rewardFile)
    : configuration?.rewardMedia &&
      `${imgUrl}/media/${configuration?.rewardMedia}`;

  return (
    <div className="reward-form">
      <h1>Rewards</h1>
      <form className="form-wrapper">
        <div>
          <div className="form">
            <label className="form-label">Upload Image</label>
            {imagePreviewUrl ? (
              <div className="upload-image-preview">
                <Tooltip placement="top" title="Delete">
                  <button
                    type="button"
                    onClick={handleDeletePreviewImage}
                    className="btn-remove"
                  >
                    <i className="feather-x" />
                  </button>
                </Tooltip>
                <ImageUploadPreview
                  imagePreviewUrl={imagePreviewUrl}
                  fileParams={configuration?.rewardFile}
                />
              </div>
            ) : (
              <Upload
                accept="image/*, video/mp4"
                showUploadList={false}
                beforeUpload={onFileChangedHandler}
              >
                <div
                  onDragLeave={() => setIsDragged(false)}
                  onDragOver={() => setIsDragged(true)}
                  className={`upload-drag-and-drop ${
                    isDragged ? 'active' : ''
                  }`}
                >
                  <ExportIcon size={22} color="#C2C2C2" />
                  <p className="upload-title">
                    Drag or <span className="upload-highlight">Browse</span>
                  </p>
                  <p className="upload-subtitle">
                    File format supported: jpg, png (Up to 5MB){' '}
                  </p>
                </div>
              </Upload>
            )}

            {errors && errors.errors && errors.errors.file && (
              <span className="error-message">{errors.errors.file[0].msg}</span>
            )}
          </div>

          {(filterAccess({
            userData,
            siteId,
            param: 'config',
            action: 'create',
          }) ||
            filterAccess({
              userData,
              siteId,
              param: 'config',
              action: 'update',
            })) && (
            <div className="button-wrapper">
              <div>
                {loading ? (
                  <button className="button-secondary" disabled>
                    Loading...
                  </button>
                ) : (
                  <button className="button-secondary" onClick={onReset}>
                    Reset
                  </button>
                )}
                {/* <button className="button-secondary" onClick={handleReset}>
            Reset
          </button> */}
              </div>
              <div>
                {loading ? (
                  <button className="button-primary" disabled>
                    Loading...
                  </button>
                ) : (
                  <button className="button-primary" onClick={onSubmitHandler}>
                    Save & Apply
                  </button>
                )}
                {/* <button className="button-secondary" onClick={handleReset}>
            Reset
          </button> */}
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default RewardForm;
