import { createReducer } from 'redux-act';

import {
  SCREENS_FETCH_DATA_INIT,
  SCREENS_FETCH_DATA_SUCCESS,
  SCREENS_FETCH_DATA_FAIL,
  SCREENS_DELETE_SCREEN_INIT,
  SCREENS_DELETE_SCREEN_SUCCESS,
  SCREENS_DELETE_SCREEN_FAIL,
  SCREENS_CLEAR_DATA,
  SCREENS_CREATE_SCREEN_INIT,
  SCREENS_CREATE_SCREEN_SUCCESS,
  SCREENS_CREATE_SCREEN_FAIL,
  SCREENS_MODIFY_SCREEN_INIT,
  SCREENS_MODIFY_SCREEN_SUCCESS,
  SCREENS_MODIFY_SCREEN_FAIL,
  SCREENS_CLEAN_UP,
  SCREENS_CLEAR_DATA_LOGOUT,
  SET_ACTIVE_TAB,
  SET_ACTIVE_SCREEN,
} from 'state/actions/screens';

const initialState = {
  list: [],
  screen: {
    name: '',
    description: '',
    siteId: '',
    siteName: '',
    modifiedAt: new Date().toDateString(),
    modifiedBy: '',
  },
  loading: false,
  error: null,
  success: false,
  deleted: false,
  updated: false,
  tab: 'directory',
  activeScreen: {
    _id: '',
    name: '',
    description: '',
  },
};

export const screensReducer = createReducer(
  {
    [SCREENS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [SCREENS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      list: payload.list || [],
      screen: payload.screen || initialState.screen,
      loading: false,
      error: null,
    }),
    [SCREENS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [SCREENS_DELETE_SCREEN_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [SCREENS_DELETE_SCREEN_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.filter((elem) => elem.id !== payload.id),
      loading: false,
      error: null,
      deleted: true,
    }),
    [SCREENS_DELETE_SCREEN_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [SCREENS_CLEAR_DATA]: (state) => ({
      ...initialState,
      list: state.list,
    }),
    [SCREENS_CREATE_SCREEN_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [SCREENS_CREATE_SCREEN_SUCCESS]: (state, payload) => ({
      ...state,
      list: state.list.concat(payload.screen),
      loading: false,
      error: null,
      success: true,
      updated: true,
    }),
    [SCREENS_CREATE_SCREEN_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [SCREENS_MODIFY_SCREEN_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [SCREENS_MODIFY_SCREEN_SUCCESS]: (state, payload) => ({
      ...state,
      list: !state.list
        ? []
        : state.list.map((elem) => {
            if (elem.id === payload.id) {
              return {
                name: payload.screen.name,
                description: payload.screen.description,
                organizationId: payload.screen.organizationId,
                organizationName: payload.screen.organizationName,
                modifiedAt: new Date().toDateString(),
                modifiedBy: elem.email,
              };
            }
            return elem;
          }),
      loading: false,
      error: null,
      success: true,
      updated: true,
    }),
    [SCREENS_MODIFY_SCREEN_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [SCREENS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
      updated: false,
    }),
    [SCREENS_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
    [SET_ACTIVE_TAB]: (state, payload) => ({
      ...state,
      tab: payload.tab,
    }),
    [SET_ACTIVE_SCREEN]: (state, payload) => {
      return {
        ...state,
        activeScreen: payload,
      };
    },
  },
  initialState
);
