import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { url } from 'utils/url';
import { errorMsg } from 'utils';

export const POIS_FETCH_DATA_INIT = createAction('POIS_FETCH_DATA_INIT');
export const POIS_FETCH_DATA_SUCCESS = createAction('POIS_FETCH_DATA_SUCCESS');
export const POIS_FETCH_DATA_FAIL = createAction('POIS_FETCH_DATA_FAIL');

export const POIS_DELETE_POI_INIT = createAction('POIS_DELETE_POI_INIT');
export const POIS_DELETE_POI_SUCCESS = createAction('POIS_DELETE_POI_SUCCESS');
export const POIS_DELETE_POI_FAIL = createAction('POIS_DELETE_POI_FAIL');

export const POIS_CLEAR_DATA = createAction('POIS_CLEAR_DATA');

export const POIS_CREATE_POI_INIT = createAction('POIS_CREATE_POI_INIT');
export const POIS_CREATE_POI_SUCCESS = createAction('POIS_CREATE_POI_SUCCESS');
export const POIS_CREATE_POI_FAIL = createAction('POIS_CREATE_POI_FAIL');

export const POIS_MODIFY_POI_INIT = createAction('POIS_MODIFY_POI_INIT');
export const POIS_MODIFY_POI_SUCCESS = createAction('POIS_MODIFY_POI_SUCCESS');
export const POIS_MODIFY_POI_FAIL = createAction('POIS_MODIFY_POI_FAIL');

export const POIS_CLEAN_UP = createAction('POIS_CLEAN_UP');

export const POIS_CLEAR_DATA_LOGOUT = createAction('POIS_CLEAR_DATA_LOGOUT');
export const POIS_SYNC_POI_INIT = createAction('POIS_SYNC_POI_INIT');
export const POIS_SYNC_POI_SUCCESS = createAction('POIS_SYNC_POI_SUCCESS');
export const POIS_SYNC_POI_FAIL = createAction('POIS_SYNC_POI_FAIL');

export const fetchPois = ({
  poiId,
  siteId,
  categoryId = 'all',
  type = 'published',
}) => {
  // eslint-disable-next-line
  return async (dispatch, getState) => {
    dispatch(POIS_FETCH_DATA_INIT());

    if (siteId) {
      let pois;
      try {
        pois = await Axios.post(
          `${url}/tenant/filter`,
          {
            siteId,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          }
        );
        console.log(pois, 'tenants');
      } catch (error) {
        let errorMessage;
        if (error.response) {
          errorMessage = error && error.response && error.response.data.message;
        }
        toastr.error('', errorMessage);
        return dispatch(POIS_FETCH_DATA_FAIL({ error }));
      }
      const { data } = pois;
      return dispatch(POIS_FETCH_DATA_SUCCESS(data));
    }
  };
};

export const removePoi = ({ id, siteId }, cb) => {
  return async (dispatch, getState) => {
    dispatch(POIS_DELETE_POI_INIT());
    try {
      await Axios.post(
        `${url}/poi/remove`,
        {
          poiId: id,
          siteId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        POIS_DELETE_POI_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'The poi was removed.');
    cb();
    return dispatch(POIS_DELETE_POI_SUCCESS({ id }));
  };
};

export const deletePoi = ({ id, siteId }, cb) => {
  return async (dispatch, getState) => {
    dispatch(POIS_DELETE_POI_INIT());
    try {
      await Axios.post(
        `${url}/tenant/delete`,
        {
          poiId: id,
          siteId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        POIS_DELETE_POI_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'The poi was deleted.');
    cb();
    return dispatch(POIS_DELETE_POI_SUCCESS({ id }));
  };
};

export const clearPoisData = () => {
  return (dispatch) => {
    dispatch(POIS_CLEAR_DATA());
  };
};

export const clearPoisDataLogout = () => {
  return (dispatch) => {
    dispatch(POIS_CLEAR_DATA_LOGOUT());
  };
};

export const createPoi = (data) => {
  return async (dispatch, getState) => {
    dispatch(POIS_CREATE_POI_INIT());

    // let createPoi;

    const poi = new FormData();
    poi.set('jcr:title', data['jcr:title']);
    if (data['jcr:description'])
      poi.set('jcr:description', data['jcr:description']);
    poi.set('siteId', data.siteId);
    if (data.storeVibe) poi.set('storeVibe', data.storeVibe.join(','));
    poi.set('marketingcategory', data.marketingcategory.join(','));
    if (data.cuisines) poi.set('cuisines', data.cuisines.join(','));
    if (data.foodTypes) poi.set('foodTypes', data.foodTypes.join(','));
    poi.set('locations', data.locations);
    poi.set(
      'acceptsCapitavoucher',
      data.acceptsCapitavoucher
        ? 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/yes'
        : 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/no'
    );
    poi.set(
      'acceptseCapitavoucher',
      data.acceptseCapitavoucher
        ? 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/yes'
        : 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/no'
    );
    poi.set(
      'acceptsDbsInstantEarn',
      data.acceptsDbsInstantEarn
        ? 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/yes'
        : 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/no'
    );
    poi.set(
      'acceptsHalalCertified',
      data.acceptsHalalCertified
        ? 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/yes'
        : 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/no'
    );
    poi.set(
      'acceptsCapitacard',
      data.acceptsCapitacard
        ? 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/yes'
        : 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/no'
    );
    if (data.chopelink) poi.set('chopelink', data.chopelink);
    if (data.storephonenumber)
      poi.set('storephonenumber', data.storephonenumber);
    if (data.storeemailaddress)
      poi.set('storeemailaddress', data.storeemailaddress);
    if (data.starttime && data.endtime)
      poi.set(
        'openinghours',
        JSON.stringify({ starttime: data.starttime, endtime: data.endtime })
      );
    if (data.websiteaddress) poi.set('websiteaddress', data.websiteaddress);
    if (data.sortimportance) poi.set('sortimportance', data.sortimportance);
    poi.append('file', data.file);

    try {
      await Axios.post(`${url}/tenant/create`, poi, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'content-type': 'multipart/form-data',
        },
      });
    } catch (error) {
      // let errorMessage;
      // if (error.response) {
      //   errorMessage = error && error.response && error.response.data.message;
      // }

      toastr.error('', 'Please fill in all required fields');
      // toastr.error('', errorMessage);
      return dispatch(
        POIS_CREATE_POI_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }
    const poiJson = JSON.stringify(Object.fromEntries(poi));
    toastr.success('', 'Poi created successfully');
    return dispatch(POIS_CREATE_POI_SUCCESS({ poi: poiJson }));
  };
};

export const modifyPoi = (data) => {
  return async (dispatch) => {
    dispatch(POIS_MODIFY_POI_INIT());

    const poi = new FormData();
    poi.set('id', data.id);
    poi.set('jcr:title', data['jcr:title']);
    if (data['jcr:description'])
      poi.set('jcr:description', data['jcr:description']);
    poi.set('siteId', data.siteId);
    if (data.storeVibe) poi.set('storeVibe', data.storeVibe.join(','));
    poi.set('marketingcategory', data.marketingcategory.join(','));
    if (data.cuisines) poi.set('cuisines', data.cuisines.join(','));
    if (data.foodTypes) poi.set('foodTypes', data.foodTypes.join(','));
    poi.set('locations', data.locations);
    poi.set(
      'acceptsCapitavoucher',
      data.acceptsCapitavoucher
        ? 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/yes'
        : 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/no'
    );
    poi.set(
      'acceptseCapitavoucher',
      data.acceptseCapitavoucher
        ? 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/yes'
        : 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/no'
    );
    poi.set(
      'acceptsDbsInstantEarn',
      data.acceptsDbsInstantEarn
        ? 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/yes'
        : 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/no'
    );
    poi.set(
      'acceptsHalalCertified',
      data.acceptsHalalCertified
        ? 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/yes'
        : 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/no'
    );
    poi.set(
      'acceptsCapitacard',
      data.acceptsCapitacard
        ? 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/yes'
        : 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/no'
    );
    if (data.chopelink) poi.set('chopelink', data.chopelink);
    if (data.storephonenumber)
      poi.set('storephonenumber', data.storephonenumber);
    if (data.storeemailaddress)
      poi.set('storeemailaddress', data.storeemailaddress);
    if (data.starttime && data.endtime)
      poi.set(
        'openinghours',
        JSON.stringify({ starttime: data.starttime, endtime: data.endtime })
      );
    if (data.websiteaddress) poi.set('websiteaddress', data.websiteaddress);
    if (data.sortimportance) poi.set('sortimportance', data.sortimportance);
    if (data.thumbnail) poi.set('thumbnail', data.thumbnail);
    poi.append('file', data.file);

    try {
      await Axios.put(`${url}/tenant/edit`, poi, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'content-type': 'multipart/form-data',
        },
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        POIS_MODIFY_POI_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }
    const poiJson = JSON.stringify(Object.fromEntries(poi));
    toastr.success('', 'Poi updated successfully');
    return dispatch(POIS_MODIFY_POI_SUCCESS({ poiJson, id: data.id }));
  };
};

export const syncPois = () => {
  return async (dispatch, getState) => {
    dispatch(POIS_SYNC_POI_INIT());
    Axios.get(`${url}/tenant/fetch`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    })
      .then((res) => {
        return dispatch(POIS_SYNC_POI_SUCCESS());
      })
      .catch((error) => {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(POIS_SYNC_POI_FAIL({ error }));
      });
  };
};

export const poisCleanUp = () => (dispatch) => dispatch(POIS_CLEAN_UP());
