import { createReducer } from 'redux-act';

import {
  NOTIFICATIONS_FETCH_DATA_INIT,
  NOTIFICATIONS_FETCH_DATA_SUCCESS,
  NOTIFICATIONS_FETCH_DATA_FAIL,
  NOTIFICATIONS_UPDATE_DATA_INIT,
  NOTIFICATIONS_UPDATE_DATA_SUCCESS,
  NOTIFICATIONS_UPDATE_DATA_FAIL,
  NOTIFICATIONS_CLEAN_UP,
} from 'state/actions/notification';

const initialState = {
  list: [],
  loading: false,
  error: null,
  deleted: false,
  downloading: false,
};

export const notificationReducer = createReducer(
  {
    [NOTIFICATIONS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [NOTIFICATIONS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      list: payload.list || [],
      loading: false,
      error: null,
    }),
    [NOTIFICATIONS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [NOTIFICATIONS_UPDATE_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [NOTIFICATIONS_UPDATE_DATA_SUCCESS]: (state) => ({
      ...state,
      loading: false,
      error: null,
    }),
    [NOTIFICATIONS_UPDATE_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),

    [NOTIFICATIONS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
    }),
  },
  initialState
);
