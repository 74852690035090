import EventForm from 'components/EventForm';
import { useFormatMessage } from 'hooks';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { createEvent, fetchEvents, modifyEvent } from 'state/actions/event';
import './Event.css';

const Event = ({ history }) => {
  const { id, siteId, siteName } = useParams();

  const { success, eventsList, eventData, sitesList, error } = useSelector(
    (state) => ({
      success: state.events.success,
      eventsList: state.events.list,
      eventData: state.events.event,
      sitesList: state.sites.list,
      error: state.events.error,
    }),
    shallowEqual
  );

  const [event, setEvent] = useState(eventData || {});

  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      const eventFetched = eventsList.find(
        (fetchedEvent) => fetchedEvent._id === id
      );
      if (eventFetched) {
        setEvent(eventFetched);
      } else if (eventData._id === id) {
        setEvent(eventData);
      } else {
        dispatch(fetchEvents(id));
      }
    }
  }, [id, eventData]); // eslint-disable-line

  const isEditing = !!id;

  const eventForm =
    !event && id ? (
      <ClipLoader />
    ) : (
      <EventForm
        isEditing={isEditing}
        event={event}
        sites={sitesList}
        setEvent={setEvent}
        action={isEditing ? modifyEvent : createEvent}
        success={success}
        id={id}
        history={history}
        error={error}
      />
    );

  // const redirect = (error || success) && <Redirect to={paths.USERS} />;

  const editEventMessage = useFormatMessage('Event.editEvent');

  const newEventMessage = useFormatMessage('Event.newEvent');

  return (
    <div className="media">
      <div className="media-header">
        <h1>{isEditing ? editEventMessage : newEventMessage}</h1>
        <Link
          to={`/${siteId}/information/${siteName}`}
          className="button-secondary"
        >
          <i className="feather-arrow-left" />
          Back
        </Link>
      </div>
      <div className="media-content">{eventForm}</div>
    </div>
  );
};

export default Event;
