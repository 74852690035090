import React from 'react';
import TooltipMenu from 'elements/TooltipMenu';
import {
  AdobeAEM,
  DetailCode,
  EditIcon,
  MaximizeIcon,
  TrashIcon,
} from 'assets/icon';
import CardTitle from 'elements/card-title';
import { capitalandUrl, imgUrl } from 'utils/url';
import moment from 'moment';
import defaultLogo from '../../assets/default-logo.png';
import './EventCards.css';

const EventCards = ({
  data,
  previewAction,
  cardClickAction,
  dataAction,
  deleteAccess,
  editAccess,
  deleteAction = () => {},
  editAction = () => {},
  isClickable = false,
}) => {
  return (
    <>
      <div
        className={`card-event-container ${data?.source ? 'adsign-card' : ''}`}
        onClick={isClickable ? cardClickAction : () => {}}
      >
        <img
          src={
            data?.thumbnail
              ? data.source
                ? `${imgUrl}/${data.thumbnail}`
                : `${capitalandUrl}${data.thumbnail}`
              : defaultLogo
          }
          alt="default"
          className="card-event-img"
          draggable={false}
        />

        <div className="card-event-details">
          <CardTitle title={data && (data['jcr:title'] ?? data.name)} />
        </div>
      </div>
      <div
        className={`card-event-footer ${data?.source ? 'adsign-footer' : ''}`}
      >
        <div className="card-event-footer-row">
          {!data?.source && <AdobeAEM />}
          <div className="card-event-footer-column">
            <div className="created-by">
              <p>Last modified by: </p>
              <p>{data?.source ? data?.modifiedBy : 'Adobe AEM'}</p>
            </div>
            {data && (
              <div className="created-by">
                <p>Last modified at:</p>
                {data?.source ? (
                  <p>{`${moment(data.modifiedAt).format(
                    'DD/MM/YYYY HH:mm'
                  )}`}</p>
                ) : moment(data['cq:lastModified']).isValid() ? (
                  <p>{`${moment(data['cq:lastModified']).format(
                    'DD/MM/YYYY HH:mm'
                  )}`}</p>
                ) : (
                  <p>{`${moment(data.firstPublished).format(
                    'DD/MM/YYYY HH:mm'
                  )}`}</p>
                )}
              </div>
            )}
          </div>
        </div>
        <div
          className="card-event-footer-right"
          onClick={(e) => e.stopPropagation()}
        >
          <ul>
            {data?.source && deleteAccess && (
              <li>
                <TooltipMenu
                  title="Delete"
                  clickAction={() => deleteAction(data?._id)}
                  icon={<TrashIcon style={{ width: 18, height: 18 }} />}
                />
              </li>
            )}
            {data?.source && editAccess && (
              <li>
                <TooltipMenu
                  title="Edit"
                  clickAction={() => editAction(data?._id)}
                  icon={<EditIcon style={{ width: 18, height: 18 }} />}
                />
              </li>
            )}
            <li>
              <TooltipMenu
                title="JSON"
                clickAction={dataAction}
                icon={<DetailCode style={{ width: 18, height: 18 }} />}
              />
            </li>
            <li>
              <TooltipMenu
                title="Preview"
                clickAction={previewAction}
                icon={<MaximizeIcon style={{ width: 18, height: 18 }} />}
              />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default EventCards;
