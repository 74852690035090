import { InfoCircleIcon } from 'assets/icon';
import React from 'react';

const InformationBox = ({ title, list, nb }) => {
  return (
    <div className="information-box">
      <div className="title-row">
        {title || (
          <>
            {' '}
            <InfoCircleIcon style={{ height: '100%', aspectRatio: 1 }} />
            Information
          </>
        )}
      </div>
      <div className="information-content">
        {list ? (
          <ul>
            {list.map((li) => (
              <li>{li}</li>
            ))}
          </ul>
        ) : (
          <ul>
            <li>A maximum of 1 media is to be uploaded for each tile. </li>
            <li>A dark overlay will be applied to all background tiles. </li>
          </ul>
        )}
      </div>

      <div className="title-row">
        {(
          <>
            <br />
            {nb}{' '}
          </>
        ) || ''}
      </div>
    </div>
  );
};

export default InformationBox;
