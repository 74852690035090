/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useState } from 'react';
// import InputMask from 'react-input-mask';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { imgUrl } from 'utils/url';
import { log } from 'utils';
import 'react-quill/dist/quill.snow.css';
import { mobileUpdate } from 'state/actions/configuration';
import ImageUpload from 'components/ImageUpload';
import { Checkbox, Modal } from 'antd';
import ModalConfirmationContent from 'components/ModalConfirmationContent';
import InformationBox from 'components/InformationBox';
import { filterAccess } from 'components/Report/utils';
import ModalResetContent from 'components/ModalResetContent';
import './MobileForm.css';
import ReactQuill from 'react-quill';

const MobileForm = ({ configuration, setConfiguration, loading, userData }) => {
  const { siteId } = useParams();

  const [errors] = useState();

  const dispatch = useDispatch();

  const onFileChangedHandler = (file, params) => {
    const config = { ...configuration };
    config[params] = file;
    setConfiguration(config);
  };
  const handleDeletePreviewImage = (file, media) => {
    const config = { ...configuration };
    config[file] = null;
    config[media] = null;
    setConfiguration({ ...config });
  };
  const mediaForms = [
    {
      label: '(Mobile) Background Application',
      fileParams: 'backgroundMobileFile',
      mediaParams: 'backgroundMobile',
      errorParams: 'directory',
    },
    {
      label:
        '(Mobile) Media for background tile: Directory [W189 px x H141.2 px]',
      fileParams: 'directoryBackgroundMobileFile',
      mediaParams: 'directoryBackgroundMobile',
      errorParams: 'directory',
    },
    {
      label: '(Mobile) Media for background tile: Dine [W189 px x H141.2 px]',
      fileParams: 'dineBackgroundMobileFile',
      mediaParams: 'dineBackgroundMobile',
      errorParams: 'dine',
    },
    {
      label:
        '(Mobile) Media for background tile: Fashion [W189 px x H141.2 px]',
      fileParams: 'fashionBackgroundMobileFile',
      mediaParams: 'fashionBackgroundMobile',
      errorParams: 'fashion',
    },
    {
      label:
        '(Mobile) Media for background tile: Floor Guide [W189 px x H141.2 px]',
      fileParams: 'floorBackgroundMobileFile',
      mediaParams: 'floorBackgroundMobile',
      errorParams: 'floor',
    },
    {
      label:
        '(Mobile) Media for background tile: Promotions [W189 px x H141.2 px]',
      fileParams: 'promotionBackgroundMobileFile',
      mediaParams: 'promotionBackgroundMobile',
      errorParams: 'promotion',
    },
    {
      label:
        '(Mobile) Media for background tile: Happenings [W189 px x H141.2 px]',
      fileParams: 'happeningBackgroundMobileFile',
      mediaParams: 'happeningBackgroundMobile',
      errorParams: 'happening',
    },
    {
      label: '(Mobile) Media for background tile: Reward [W189 px x H141.2 px]',
      fileParams: 'rewardBackgroundMobileFile',
      mediaParams: 'rewardBackgroundMobile',
      errorParams: 'reward',
    },

    {
      label: `(Mobile) Media for background tile: Mall Info [${
        configuration.touristActive
          ? 'W189 px x H141.2 px'
          : 'W189 px x H141.2 px'
      }]`,
      fileParams: 'conciergeBackgroundMobileFile',
      mediaParams: 'conciergeBackgroundMobile',
      errorParams: 'concierge',
    },
    {
      label: `(Mobile) Media for background tile: Public Transport [${
        configuration.touristActive
          ? 'W189 px x H141.2 px'
          : 'W189 px x H141.2 px'
      }]`,
      fileParams: 'transportBackgroundMobileFile',
      mediaParams: 'transportBackgroundMobile',
      errorParams: 'transport',
    },
  ];

  if (configuration.touristActive) {
    mediaForms.push(
      {
        label:
          '(Mobile) Media for background tile: Tourist Programme [W189 px x H141.2 px]',
        fileParams: configuration.touristActive
          ? 'touristBackgroundMobileFile'
          : null,
        mediaParams: 'touristBackgroundMobile',
        errorParams: 'tourist',
      },
      {
        label:
          '(Mobile) Tourist Information Page - Media [W414 px x H {No Req} px]',
        fileParams: configuration.touristActive
          ? 'touristMediaMobileFile'
          : null,
        mediaParams: 'touristMediaMobile',
        errorParams: 'tourist',
      }
    );
  }
  mediaForms.push(
    {
      label: '(Mobile) Mall Info Page - Media [W414 px x H {No Req} px]',
      fileParams: 'conciergeMediaMobileFile',
      mediaParams: 'conciergeMediaMobile',
      errorParams: 'tourist',
    },
    {
      label: '(Mobile) Rewards Page - Media  [W382 px x H {No Req} px]',
      fileParams: 'rewardMediaMobileFile',
      mediaParams: 'rewardMediaMobile',
      errorParams: 'tourist',
    },
    {
      label:
        '(Mobile) Transport Page - Media for background tile: Carpark Rates [W390 px x H141.2 px]',
      fileParams: 'carparkBackgroundMobileFile',
      mediaParams: 'carparkBackgroundMobile',
      errorParams: 'tourist',
    },
    {
      label:
        '(Mobile) Transport Page - Carpark Rates - Media  [W382 px x H {No Req} px]',
      fileParams: 'carparkContentMobileFile',
      mediaParams: 'carparkContentMobile',
      errorParams: 'tourist',
    },
    {
      label:
        '(Mobile) Transport Page - Media for background tile: Public Transportation Information [W390 px x H141.2 px]',
      fileParams: 'publictransportBackgroundMobileFile',
      mediaParams: 'publictransportBackgroundMobile',
      errorParams: 'tourist',
    }
  );

  const onSubmitHandler = (event) => {
    event.preventDefault();
    // const errorFields = mediaForms.filter(
    //   (form) =>
    //     !configuration[form.fileParams] && !configuration[form.mediaParams]
    // );
    // if (errorFields.length > 0) {
    //   const newErrors = {
    //     errors: {},
    //     message: 'Something went wrong!',
    //   };
    //   if (errorFields.length > 0) {
    //     for (let i = 0, l = errorFields.length; i < l; i += 1) {
    //       const form = errorFields[i];
    //       newErrors.errors[form.errorParams] = [
    //         {
    //           msg: 'This field is required',
    //         },
    //       ];
    //     }
    //   }

    //   setErrors(newErrors);
    // } else {
    log(configuration, 'configuration');
    Modal.confirm({
      title: 'Confirmation',
      content: <ModalConfirmationContent />,
      onOk: () =>
        dispatch(
          mobileUpdate({
            ...configuration,
            siteId,
          })
        ),
    });
    // }
  };

  const onReset = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: 'Confirmation',
      content: <ModalResetContent />,
      onOk: () => {
        const newConf = {
          ...configuration,
        };

        for (let i = 0, l = mediaForms.length; i < l; i += 1) {
          const form = mediaForms[i];
          newConf[form.fileParams] = null;
          newConf[form.mediaParams] = null;
        }
        setConfiguration({ ...newConf });
      },
    });
  };

  // const canSubmit = poi.name && poi.description;

  return (
    <div className="reward-form">
      <h1>Mobile Requirement</h1>
      <form className="form-wrapper">
        <div>
          <div className="form">
            <Checkbox
              checked={configuration.isDisabledMobile}
              onChange={(e) =>
                setConfiguration({
                  ...configuration,
                  isDisabledMobile: e.target.checked,
                })
              }
            >
              <label className="form-label">
                {`Temporarily Disabled - Users can't access the site on mobile/desktop`}{' '}
              </label>
            </Checkbox>
          </div>
          <div className="form">
            <label className="form-label">
              Under Maintenance - Description
            </label>

            <ReactQuill
              modules={{
                toolbar: [
                  [{ header: '1' }, { header: '2' }],
                  [{ size: [] }],
                  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                ],
                clipboard: {
                  // toggle to add extra line breaks when pasting HTML:
                  matchVisual: false,
                },
              }}
              formats={[
                'header',
                'font',
                'size',
                'bold',
                'italic',
                'underline',
                'strike',
                'blockquote',
                'list',
                'bullet',
                'indent',
                'link',
                'image',
                'video',
              ]}
              theme="snow"
              value={configuration?.maintenanceDescription ?? ''}
              onChange={(val) =>
                setConfiguration({
                  ...configuration,
                  maintenanceDescription: val,
                })
              }
            />

            {errors && errors.errors && errors.errors.description && (
              <span className="error-message">
                {errors.errors.description[0].msg}
              </span>
            )}
          </div>
          <InformationBox />

          {mediaForms.map((form) => {
            const imagePreviewUrl =
              configuration &&
              (configuration[form.fileParams]
                ? configuration[form.fileParams] &&
                  URL.createObjectURL(configuration[form.fileParams])
                : configuration[form.mediaParams] &&
                  `${imgUrl}/media/${configuration[form.mediaParams]}`);

            return (
              form.fileParams && (
                <div className="form">
                  <label className="form-label">{form.label}</label>
                  <ImageUpload
                    handleDeletePreviewImage={() =>
                      handleDeletePreviewImage(
                        form.fileParams,
                        form.mediaParams
                      )
                    }
                    onFileChangedHandler={(file) =>
                      onFileChangedHandler(file, form.fileParams)
                    }
                    imagePreviewUrl={imagePreviewUrl}
                    fileParams={configuration[form.fileParams]}
                  />
                  {errors &&
                    errors.errors &&
                    errors.errors[form.errorParams] && (
                      <span className="error-message">
                        {errors.errors[form.errorParams][0].msg}
                      </span>
                    )}
                </div>
              )
            );
          })}

          {(filterAccess({
            userData,
            siteId,
            param: 'config',
            action: 'create',
          }) ||
            filterAccess({
              userData,
              siteId,
              param: 'config',
              action: 'update',
            })) && (
            <div className="button-wrapper">
              <div>
                {loading ? (
                  <button className="button-secondary" disabled>
                    Loading...
                  </button>
                ) : (
                  <button className="button-secondary" onClick={onReset}>
                    Reset
                  </button>
                )}
              </div>
              <div>
                {loading ? (
                  <button className="button-primary" disabled>
                    Loading...
                  </button>
                ) : (
                  <button className="button-primary" onClick={onSubmitHandler}>
                    Save & Apply
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default MobileForm;
